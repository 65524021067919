import users from '@bold/common/constants/users'
import * as time from '@bold/common/libs/time'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import Button from 'components/Button'
import Container from 'components/Container'
import Image, { type ImageProps } from 'components/Image'
import Row from 'components/Row'
import Title from 'components/Title'
import paths from 'constants/paths'
import { useGlobalContext } from 'contexts/GlobalContext'
import ActivityView from 'features/user/components/ActivityView'
import useEventPageVisited from 'hooks/use-event-page-visited'
import assessmentBalanceBadgeImage from 'images/badges/assessment---balance.svg'
import assessmentMobilityBadgeImage from 'images/badges/assessment---mobility.svg'
import assessmentStrengthLowerBodyBadgeImage from 'images/badges/assessment---strength-lower-body.svg'
import assessmentStrengthUpperBodyBadgeImage from 'images/badges/assessment---strength-upper-body.svg'
import challenge202108BadgeImage from 'images/badges/challenge---2021-08.png'
import challenge202109BadgeImage from 'images/badges/challenge---2021-09.png'
import challenge202110BadgeImage from 'images/badges/challenge---2021-10.png'
import challenge2022MarchMadnessBadgeImage from 'images/badges/challenge---2022-march-madness.png'
import challenge2022SeptemberBalanceChallengeBadgeImage from 'images/badges/challenge---2022-september-balance.png'
import challengeHeartHealthyBadgeImage from 'images/badges/challenge---2023-healthy-heart.png'
import challenge2023StartStrongBadgeImage from 'images/badges/challenge---2023-start-strong.png'
import challengeFestiveFlowBadgeImage from 'images/badges/challenge---festive-flow.png'
import challengeJanuaryBalanceBadgeImage from 'images/badges/challenge---january-balance.png'
import challengeMoverAndShakerBadgeImage from 'images/badges/challenge---mover-and-shaker.png'
import lessons1BadgeImage from 'images/badges/lessons---1.svg'
import lessons5BadgeImage from 'images/badges/lessons---5.svg'
import lessons10BadgeImage from 'images/badges/lessons---10.svg'
import lessons15BadgeImage from 'images/badges/lessons---15.svg'
import lessons20BadgeImage from 'images/badges/lessons---20.svg'
import lessons30BadgeImage from 'images/badges/lessons---30.svg'
import lessons40BadgeImage from 'images/badges/lessons---40.svg'
import lessons50BadgeImage from 'images/badges/lessons---50.svg'
import lessons75BadgeImage from 'images/badges/lessons---75.svg'
import lessons100BadgeImage from 'images/badges/lessons---100.svg'
import lessons200BadgeImage from 'images/badges/lessons---200.svg'
import lessons300BadgeImage from 'images/badges/lessons---300.svg'
import lessons400BadgeImage from 'images/badges/lessons---400.svg'
import lessons500BadgeImage from 'images/badges/lessons---500.svg'
import lessons600BadgeImage from 'images/badges/lessons---600.svg'
import lessons700BadgeImage from 'images/badges/lessons---700.svg'
import lessons800BadgeImage from 'images/badges/lessons---800.svg'
import lessons900BadgeImage from 'images/badges/lessons---900.svg'
import lessons1000BadgeImage from 'images/badges/lessons---1000.svg'
import minutes60BadgeImage from 'images/badges/minutes---60.png'
import minutes120BadgeImage from 'images/badges/minutes---120.png'
import minutes180BadgeImage from 'images/badges/minutes---180.png'
import minutes240BadgeImage from 'images/badges/minutes---240.png'
import minutes300BadgeImage from 'images/badges/minutes---300.png'
import programCompleteBadgeImage from 'images/badges/program-complete.svg'
import welcomeBadgeImage from 'images/badges/welcome.png'
import * as assessmentsLib from 'libs/assessments'
import * as assessmentsApiRequest from 'libs/assessments-api-request'
import { CHALLENGE_TYPE } from 'libs/challenge'
import * as challengeApiRequest from 'libs/challenge-api-request'
import * as user from 'libs/user'
import * as userApiRequest from 'libs/user-api-request'
import styles from './Badges.module.scss'

type BadgesBadgeProps = {
  active?: boolean
} & ImageProps

function BadgesBadge({ active, ...props }: BadgesBadgeProps) {
  return (
    <div className={styles['badges--item']}>
      <Image {...props} className={classNames(styles.badge, active && styles['badge---active'])} />
    </div>
  )
}

type BadgesChallengeBadgeProps = {
  active?: boolean
  data?: unknown
  name: string
} & BadgesBadgeProps

function BadgesChallengeBadge({ active, data, name, ...props }: BadgesChallengeBadgeProps) {
  if ((data && !_.some(data, (item) => item.challenge.name === name)) || (!data && !active)) {
    return null
  }

  return (
    <BadgesBadge
      {...props}
      active={active || _.some(data, (item) => item.progress >= item.challenge.threshold)}
      alt={`${name} badge`}
    />
  )
}

export default function Badges() {
  useEventPageVisited('Badges')
  const globalContext = useGlobalContext()
  const [assessmentBalance, setAssessmentBalance] = React.useState(false)
  const [assessmentMobility, setAssessmentMobility] = React.useState(false)
  const [assessmentStrengthLowerBody, setAssessmentStrengthLowerBody] = React.useState(false)
  const [assessmentStrengthUpperBody, setAssessmentStrengthUpperBody] = React.useState(false)
  const [previousUserChallenges, setPreviousUserChallenges] = React.useState([])
  const [userProgramsProgress, setUserProgramsProgress] = React.useState()
  const [watchedTime, setWatchedTime] = React.useState(0)

  React.useEffect(() => {
    async function getAssessments() {
      const response = await assessmentsApiRequest.getAssessments(globalContext.user?.id)

      function isAssessmentCompleted(internalId) {
        return !_.isEmpty(response.find((item) => item.internalId === internalId)?.lastRecordDate)
      }

      if (response) {
        setAssessmentBalance(isAssessmentCompleted(assessmentsLib.ASSESSMENT_BALANCE_INTERNAL_ID))
        setAssessmentMobility(isAssessmentCompleted(assessmentsLib.ASSESSMENT_MOBILITY_INTERNAL_ID))
        setAssessmentStrengthLowerBody(
          isAssessmentCompleted(assessmentsLib.ASSESSMENT_STRENGTH_LOWER_BODY_INTERNAL_ID)
        )
        setAssessmentStrengthUpperBody(
          isAssessmentCompleted(assessmentsLib.ASSESSMENT_STRENGTH_UPPER_BODY_INTERNAL_ID)
        )
      }
    }
    getAssessments()
  }, [globalContext.user?.id])

  React.useEffect(() => {
    async function getPreviousUserChallenges() {
      const response = await challengeApiRequest.getUserChallenges({
        complete: true,
        type: CHALLENGE_TYPE.ONEOFF,
      })
      setPreviousUserChallenges(response.data)
    }
    getPreviousUserChallenges()
  }, [])

  React.useEffect(() => {
    async function getUserProgramsProgress() {
      const response = await userApiRequest.getUserExerciseProgramsProgress()
      setUserProgramsProgress(response.data)
    }
    getUserProgramsProgress()
  }, [])

  React.useEffect(() => {
    async function getWatchedTime() {
      const response = await userApiRequest.getUserStatistics(globalContext.user?.id)
      setWatchedTime(time.secondsToMinutes(response.data?.stats?.duration))
    }
    getWatchedTime()
  }, [globalContext.user?.id])

  return (
    <ActivityView title="Badges" className="Badges">
      <Container flush>
        <Title
          subtext="You’re doing great! Keep on taking classes and assessing your progress to continue unlocking badges. We’re impressed."
          cta={
            <Button
              to={user.isFreeUser(globalContext.user) ? paths.EXPLORE : paths.PROGRAM}
              size="xlarge">
              Take a class
            </Button>
          }
          align="center"
          size="small"
        />
        <Row>
          <div className={styles.badges}>
            <BadgesBadge src={welcomeBadgeImage} active alt="Welcome badge" />
            {userProgramsProgress?.map(
              (userExerciseProgram) =>
                userExerciseProgram.percentComplete === 100 && (
                  <BadgesBadge
                    src={programCompleteBadgeImage}
                    active
                    alt="Program complete badge"
                  />
                )
            )}
            <BadgesBadge
              src={assessmentBalanceBadgeImage}
              active={assessmentBalance}
              alt="Balance assessment badge"
            />
            <BadgesBadge
              src={assessmentMobilityBadgeImage}
              active={assessmentMobility}
              alt="Mobility assessment badge"
            />
            <BadgesBadge
              src={assessmentStrengthLowerBodyBadgeImage}
              active={assessmentStrengthLowerBody}
              alt="Lower body strength assessment badge"
            />
            <BadgesBadge
              src={assessmentStrengthUpperBodyBadgeImage}
              active={assessmentStrengthUpperBody}
              alt="Upper body strength assessment badge"
            />
            <BadgesBadge
              src={lessons1BadgeImage}
              active={globalContext.user?.allTimeClassesWatched >= 1}
              alt="1st class badge"
            />
            <BadgesBadge
              src={lessons5BadgeImage}
              active={globalContext.user?.allTimeClassesWatched >= 5}
              alt="5 classes badge"
            />
            <BadgesBadge
              src={lessons10BadgeImage}
              active={globalContext.user?.allTimeClassesWatched >= 10}
              alt="10 classes badge"
            />
            <BadgesBadge
              src={lessons15BadgeImage}
              active={globalContext.user?.allTimeClassesWatched >= 15}
              alt="15 classes badge"
            />
            <BadgesBadge
              src={lessons20BadgeImage}
              active={globalContext.user?.allTimeClassesWatched >= 20}
              alt="20 classes badge"
            />
            <BadgesBadge
              src={lessons30BadgeImage}
              active={globalContext.user?.allTimeClassesWatched >= 30}
              alt="30 classes badge"
            />
            <BadgesBadge
              src={lessons40BadgeImage}
              active={globalContext.user?.allTimeClassesWatched >= 40}
              alt="40 classes badge"
            />
            <BadgesBadge
              src={lessons50BadgeImage}
              active={globalContext.user?.allTimeClassesWatched >= 50}
              alt="50 classes badge"
            />
            <BadgesBadge
              src={lessons75BadgeImage}
              active={globalContext.user?.allTimeClassesWatched >= 75}
              alt="75 classes badge"
            />
            <BadgesBadge
              src={lessons100BadgeImage}
              active={globalContext.user?.allTimeClassesWatched >= 100}
              alt="100 classes badge"
            />
            <BadgesBadge
              src={lessons200BadgeImage}
              active={globalContext.user?.allTimeClassesWatched >= 200}
              alt="200 classes badge"
            />
            <BadgesBadge
              src={lessons300BadgeImage}
              active={globalContext.user?.allTimeClassesWatched >= 300}
              alt="300 classes badge"
            />
            <BadgesBadge
              src={lessons400BadgeImage}
              active={globalContext.user?.allTimeClassesWatched >= 400}
              alt="400 classes badge"
            />
            <BadgesBadge
              src={lessons500BadgeImage}
              active={globalContext.user?.allTimeClassesWatched >= 500}
              alt="500 classes badge"
            />
            <BadgesBadge
              src={lessons600BadgeImage}
              active={globalContext.user?.allTimeClassesWatched >= 600}
              alt="600 classes badge"
            />
            <BadgesBadge
              src={lessons700BadgeImage}
              active={globalContext.user?.allTimeClassesWatched >= 700}
              alt="700 classes badge"
            />
            <BadgesBadge
              src={lessons800BadgeImage}
              active={globalContext.user?.allTimeClassesWatched >= 800}
              alt="800 classes badge"
            />
            <BadgesBadge
              src={lessons900BadgeImage}
              active={globalContext.user?.allTimeClassesWatched >= 900}
              alt="900 classes badge"
            />
            <BadgesBadge
              src={lessons1000BadgeImage}
              active={globalContext.user?.allTimeClassesWatched >= 1000}
              alt="1000 classes badge"
            />
            <BadgesBadge
              src={minutes60BadgeImage}
              active={watchedTime >= 60}
              alt="60 minutes milestone badge"
            />
            <BadgesBadge
              src={minutes120BadgeImage}
              active={watchedTime >= 120}
              alt="120 minutes milestone badge"
            />
            <BadgesBadge
              src={minutes180BadgeImage}
              active={watchedTime >= 180}
              alt="180 minutes milestone badge"
            />
            <BadgesBadge
              src={minutes240BadgeImage}
              active={watchedTime >= 240}
              alt="240 minutes milestone badge"
            />
            <BadgesBadge
              src={minutes300BadgeImage}
              active={watchedTime >= 300}
              alt="300 minutes milestone badge"
            />
            {user.hasChallenges(globalContext.user) && (
              <>
                <BadgesChallengeBadge
                  data={previousUserChallenges}
                  name="January Balance Challenge"
                  src={challengeJanuaryBalanceBadgeImage}
                />
                <BadgesChallengeBadge
                  data={previousUserChallenges}
                  name="Healthy Heart Challenge"
                  src={challengeHeartHealthyBadgeImage}
                />
                <BadgesChallengeBadge
                  data={previousUserChallenges}
                  name="Festive Flow Challenge"
                  src={challengeFestiveFlowBadgeImage}
                />
                <BadgesChallengeBadge
                  data={previousUserChallenges}
                  name="Start Strong Challenge"
                  src={challenge2023StartStrongBadgeImage}
                />
                {users.CHALLENGE_2021_08_EMAILS.includes(
                  globalContext.user?.email?.toLowerCase()
                ) && (
                  <BadgesChallengeBadge
                    active
                    name="August 2021 Challenge"
                    src={challenge202108BadgeImage}
                  />
                )}
                <BadgesChallengeBadge
                  data={previousUserChallenges}
                  name="September Balance Challenge"
                  src={challenge202109BadgeImage}
                />
                {users.CHALLENGE_2021_10_EMAILS.includes(
                  globalContext.user?.email?.toLowerCase()
                ) && (
                  <BadgesChallengeBadge
                    active
                    name="Octobert 2021 Challenge"
                    src={challenge202110BadgeImage}
                  />
                )}
                <BadgesChallengeBadge
                  data={previousUserChallenges}
                  name="March Madness Challenge"
                  src={challenge2022MarchMadnessBadgeImage}
                />
                <BadgesChallengeBadge
                  data={previousUserChallenges}
                  name="September 2022 Balance Challenge"
                  src={challenge2022SeptemberBalanceChallengeBadgeImage}
                />
                <BadgesChallengeBadge
                  active={_.some(
                    previousUserChallenges,
                    (item) => item.tag === 'skill_building' || item.complete === true
                  )}
                  name="Mover & Shaker Challenge"
                  src={challengeMoverAndShakerBadgeImage}
                />
              </>
            )}
          </div>
        </Row>
      </Container>
    </ActivityView>
  )
}
