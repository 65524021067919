import React from 'react'
import Container from 'components/Container'
import * as events from 'constants/events'
import OnboardingLayout from 'features/onboarding/components/OnboardingLayout'
import useEventPageVisited from 'hooks/use-event-page-visited'
import OnboardingPhoneNumberForm from './components/OnboardingPhoneNumberForm'

export default function OnboardingPhoneNumber() {
  useEventPageVisited(events.ONBOARDING_PHONE_NUMBER_PAGE)

  return (
    <OnboardingLayout className="OnboardingPhoneNumber">
      <Container size="small">
        <OnboardingPhoneNumberForm />
      </Container>
    </OnboardingLayout>
  )
}
