import type { PageProps } from 'gatsby'
import React from 'react'
import HomeSpecial from 'components/landing/HomeSpecial'
import HomeSpecialLayout from 'components/landing/HomeSpecialLayout'

type Props = PageProps

export default function HomeSpecialSeniors(props: Props) {
  return (
    <HomeSpecialLayout {...props} className="HomeSpecialSeniors">
      <HomeSpecial
        title="Free* exercises for seniors"
        subtext="Hundreds of free* exercises classes for seniors. Take your first class today."
        disclaimer="*Free if covered by your insurance"
      />
    </HomeSpecialLayout>
  )
}
