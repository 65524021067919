import phoneNumbers from '@bold/common/constants/phone-numbers'
import paths from 'constants/paths'
import statusCodes from 'constants/status-codes'
import storage from 'constants/storage'
import * as formatPhone from 'libs/format-phone'
import * as userApiRequest from 'libs/user-api-request'
import * as validations from 'libs/validations'

export const DEMO_CLINICAL_KEY = 'demo'
export const TRIAL_KEY = 'trial'

export const BECAUSE_KEY = 'because'
export const CQC_KEY = 'cqc'
export const GATOR_KEY = 'gator'
export const GROUPER_KEY = 'grouper'
export const HERRING_KEY = 'herring'
export const KELP_CO_KEY = 'kelp-co'
export const KELP_NORCAL_KEY = 'kelp-ncal'
export const KELP_WA_KEY = 'kelp-wa'
export const ORCA_KEY = 'orca'
export const OTTER_KEY = 'otter'
export const PATINA_KEY = 'patina'
export const REEF_KEY = 'reef'
export const SEAL_KEY = 'seal'
export const UCARE_KEY = 'ucare'
export const URCHIN_KEY = 'urchin'

export const PARTNERS = {
  [DEMO_CLINICAL_KEY]: {
    internalId: DEMO_CLINICAL_KEY,
    campaign: 'clinical-demo',
    name: 'Partner',
    slug: 'enterprise',
  },
  [BECAUSE_KEY]: {
    internalId: BECAUSE_KEY,
    campaign: 'because',
    name: 'Because',
    slug: 'because',
    trialDays: 30,
  },
  [CQC_KEY]: {
    internalId: CQC_KEY,
    campaign: 'cinqcare',
    name: 'CINQCARE',
    slug: 'cinqcare',
  },
  [GATOR_KEY]: {
    internalId: GATOR_KEY,
    campaign: 'genspace',
    name: 'Wallis Annenberg GenSpace',
    slug: 'genspace',
  },
  [GROUPER_KEY]: {
    internalId: GROUPER_KEY,
    campaign: 'uhc-group',
    name: 'UnitedHealthcare',
    phoneNumber: '1 (855) 608-1393',
    phoneNumberDisplay: '1 (855) 608-1393 (TTY: 711)',
    slug: 'uhcgroup',
  },
  [HERRING_KEY]: {
    internalId: HERRING_KEY,
    campaign: 'human-good',
    name: 'Human Good',
    nameTechnical: 'Human Good®',
    slug: 'humangoodlg',
  },
  [KELP_CO_KEY]: {
    internalId: ORCA_KEY,
    campaign: 'kelp-co',
    name: 'Kaiser Permanente',
    slug: 'kpco',
  },
  [KELP_NORCAL_KEY]: {
    internalId: ORCA_KEY,
    campaign: 'kelp-cal',
    name: 'Kaiser Permanente',
    slug: 'kpncal',
  },
  [KELP_WA_KEY]: {
    internalId: ORCA_KEY,
    campaign: 'kelp-wa',
    name: 'Kaiser Permanente',
    slug: 'kpwa',
  },
  [ORCA_KEY]: {
    internalId: ORCA_KEY,
    campaign: 'one-pass',
    name: 'One Pass',
    nameTechnical: 'One Pass™',
    slug: 'onepass',
    terms: 'https://www.rallyhealth.com/corporate/terms/en-US/',
  },
  [OTTER_KEY]: {
    internalId: OTTER_KEY,
    campaign: 'otter',
    name: 'Optum',
    phoneNumber: '1 (855) 528-0851',
    phoneNumberDisplay: '1 (855) 528-0851 (TTY: 711)',
    slug: 'optumaco',
  },
  [PATINA_KEY]: {
    internalId: ORCA_KEY,
    name: 'Patina',
    slug: 'patina',
  },
  [REEF_KEY]: {
    internalId: REEF_KEY,
    campaign: 'renewactive',
    name: 'Renew Active',
    nameTechnical: 'Renew Active® by UnitedHealthcare®',
    slug: 'renewactive',
    terms: 'https://uhcrenewactive.com/terms',
  },
  [SEAL_KEY]: {
    internalId: ORCA_KEY,
    campaign: 'scan',
    name: 'SCAN Health Plan through One Pass™',
    slug: 'scan',
  },
  [UCARE_KEY]: {
    internalId: ORCA_KEY,
    campaign: 'ucare',
    name: 'UCare',
    slug: 'ucare',
  },
  [URCHIN_KEY]: {
    internalId: URCHIN_KEY,
    campaign: 'urchin',
    name: 'UCLA Health Medicare Advantage Plan',
    phoneNumber: '1 (213) 320-6946',
    phoneNumberDisplay: '1 (213) 320-6946 (TTY: 711)',
    slug: 'uclahealthma',
  },
}

// prettier-ignore
const PARTNERS_CLINICAL = [
  DEMO_CLINICAL_KEY,
  GROUPER_KEY,
  OTTER_KEY,
]

const PARTNERS_FITNESS = [
  BECAUSE_KEY,
  GATOR_KEY,
  HERRING_KEY,
  KELP_CO_KEY,
  KELP_NORCAL_KEY,
  KELP_WA_KEY,
  ORCA_KEY,
  PATINA_KEY,
  REEF_KEY,
  SEAL_KEY,
  TRIAL_KEY,
  UCARE_KEY,
  URCHIN_KEY,
]

export const PARTNERS_ALWAYS_ELIGIBLE = [
  DEMO_CLINICAL_KEY,
  GATOR_KEY,
  HERRING_KEY,
  storage.MASS_ADVANTAGE,
  TRIAL_KEY,
]

export const PARTNERS_WITH_ELIGIBILITY_FILE = [GROUPER_KEY, OTTER_KEY, URCHIN_KEY]

// prettier-ignore
export const REEF_ORCA_SOURCES = [
  BECAUSE_KEY,
  KELP_CO_KEY,
  KELP_NORCAL_KEY,
  KELP_WA_KEY,
  ORCA_KEY,
  PATINA_KEY,
  REEF_KEY,
  SEAL_KEY,
  'special',
  UCARE_KEY,
  URCHIN_KEY,
]

// prettier-ignore
const PARTNERS_ORCA = [
  KELP_CO_KEY,
  KELP_NORCAL_KEY,
  KELP_WA_KEY,
  ORCA_KEY,
  SEAL_KEY,
  UCARE_KEY
]

const SRC_IDS_NO_ELIGIBILITY_CHECK = [storage.MASS_ADVANTAGE]

export async function convertReefOrcaUserToEnterprise({
  data,
  onError,
  onSuccess,
}: {
  data: {
    enterpriseId: string
    externalId: string
    // validateCode?: boolean
  }
  onError?: () => Promise<void>
  onSuccess?: () => Promise<void>
}) {
  if (!data.enterpriseId) return

  const response = await userApiRequest
    .associateUserWithEnterprise({
      ...data,
      validateCode: true, // so nobody can have just a random URL or type in any code
    })
    .catch((error) => {
      console.error(error)
      if (onError) onError(response)
    })

  if (response.statusCode !== statusCodes.POST_SUCCESS) {
    if (onError) await onError(response)
    return
  }

  if (response.statusCode === statusCodes.POST_SUCCESS && onSuccess) await onSuccess()

  return response?.data
}

export function determineReefOrOrca(key: string) {
  switch (key) {
    case null:
    case undefined:
    case BECAUSE_KEY:
    case 'special':
    case PATINA_KEY:
    case URCHIN_KEY: {
      return ['reef', 'orca']
    }
    case 'reef': {
      return ['reef']
    }
    case KELP_CO_KEY:
    case KELP_NORCAL_KEY:
    case KELP_WA_KEY:
    case ORCA_KEY:
    case SEAL_KEY:
    case UCARE_KEY: {
      return ['orca']
    }
    default: {
      return []
    }
  }
}

export function getHomePath(key: string) {
  return key === 'special'
    ? paths.HOME_SPECIAL
    : PARTNERS[key]?.slug
      ? `/${PARTNERS[key].slug}/`
      : paths.HOME
}

export function getInternalId(key: string) {
  return PARTNERS[key]?.internalId
}

export function getLogo(key: string) {
  return `/enterprise/${getInternalId(key)}.svg`
}

export function getName(key: string) {
  return PARTNERS[key]?.name ?? ''
}

export function getNameTechnical(key: string) {
  if (!PARTNERS[key]) return ''
  return PARTNERS[key].nameTechnical || PARTNERS[key].name
}

export function getReefAndOrOrcaName(key: string, conjunction: string = ' or ') {
  const orcaName = getNameTechnical(ORCA_KEY)
  const reefName = getNameTechnical(REEF_KEY)
  if (PARTNERS_ORCA.includes(key)) {
    return `${orcaName} member code`
  }
  if (key === REEF_KEY) {
    return `${reefName} confirmation code`
  }
  return `${reefName + conjunction + orcaName} code`
}

export function getTerms(key) {
  return PARTNERS[key]?.terms
}

export function getPhoneNumber(key, format = false) {
  if (PARTNERS[key]?.phoneNumber) {
    return format ? formatPhone.display(PARTNERS[key]?.phoneNumber) : PARTNERS[key]?.phoneNumber
  }

  return format ? formatPhone.display(phoneNumbers.DEFAULT) : phoneNumbers.DEFAULT
}

export function getPhoneNumberDisplay(key) {
  return PARTNERS[key]?.phoneNumberDisplay ?? getPhoneNumber(key, true)
}

export function isClinical(key) {
  return PARTNERS_CLINICAL.includes(key)
}

export function isFitness(key) {
  return PARTNERS_FITNESS.includes(key)
}

export function skipEligibilityCheck(srcId: string) {
  return SRC_IDS_NO_ELIGIBILITY_CHECK.includes(srcId)
}

export async function validateExternalId(externalId: string) {
  try {
    await validations.REEF_ORCA_EXTERNAL_ID.validate(externalId)
    return true
  } catch (error) {
    console.error(error)
    return false
  }
}
