import React from 'react'
import Button from 'components/Button'
import paths from 'constants/paths'
import * as lessonsQuery from 'libs/lessons-query'
import ExploreLessons from '../ExploreLessons'

export default function ExploreLessonsBoldTalks() {
  const { data, isLoading } = lessonsQuery.useLessonsBoldTalks()

  // TODO - deprioritize completed classes to bottom for carousel?
  return (
    <ExploreLessons
      title="Hear from experts in educational Bold Talks — no movement involved!"
      action={<Button to={paths.EXPLORE_BOLD_TALKS} />}
      data={data?.data}
      eventKey="bold talks"
      loading={isLoading}
      className="ExploreLessonsBoldTalks"
    />
  )
}
