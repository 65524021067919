import React from 'react'
import Container from 'components/Container'
import View from 'components/View'
import * as events from 'constants/events'
import { useGlobalContext } from 'contexts/GlobalContext'
import Referral from 'features/user/components/Referral'
import * as url from 'libs/url'
import styles from './Referrals.module.scss'

export default function Referrals() {
  const globalContext = useGlobalContext()

  React.useEffect(() => {
    const { source } = url.getQueryString()

    globalContext.analytics?.markPageVisited(events.REFERRALS_PAGE, { source })
  }, [globalContext.analytics])

  return (
    <View className="Referrals">
      <Container flush size="xlarge">
        <div className={styles.box}>
          <div className={styles['box--image']} />
          <Referral />
        </div>
      </Container>
    </View>
  )
}
