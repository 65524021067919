import classNames from 'classnames'
import { getDay } from 'date-fns'
import _ from 'lodash'
import { DateTime } from 'luxon'
import pluralize from 'pluralize'
import React from 'react'
import Box from 'components/Box'
import Heading2 from 'components/Heading2'
import Link from 'components/Link'
import MediaObject from 'components/MediaObject'
import Row from 'components/Row'
import Text from 'components/Text'
import * as events from 'constants/events'
import paths from 'constants/paths'
import type { Component } from 'constants/types'
import { useGlobalContext } from 'contexts/GlobalContext'
import * as date from 'libs/date'
import styles from './DashboardActivity.module.scss'
import { ReactComponent as CalendarSvg } from './images/calendar.svg'
import { ReactComponent as GraphSvg } from './images/graph.svg'
import { ReactComponent as StreakSvg } from './images/streak.svg'

type Data = {
  allTimeClassesWatched: number
  recentUserStats: []
  streak: number
}

type Props = {
  data: Data
} & Component

export default function DashboardActivity({ data }: Props) {
  const globalContext = useGlobalContext()

  if (_.isNil(data) || _.isEmpty(data)) return null

  function handleViewAllButtonClick() {
    globalContext.analytics?.trackEvent(events.DASHBOARD_ACTIVITY_VIEW_ALL_BUTTON_CLICK)
  }

  const startOfMonth = DateTime.now().setZone(date.TIMEZONE).startOf('month')
  const activeDaysThisMonthSet = new Set(
    data.recentUserStats
      ?.map((item) => DateTime.fromISO(item.date).setZone(date.TIMEZONE).toISODate()) // convert to YYYY-MM-DD
      .filter((item) => DateTime.fromISO(item).setZone(date.TIMEZONE) >= startOfMonth)
  )
  const daysActiveThisMonth = _.size(activeDaysThisMonthSet)
  const stats = [
    {
      copy: 'Week streak',
      // TODO
      color: '#fef4c6',
      icon: StreakSvg,
      value: data.streak,
    },
    {
      copy: `${pluralize('Day', daysActiveThisMonth)} this month`,
      // TODO
      color: '#fff5e6',
      icon: CalendarSvg,
      value: daysActiveThisMonth,
    },
    {
      copy: `Total ${pluralize('class', data.allTimeClassesWatched)}`,
      // TODO
      color: '#cbfbf1',
      icon: GraphSvg,
      value: data.allTimeClassesWatched,
    },
  ]
  const todayIndex = getDay(new Date())
  const sundayThisWeek = DateTime.now().setZone(date.TIMEZONE).startOf('week').minus({ days: 1 }) // week starts on Monday in luxon but we start on Sunday
  const activeDaysThisWeekSet = new Set(
    data.recentUserStats
      ?.map((item) => DateTime.fromISO(item.date).setZone(date.TIMEZONE).toISODate()) // convert to YYYY-MM-DD to dedup by day
      .filter((item) => DateTime.fromISO(item).setZone(date.TIMEZONE) >= sundayThisWeek)
  )
  const daysOfWeekActive: number[] = []

  activeDaysThisWeekSet.forEach((dateString) => {
    daysOfWeekActive.push(DateTime.fromISO(dateString).setZone(date.TIMEZONE).weekday % 7)
  })

  return (
    <Box color="white" flush className={classNames('DashboardActivity', styles.this)}>
      <header className={styles.header}>
        <MediaObject
          figure={
            <Link to={paths.ACTIVITY} color="grey400" onClick={handleViewAllButtonClick}>
              <Text weight="bold">View more</Text>
            </Link>
          }
          center
          figurePosition="right">
          <Heading2 flush level={3} levelStyle={5}>
            My activity
          </Heading2>
        </MediaObject>
      </header>
      <div className={styles.body}>
        <Text
          align="center"
          casing="upper"
          color="black"
          element="div"
          size="small"
          weight="bold"
          className={styles.week}>
          {date.DAYS_OF_WEEK_XXSMALL.map((item: string, index: number) => (
            <div key={item}>
              <Row size="xxsmall">
                <div
                  key={item}
                  className={classNames(
                    styles['week--day'],
                    daysOfWeekActive?.includes(index) && styles['week--day---active'],
                    daysOfWeekActive?.includes(index) &&
                      index === todayIndex &&
                      styles['week--day---active---today']
                  )}
                />
              </Row>
              <span>{item}</span>
            </div>
          ))}
        </Text>
        <Text color="black" element="div" weight="bold" className={styles.stats}>
          {stats.map((item) => (
            <Text
              element="figure"
              key={item.copy}
              style={{ backgroundColor: item.color }}
              className={styles.stat}>
              <MediaObject figure={<item.icon />} center size="xsmall">
                <Text element="div">{item.value || '0'}</Text>
                <Text size="xsmall">{item.copy}</Text>
              </MediaObject>
            </Text>
          ))}
        </Text>
      </div>
    </Box>
  )
}
