import _ from 'lodash'
import React from 'react'
import Button from 'components/Button'
import paths from 'constants/paths'
import type { Component } from 'constants/types'
import type { LessonStream } from 'libs/lesson'
import * as liveLessons from 'libs/live-lessons'
import DashboardLessons from '../DashboardLessons'

type Props = {
  data: LessonStream[]
} & Component

export default function DashboardLive({ data }: Props) {
  const [lessons, setLessons] = React.useState<LessonStream[]>([])

  React.useEffect(() => {
    setLessons(liveLessons.sortAndFilterUpcoming(data))
  }, [data])

  if (_.isEmpty(data)) return null

  return (
    <DashboardLessons
      title="Upcoming live classes"
      action={<Button to={paths.LIVE_LESSONS}>See all live classes</Button>}
      data={lessons}
      hover={false}
      loading={_.isEmpty(lessons)}
      className="DashboardLiveLessons"
    />
  )
}
