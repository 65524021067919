import type { PageProps } from 'gatsby'
import React from 'react'
import HomeSpecial from 'components/landing/HomeSpecial'
import HomeSpecialLayout from 'components/landing/HomeSpecialLayout'

type Props = PageProps

export default function HomeSpecialPage(props: Props) {
  return (
    <HomeSpecialLayout {...props}>
      <HomeSpecial />
    </HomeSpecialLayout>
  )
}
