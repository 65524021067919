import classNames from 'classnames'
import _ from 'lodash'
import numberToWords from 'number-to-words'
import React from 'react'
//import Button, { type ButtonProps } from 'components/Button'
import Button from 'components/Button'
//import ButtonClose from 'components/ButtonClose'
//import Buttons from 'components/Buttons'
import Container from 'components/Container'
import Copy from 'components/Copy'
import Divider from 'components/Divider'
import LessonTrainer from 'components/LessonTrainer'
import MediaObject from 'components/MediaObject'
import ModalBox from 'components/ModalBox'
import Row from 'components/Row'
import Text from 'components/Text'
import TextWithIcon from 'components/TextWithIcon'
import View from 'components/View'
import { useGlobalContext } from 'contexts/GlobalContext'
//import Media from 'react-media'
import LessonFavoriteButton from 'features/lesson/components/LessonFavoriteButton'
import LessonVideo from 'features/lesson/components/LessonVideo'
import ProgramHeaderPostLesson from 'features/program/components/Program/components/ProgramHeaderPostLesson'
import ProgramGoal from 'features/program/components/ProgramGoal'
import { useUserChallenges } from 'libs/challenge-query'
import type { Lesson } from 'libs/lesson'
import { useLessonsForProgramStatic, useProgram } from 'libs/program-query'
//import constants from 'styles/constants.module.scss'
import styles from './ProgramHeader.module.scss'
import ProgramLessonSkipButton from './components/ProgramLessonSkipButton'
import recommendationCopyConstants from './constants/recommendation-copy'
import { ReactComponent as ChairSvg } from './images/chair.svg'
import { ReactComponent as PlaySvg } from './images/play.svg'

function getRecommendationIntensityCopy(lessonIntensity?: number) {
  if (!lessonIntensity) return null

  let intensityCopy = recommendationCopyConstants.intensity.MEDIUM

  if (lessonIntensity < 3.5) {
    intensityCopy = recommendationCopyConstants.intensity.LOW
  }
  if (lessonIntensity > 6.5) {
    intensityCopy = recommendationCopyConstants.intensity.HIGH
  }

  return intensityCopy
}

function getRecommendationCopy(lesson: Lesson) {
  const { instructorName } = lesson

  if (!instructorName || !lesson.type) return null

  const trainerCopy =
    recommendationCopyConstants.instructor[instructorName] ||
    `${
      instructorName !== 'Guest Trainer' ? instructorName.split(' ')[0] : 'This instructor'
    } focuses on building strength and stability`
  const positionCopy =
    recommendationCopyConstants.position[lesson.type] ||
    'Exercises designed for a safe and effective workout'

  return [trainerCopy, positionCopy, getRecommendationIntensityCopy(lesson.average_intensity)]
}

type Props = {
  lessonOverride: Lesson | undefined
  // eslint-disable-next-line no-unused-vars
  onLessonClick: (lesson: Lesson) => void
  onLessonOverride: () => void
  // eslint-disable-next-line no-unused-vars
  onLessonSkipped: (lessonId: string) => void
  onPlaySkippedLesson: () => void
  // eslint-disable-next-line no-unused-vars
  onPostLessonStateChange: (lessonState: boolean) => void
  onVideoEnd: () => Promise<void>
}

export default function ProgramHeader({
  lessonOverride,
  onLessonClick,
  onLessonOverride,
  onLessonSkipped,
  onPlaySkippedLesson,
  onPostLessonStateChange,
  onVideoEnd,
}: Props) {
  const globalContext = useGlobalContext()
  const { data: programData, mutate: mutateProgram } = useProgram({ revalidateOnFocus: false })
  const program = programData?.data
  const exerciseProgramId = program?.exerciseProgram?.id
  const { data: programLessonsData, mutate: mutateProgramLessons } = useLessonsForProgramStatic(
    exerciseProgramId,
    {
      dependencies: [programData],
      revalidateOnFocus: false,
    }
  )
  const { mutate: mutateUserProgramChallenges } = useUserChallenges(
    {
      type: 'program',
    },
    {
      revalidateOnFocus: false,
    }
  )
  const [currentLesson, setCurrentLesson] = React.useState<Lesson | undefined>()
  const [lessonIndex, setLessonIndex] = React.useState()
  const [lessonIndexPrevious, setLessonIndexPrevious] = React.useState()
  const [previousLesson, setPreviousLesson] = React.useState<Lesson>()
  const [showPostLesson, setShowPostLesson] = React.useState(false)
  const [lessonIsActive, setLessonIsActive] = React.useState(false)
  const [showAfterEducationPlayIcon] = React.useState(false)
  const [sessionId, setSessionId] = React.useState(null)
  const [showVideoEducationSkipFooter, setShowVideoEducationSkipFooter] = React.useState<
    boolean | undefined
  >()

  const programLessons = programLessonsData?.data

  React.useEffect(() => {
    if (!program || _.isEmpty(programLessons) || lessonIsActive) return

    if (lessonOverride) {
      const lessons = programLessonsData?.data
      const overrideId = lessonOverride?.id
      const indexOfOverride = lessons.findIndex((lesson: { id: any }) => lesson.id === overrideId)
      setLessonIndex(indexOfOverride)
      setShowPostLesson(false) // not sure why this stopped working...
    } else {
      let currentIndex = programLessons.findIndex(
        (item: { userView: { status: string } }, index: number) =>
          item.userView?.status !== 'completed' &&
          index !== lessonIndexPrevious &&
          item.userView?.status !== 'skipped'
      )

      // if all lessons are completed, just show last class
      if (currentIndex < 0) {
        currentIndex = programLessons.length - 1
      }
      setLessonIndex(currentIndex)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lessonIndexPrevious, lessonOverride, programLessons])

  React.useEffect(() => {
    if (_.isNil(lessonIndex) || !programLessons) return
    setCurrentLesson(programLessons[lessonIndex])
    mutateProgramLessons()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lessonIndex])

  React.useEffect(() => {
    if (onPostLessonStateChange) onPostLessonStateChange(showPostLesson)
  }, [showPostLesson, onPostLessonStateChange])

  const isEducation = currentLesson?.purpose === 'education'

  function handleVideoProgress(progressSeconds: number) {
    if (!isEducation || progressSeconds < 48 || showVideoEducationSkipFooter === false) {
      return
    }

    setShowVideoEducationSkipFooter(true)
  }

  function updateLessons() {
    const indexOfPlayingLesson = programLessons.findIndex(
      (lesson) => lesson.id === currentLesson.id
    )
    setLessonIndexPrevious(indexOfPlayingLesson)
    setPreviousLesson(programLessons[indexOfPlayingLesson])
  }

  function handleSkip(lessonId: string) {
    setLessonIsActive(false)
    if (onLessonSkipped) {
      onLessonSkipped(lessonId)
    }
  }

  function handleSkipFeedbackSuccess() {
    onLessonOverride()
    updateLessons()
    mutateUserProgramChallenges()
  }

  const handlePlaySkippedLesson = () => {
    if (onPlaySkippedLesson) {
      onPlaySkippedLesson()
    }
  }

  async function handlePlay() {
    setLessonIsActive(true)
  }

  async function handleVideoEnd() {
    setLessonIsActive(false)
    await onVideoEnd()

    updateLessons()
    setShowPostLesson(true)
  }

  function handleNextLessonClick() {
    setShowPostLesson(false)
    mutateProgramLessons()
    mutateUserProgramChallenges()
    mutateProgram()

    if (onLessonClick) onLessonClick(currentLesson) // update tags on videos in ProgramBlock
  }

  function handleSessionIdUpdate(newSessionId: string) {
    setSessionId(newSessionId)
  }

  if (!currentLesson) return null

  const hasRequiredItems =
    currentLesson.requiredItems && currentLesson.requiredItems?.toLowerCase() !== 'none'

  const isMobile = /android|iphone/i.test(navigator.userAgent)

  const recommendationCopy = getRecommendationCopy(currentLesson)

  const title = (
    <Text element="h1" size="xxlarge" weight="bold" data-test-id="program-header-title">
      {currentLesson.videoTitle}
    </Text>
  )

  const afterEducationPlayIcon = (
    <Container flush size="xmedium">
      <ModalBox
        size="small"
        title={<Row size="xxxsmall">Let’s get moving, {globalContext.user.firstName}!</Row>}
        subtext={
          <Row size="xxxsmall">
            {lessonIndexPrevious || lessonIndexPrevious === 0
              ? `Congratulations, you finished the ${numberToWords.toWordsOrdinal(
                  lessonIndexPrevious + 1
                )} class in your program!
              Now, it’s time to move. ${
                lessonIndexPrevious <= 1 ? 'Start ' : 'Continue '
              }your exercise program today.`
              : 'Congratulations on your progress so far! Now, it’s time to move.'}
          </Row>
        }>
        <Button color="yellow" icon={<PlaySvg />} size="xlarge">
          Start class
        </Button>
      </ModalBox>
    </Container>
  )

  return (
    <header className="ProgramHeader">
      <View>
        {showPostLesson ? (
          <ProgramHeaderPostLesson
            data={previousLesson}
            lessonNext={currentLesson}
            onLessonNextClick={handleNextLessonClick}
          />
        ) : (
          <div className={styles['video-and-sidebar']}>
            <div className={classNames(styles['video-wrap'])}>
              <LessonVideo
                customPlayIcon={showAfterEducationPlayIcon ? afterEducationPlayIcon : undefined}
                lesson={currentLesson}
                playSkippedLesson={handlePlaySkippedLesson}
                thumbnail={currentLesson.videoThumbnail}
                url={currentLesson.videoLink}
                userExerciseProgramId={program.id}
                className={styles.video}
                onEnd={handleVideoEnd}
                onPlay={handlePlay}
                onProgress={handleVideoProgress}
                onSessionIdUpdate={handleSessionIdUpdate}
              />
              {/*isEducation && (
                <footer
                  className={classNames(
                    styles['video-skip-footer'],
                    showVideoEducationSkipFooter && styles['video-skip-footer---visible']
                  )}>
                  <Media query={{ minWidth: constants.GT_MOBILE_MEDIUM }}>
                    <MediaObject
                      figure={<ButtonClose {...videoSkipFooterCloseButtonProps} />}
                      center
                      figurePosition="right">
                      <MediaObject
                        figure={<Button {...videoSkipFooterButtonProps} size="xsmall" />}
                        center
                        figurePosition="right"
                        inline
                        size="xsmall">
                        {videoSkipFooterCopy}
                      </MediaObject>
                    </MediaObject>
                  </Media>
                  <Media query={{ maxWidth: constants.MOBILE_MEDIUM }}>
                    <Text align="center">
                      <Buttons axis="y" size="small">
                        {videoSkipFooterCopy}
                        <Button {...videoSkipFooterButtonProps} full />
                        <Button {...videoSkipFooterCloseButtonProps} level="text">
                          Close
                        </Button>
                      </Buttons>
                    </Text>
                  </Media>
                </footer>
              )*/}
            </div>
            <Text element="div" className={styles.sidebar}>
              <div>
                <Row size="xsmall">{title} </Row>
                <Row size="smallx">
                  <LessonTrainer data={currentLesson} size="large" weight="normal" />
                </Row>
                <Row size="small">
                  <LessonFavoriteButton lesson={currentLesson} lessonId={currentLesson.id} />
                </Row>
                <Row size="xmedium">
                  <Row size="small">
                    <div className={styles.skip}>
                      <div className={styles['skip--body']}>
                        {recommendationCopy && !isEducation ? (
                          <>
                            <Text element="h2" weight="bold">
                              Why we chose this class for you
                            </Text>
                            <Divider thin size="xsmall" />
                            <Copy>
                              <Text element="ul">
                                {recommendationCopy.filter(Boolean).map((item) => (
                                  <Row element="li" key={item} size="xsmall">
                                    {item}
                                  </Row>
                                ))}
                              </Text>
                            </Copy>
                          </>
                        ) : (
                          <>
                            <Row size="xxxsmall">
                              <Text element="h2" weight="bold">
                                About class
                              </Text>
                            </Row>
                            <Text element="p" flush>
                              {currentLesson.exerciseBlocks?.exerciseBlockLesson
                                ?.blockLessonDescription || currentLesson.instructions}
                            </Text>
                          </>
                        )}
                      </div>
                      <div className={styles['skip--footer']}>
                        <MediaObject
                          figure={
                            <ProgramLessonSkipButton
                              lesson={currentLesson}
                              sessionId={sessionId}
                              userExerciseProgramId={program.id}
                              onFeedbackSuccess={handleSkipFeedbackSuccess}
                              onSkip={handleSkip}
                            />
                          }
                          center
                          figurePosition="right">
                          <Text color="black" weight="bold">
                            Want to take a different class?
                          </Text>
                        </MediaObject>
                      </div>
                    </div>
                  </Row>
                  {hasRequiredItems && !recommendationCopy && (
                    <>
                      <Row size="xxxsmall">
                        <Text element="h2" weight="bold">
                          Equipment
                        </Text>
                      </Row>
                      <Copy>
                        <TextWithIcon
                          icon={
                            currentLesson.requiredItems?.toLowerCase().includes('chair') ? (
                              <ChairSvg />
                            ) : undefined
                          }
                          inline>
                          {currentLesson.requiredItems?.replace(/,/g, ', ')}
                        </TextWithIcon>
                      </Copy>
                    </>
                  )}
                </Row>
                <Row flush={!isMobile} size="xmedium">
                  <ProgramGoal />
                </Row>
              </div>
            </Text>
          </div>
        )}
      </View>
    </header>
  )
}
