import React from 'react'
import AuthButton from 'components/AuthButton'
import Link from 'components/Link'
import Row from 'components/Row'
import Text, { type TextProps } from 'components/Text'
import emails from 'constants/emails'
import EnterpriseClinicalLanding from 'features/enterprise/clinical/components/EnterpriseClinicalLanding'
import * as enterprise from 'libs/enterprise'
import { ReactComponent as LogosSvg } from './images/logos.svg'

export default function EnterpriseGrouper() {
  const partner = enterprise.GROUPER_KEY

  const faqs = [
    {
      title: 'What is Bold?',
      copy: (
        <>
          <Text element="p">
            Bold’s online fall prevention program includes guided video exercise classes that
            specifically focus on increasing balance. The classes are led by expert trainers that
            specialize in adapting exercises for healthy aging. The classes include a variety of
            functional fitness exercises, tai chi, and mobility movements.
          </Text>
          <Text element="p" flush>
            As a member, you’ll also receive support from Bold to help you set goals and build a
            sustainable routine, as well as any technical support you may need around accessing the
            program.
          </Text>
        </>
      ),
    },
    {
      title: 'How does the program work?',
      copy: (
        <>
          <Text element="p">
            When signing up for Bold, you will answer some onboarding questions, which allow Bold to
            create a personalized program tailored to your needs and based on your current exercise
            preferences, health conditions, and goals. As you take your exercise classes, which may
            range from 5 to 35+ minutes, depending on your program, you will focus on movements
            designed to improve balance, strength, and mobility. You will have the opportunity to
            provide feedback after every class to help ensure the exercises are at the right
            intensity and pace.
          </Text>
          <Text element="p" flush>
            In addition, Bold offers features to help you stay motivated and active throughout the
            program, so that by the end of your first program, you will feel stronger, more
            balanced, healthier, and at lower risk of falls.
          </Text>
        </>
      ),
    },
    {
      title: 'Is there a cost?',
      copy: 'Bold, a part of the Steady Together program, is offered to qualifying members by UnitedHealthcare Medicare Advantage at no additional cost.',
    },
    {
      title: 'What do I need to participate in Bold?',
      copy: 'You will need internet access to be able to view the classes on Bold’s website. You can access Bold through any internet-connected device, including a mobile phone, by going to Bold’s website from your internet browser. There is no equipment required to participate in. We do recommend you have a sturdy chair nearby (without wheels) to use when instructed during class.',
    },
    {
      title: 'Is Bold right for me?',
      copy: 'Many members participate in Bold’s fall prevention program while also doing physical therapy or other exercise routines. Talk to your doctor to ensure balance training exercises are safe to do during this time.',
    },
    {
      title: 'What if I need help signing up or have more questions?',
      copy: (
        <>
          You can reach Bold Monday – Friday, 8AM to 5PM Pacific Time by phone at{' '}
          {enterprise.getPhoneNumberDisplay(partner)} or you can email us at{' '}
          <Link to={emails.ENROLLMENT} />.
        </>
      ),
    },
  ]

  const sources = [
    {
      copy: 'Exercise for preventing falls in older people living in the community',
      url: 'https://europepmc.org/article/MED/30703272',
    },
    {
      copy: 'Falls and fractures in older adults: Causes and prevention',
      url: 'https://nia.nih.gov/health/falls-and-fractures-older-adults-causes-and-prevention',
    },
    {
      copy: 'A digital health fall prevention program for older adults: Feasibility study',
      url: 'https://formative.jmir.org/2021/12/e30558',
    },
  ]

  const textYellowProps: Partial<TextProps> = {
    color: 'yellow',
    weight: 'bold',
  }

  return (
    <EnterpriseClinicalLanding
      faqs={faqs}
      footer={
        <>
          <Row>
            <Row size="xxsmall">
              <Text element="h4" weight="bold">
                Disclaimer
              </Text>
            </Row>
            <Text element="p" flush>
              This information is for educational purposes only and is not a substitute for the
              advice of a licensed medical provider. Consult your provider prior to beginning an
              exercise program or making changes to your lifestyle or health care routine.
            </Text>
          </Row>
          <Row size="xxsmall">
            <Text element="h4" weight="bold">
              Sources
            </Text>
          </Row>
          <Text copy element="ol">
            {sources.map((item, index) => (
              <Row element="li" key={item.url} size="xxxsmall">
                <Text element="sup">
                  {index + 1}
                  {'\u00A0'}
                </Text>
                <Link to={item.url} color="grey">
                  {item.copy}
                </Link>
              </Row>
            ))}
          </Text>
        </>
      }
      heroProps={{
        title: <LogosSvg />,
        subtext: (
          <>
            <Text element="p">
              Meet Bold—your at-home, digital strength and <Text {...textYellowProps}>balance</Text>{' '}
              exercise program designed to <Text {...textYellowProps}>reduce falls</Text> and help
              you live your strongest and healthiest life.
            </Text>
            <Text element="p" flush>
              Bold, a part of the Steady Together program, is offered to qualifying members by
              UnitedHealthcare Medicare Advantage at no additional cost.
            </Text>
          </>
        ),
      }}
      partner={partner}
      scienceProps={{
        title: 'Beat the odds with Bold',
        button: <AuthButton type="sign-up" full size="large" />,
        children: (
          <>
            Over 1 in 3 older adults fall each year.
            <Text element="sup">1</Text> However, most falls are preventable. Research shows that
            exercise can help boost and condition balance.
            <Text element="sup">2</Text> Bold balance programs are proven to lower fall risk by{' '}
            <Text color="purple" weight="bold">
              46%
            </Text>
            .<Text element="sup">3</Text>
          </>
        ),
      }}
      className="EnterpriseGrouper"
      data-test-id="home"
    />
  )
}
