/* eslint-disable no-case-declarations */
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import Button from 'components/Button'
import DateTime from 'components/DateTime'
import MediaObject from 'components/MediaObject'
import Row from 'components/Row'
import Text from 'components/Text'
import paths from 'constants/paths'
import type { Component } from 'constants/types'
import { useGlobalContext } from 'contexts/GlobalContext'
import type { Assessment } from 'libs/assessments'
import * as assessmentsLib from 'libs/assessments'
import * as assessmentsApiRequest from 'libs/assessments-api-request'
import styles from './AssessmentCards.module.scss'
import { ReactComponent as CalendarSvg } from './images/calendar.svg'
import { ReactComponent as CheckmarkSvg } from './images/checkmark.svg'

const SCORE_COLORS = {
  aboveAverage: 'cyan',
  belowAverage: 'purple',
}

type AssessmentCardsScoreProps = {
  dateKey: string
  item: Assessment
  scoreComparisonKey: string
  scoreKey: string
  size: Props['size']
  title: string
}

function AssessmentCardsScore({
  dateKey,
  item,
  scoreComparisonKey,
  scoreKey,
  size,
  title,
}: AssessmentCardsScoreProps) {
  let scoreSection = null

  switch (item.type) {
    case assessmentsLib.ASSESSMENT_TYPE_BALANCE: {
      scoreSection = (
        <div className={styles['item--score--checkmarks']}>
          {[0, 1, 2, 3].map((index) => (
            <Text color="white" element="div" key={index} className={styles['item--score--circle']}>
              {index < item[scoreKey] && (
                <CheckmarkSvg
                  aria-label="Completed"
                  className={styles['item--score--circle--checkmark']}
                />
              )}
            </Text>
          ))}
        </div>
      )
      break
    }
    case assessmentsLib.ASSESSMENT_TYPE_MOVEMENT: {
      const sectionCopy = assessmentsLib.MOVEMENT_ASSESSMENT_RESULTS_MAP[item[scoreComparisonKey]]
      const name = _.get(
        sectionCopy,
        'name',
        assessmentsLib.MOVEMENT_ASSESSMENT_RESULTS_MAP.double_leg_weight_bearing.name
      )
      const about = _.get(
        sectionCopy,
        'about',
        assessmentsLib.MOVEMENT_ASSESSMENT_RESULTS_MAP.double_leg_weight_bearing.about
      )
      scoreSection = (
        <>
          <Row>
            <Text
              color="purple"
              casing="upper"
              element="h4"
              size={size === 'small' ? 'small' : undefined}
              weight="bold">
              {name}
            </Text>
          </Row>
          <Row size="xsmall">
            <Text
              casing="upper"
              element="h4"
              size={size === 'small' ? 'small' : undefined}
              weight="bold">
              About this level
            </Text>
          </Row>
          <Text element="p" flush>
            {about}
          </Text>
        </>
      )
      break
    }
    default: {
      scoreSection = (
        <Text
          color={SCORE_COLORS[item[scoreComparisonKey]]}
          element="div"
          size="xxxlarge"
          weight="bold">
          {item[scoreKey]}
          {item.type === assessmentsLib.ASSESSMENT_TYPE_TIMED && 's'}
        </Text>
      )
      break
    }
  }

  return (
    <div className={styles['item--score']}>
      <Row size="xsmall">
        <Text
          casing="upper"
          element="h4"
          size={size === 'small' ? 'small' : undefined}
          weight="bold">
          {title}
        </Text>
      </Row>
      {item[scoreKey] ? (
        <>
          <Row size="xsmall">{scoreSection}</Row>
          {size !== 'small' && (
            <Text casing="upper" element="div" size="small" weight="bold">
              <MediaObject
                center
                figure={<CalendarSvg className={styles['item--calendar-icon']} />}
                inline
                size="xxsmall">
                <DateTime data={item[dateKey]} />
              </MediaObject>
            </Text>
          )}
        </>
      ) : (
        <Text size="xxxlarge">–</Text>
      )}
    </div>
  )
}

type Props = {
  size?: 'medium' | 'small'
} & Component

export default function AssessmentCards({ className, size = 'medium', ...props }: Props) {
  const globalContext = useGlobalContext()
  const [assessments, setAssessments] = React.useState(
    //assessmentsLib.ITEMS
    []
  )

  React.useEffect(() => {
    async function getAssessments() {
      const response = await assessmentsApiRequest.getAssessments(globalContext.user?.id)

      setAssessments(response)
    }
    getAssessments()
  }, [globalContext.user?.id])

  if (_.isEmpty(assessments)) return null

  return (
    <div
      {...props}
      className={classNames('AssessmentCards', styles.this, styles[`this---${size}`], className)}>
      <div className={styles.inner}>
        <div className={styles.cards}>
          {assessments?.map((item: Assessment) => {
            const assessment = assessmentsLib.getByInternalId(item.internalId)

            if (_.isEmpty(assessment)) return null

            let scores = (
              <div className={styles['item--scores']}>
                <AssessmentCardsScore
                  title="Last score"
                  item={item}
                  dateKey="lastRecordDate"
                  scoreComparisonKey="lastResult"
                  scoreKey="lastRecord"
                  size={size}
                />
                <AssessmentCardsScore
                  title="Best score"
                  item={item}
                  dateKey="bestRecordDate"
                  scoreComparisonKey="bestResult"
                  scoreKey="bestRecord"
                  size={size}
                />
              </div>
            )

            if (item.type === assessmentsLib.ASSESSMENT_TYPE_MOVEMENT) {
              scores = (
                <div className={styles['item--scores']}>
                  <AssessmentCardsScore
                    title="Your movement level"
                    item={item}
                    dateKey="lastRecordDate"
                    scoreComparisonKey="lastResult"
                    scoreKey="lastRecord"
                    size={size}
                  />
                </div>
              )
            }

            return (
              <Text align="center" element="div" key={item.internalId} className={styles.item}>
                <header className={styles['item--header']}>
                  {size !== 'small' && (
                    <Row size="xsmall">
                      <Text
                        color="white"
                        element="div"
                        aria-hidden
                        className={styles['item--header--icon']}>
                        <assessment.icon className={styles['item--header--icon--svg']} />
                      </Text>
                    </Row>
                  )}
                  <Text align="center" casing="upper" element="h3" size="large" weight="bold">
                    {assessment.title}
                  </Text>
                </header>
                {scores}
                <footer className={styles['item--footer']}>
                  <Row size="xsmall">
                    <Text
                      casing="upper"
                      element="h4"
                      size={size === 'small' ? 'small' : undefined}
                      weight="bold">
                      About this assessment
                    </Text>
                  </Row>
                  <Text
                    element="p"
                    size={size === 'small' ? 'small' : undefined}
                    className={styles['item--copy']}>
                    {assessment.description}
                  </Text>
                  <Button to={`${paths.ASSESSMENTS}${assessment.internalId}/`} color="yellow" full>
                    <Text wrap={false}>
                      {item.lastRecordDate ? 'Retake assessment' : 'Take assessment'}
                    </Text>
                  </Button>
                </footer>
              </Text>
            )
          })}
        </div>
      </div>
    </div>
  )
}
