import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import Media from 'react-media'
import useWindowSize from 'react-use/lib/useWindowSize'
import Heading2 from 'components/Heading2'
import Loader from 'components/Loader'
import MediaObject from 'components/MediaObject'
import Row from 'components/Row'
import type { Component } from 'constants/types'
import LessonBox from 'features/lesson/components/LessonBox'
import LiveLessonBox from 'features/live-lesson/components/LiveLessonBox'
import constants from 'styles/constants.module.scss'
import styles from './DashboardLessons.module.scss'

type Props = {
  action?: React.ReactElement
  data: {}[]
  hover?: boolean
  loading?: boolean
  title: string
} & Component

export default function DashboardLessons({
  action: propsAction,
  className,
  data,
  hover = true,
  loading = true,
  title: propsTitle,
}: Props) {
  const { width } = useWindowSize()

  const hasAction = !!propsAction && _.size(data) > 3

  const action =
    hasAction &&
    React.cloneElement(propsAction, {
      children: propsAction.props?.children || 'See all',
      color: 'white',
    })

  const titleHeading = (
    <Heading2 level={2} levelStyle={4}>
      {propsTitle}
    </Heading2>
  )

  const title = !hasAction ? (
    titleHeading
  ) : (
    <Row size="small">
      <MediaObject
        figure={<Media query={{ minWidth: constants.GT_MOBILE }}>{action}</Media>}
        center
        figurePosition="right">
        {React.cloneElement(titleHeading, { flush: true })}
      </MediaObject>
    </Row>
  )

  if (!loading && _.isEmpty(data)) return null

  return (
    <section
      className={classNames(
        'DashboardLessons',
        styles.this,
        !hover && styles['this---no-hover'],
        className
      )}>
      {title}
      <Row size="small">
        {loading ? (
          <Loader overlay={false} />
        ) : (
          <div className={styles['grid-wrap']}>
            <div className={styles.grid}>
              {data
                .slice(0, 3)
                .map((item) =>
                  item.scheduledStartTs ? (
                    <LiveLessonBox data={item} key={item.id} size="small" />
                  ) : (
                    <LessonBox
                      data={item}
                      hover={width > Number(constants.LT_FULL_PX)}
                      key={item.id}
                    />
                  )
                )}
            </div>
          </div>
        )}
      </Row>
      {!!hasAction && <Media query={{ maxWidth: constants.MOBILE }}>{action}</Media>}
    </section>
  )
}
