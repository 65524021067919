import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import Heading2 from 'components/Heading2'
import RequestDemoForm from 'components/RequestDemoForm'
import Row from 'components/Row'
import Text from 'components/Text'
import paths from 'constants/paths'
import GatedResource from 'templates/GatedResource'

export default function RequestDemo() {
  const [showSuccess, setShowSuccess] = React.useState(false)

  function handleRequestDemoSuccess() {
    setShowSuccess(true)
  }

  const title = 'Request a demo'

  return (
    <GatedResource
      title="Join the movement for movement as medicine"
      formAlternate={<RequestDemoForm onSuccess={handleRequestDemoSuccess} />}
      formTitle="Request a meeting with Bold"
      hideDisclaimer
      logoTo={paths.PARTNERSHIPS}
      resource={title}
      sidebar={
        showSuccess ? (
          <>
            <Heading2 levelStyle={4}>Thank you for requesting a meeting with Bold</Heading2>
            <Text element="p" flush size="large">
              We’ll be in touch soon.
            </Text>
          </>
        ) : undefined
      }
      className="RequestDemo">
      <Row>
        <Text element="p" flush>
          Improve Star Ratings, increase plan profitability, and make members healthier and happier
          with Bold.
        </Text>
      </Row>
      <StaticImage src="./images/photo@2x.jpg" alt="" />
    </GatedResource>
  )
}
