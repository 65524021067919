import classNames from 'classnames'
import React from 'react'
import AuthButton from 'components/AuthButton'
import ContactButton from 'components/ContactButton'
import Container from 'components/Container'
import Row from 'components/Row'
import SignUpEmailForm from 'components/SignUpEmailForm'
import Text from 'components/Text'
import type { Component } from 'constants/types'
import * as auth from 'libs/auth'
import styles from './SignUpBox.module.scss'
import { ReactComponent as LogoSvg } from './images/logo.svg'

type Props = Component

export default function SignUpBox({ className }: Props) {
  const signUpType = auth.determineSignUp()

  return (
    <div className={classNames('SignUpBox', styles.this, className)}>
      <Container flush size="small">
        <Text align="center" element="div">
          <LogoSvg aria-hidden className={styles.logo} />
          <Row>
            <Text element="h2" size="xlarge" weight="bold">
              Sign up for Bold
            </Text>
          </Row>
          <Row size="small">
            {signUpType === 'email' ? (
              <SignUpEmailForm
                buttonProps={{
                  color: 'yellow',
                  id: 'assessments--sign-up-button',
                }}
              />
            ) : (
              <AuthButton type="sign-up" color="yellow" />
            )}
          </Row>
          <Text color="blue" element="p" flush>
            Still unsure how Bold can help?{' '}
            <ContactButton color="inherit" level="text">
              <Text element="u">Contact us</Text>
            </ContactButton>
          </Text>
        </Text>
      </Container>
    </div>
  )
}
