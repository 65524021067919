import { navigate } from 'gatsby'
import React from 'react'
//import Container from 'components/Container'
import Modal, { type ModalProps } from 'components/Modal'
import ModalBox from 'components/ModalBox'
import ModalConfirm from 'components/ModalConfirm'
import Text from 'components/Text'
import * as events from 'constants/events'
import paths from 'constants/paths'
import { useGlobalContext } from 'contexts/GlobalContext'
import CheckinSingleForm from 'features/checkin/components/CheckinSingleForm'
import * as checkinApiRequest from 'libs/checkin-api-request'
import * as logging from 'libs/logging'
import { useProgram } from 'libs/program-query'
import CheckinTracker from 'libs/checkins/checkin-tracker'

type Props = {
  numClassesCompleted?: number
  onClose?: Function
} & Partial<ModalProps>

export default function ProgramProgressionModals({
  numClassesCompleted,
  onClose,
  ...props
}: Props) {
  const globalContext = useGlobalContext()
  const { data: programData } = useProgram({
    revalidateOnFocus: false,
  })
  const program = programData?.data
  const [checkinTracker, setCheckinTracker] = React.useState<CheckinTracker>(null)
  const [showCheckinModal, setShowCheckinModal] = React.useState(false)
  const [showModal, setShowModal] = React.useState(false)

  React.useEffect(() => {
    async function getCheckin() {
      const feedbackCheckinResponse = await checkinApiRequest
        .getUserCheckin({
          type: 'after_program',
          code: 'after_program',
          activeOnly: true,
          createIfNotExists: true,
          objectId: program?.id,
        })
        .catch((error: any) => {
          globalContext.analytics?.trackEvent(events.FAILED_CHECKIN_CALL, {
            error,
            from: 'after_program',
            type: 'after_program',
          })

          logging.toDatadog(
            `Dynamic Program: afterProgramFeedback checkin failed for user: ${globalContext?.user?.id}`,
            {
              programId: program?.id,
            },
            error
          )
        })

      if (feedbackCheckinResponse.data) {
        setCheckinTracker(
          new CheckinTracker(
            globalContext.user?.id,
            feedbackCheckinResponse.data,
            globalContext.analytics
          )
        )
      }
    }

    getCheckin()
    return () => {
      // Optionally reset checkinTracker when the component unmounts or when switching lessons
      setCheckinTracker(null)
    }
  }, [globalContext.analytics, globalContext.user?.id, program])

  React.useEffect(() => {
    if (program?.status === 'completed') {
      setShowModal(true)
      globalContext.analytics?.trackEvent('Dynamic Program: Start program complete flow', {
        exerciseProgramTitle: 'My Bold Program',
        currentExerciseProgramId: program?.id,
      })
    } else {
      setShowModal(false)
    }
  }, [globalContext.analytics, program])

  const handleConfirmCompletion = () => {
    setShowCheckinModal(true)
    globalContext.analytics?.trackEvent('Dynamic program: Complete flow first modal click')
  }

  const handleModalClose = () => {
    setShowCheckinModal(false)
    if (Number(checkinTracker.metadata.after_program_meet_your_needs) <= 3) {
      navigate(paths.PROGRAM_CHANGE, { replace: true })
    } else {
      navigate(`${paths.PROGRAM_BUILD}?changeRequested=true`, { replace: true })
    }
    checkinApiRequest.markUserCheckinAsDismissed(checkinTracker.checkinId)
    globalContext.analytics?.trackEvent('Dynamic program: After program feedback incomplete')
  }

  const submitCheckin = async () => {
    setShowCheckinModal(false)
    if (Number(checkinTracker.metadata.after_program_meet_your_needs) <= 3) {
      navigate(paths.PROGRAM_CHANGE, { replace: true })
    } else {
      navigate(`${paths.PROGRAM_BUILD}?changeRequested=true`, { replace: true })
    }
    globalContext.analytics?.trackEvent('Dynamic program: After program feedback complete')
    await globalContext.updateUser()
  }

  const commonProps = {
    size: 'small',
    className: 'ProgramProgressionModals',
  }

  return (
    <>
      <ModalConfirm
        {...props}
        {...commonProps}
        title="Well done!"
        confirmButtonProps={{ color: 'yellow' }}
        confirmText="Continue"
        hideCancel
        hideClose
        isOpen={showModal}
        onConfirm={handleConfirmCompletion}
        onRequestClose={() => setShowModal(false)}>
        <Text element="p" flush>
          Congrats on finishing your program! Share some feedback to help us further personalize
          your experience.
        </Text>
      </ModalConfirm>
      <Modal isOpen={showCheckinModal} onRequestClose={handleModalClose}>
        <ModalBox>
          <CheckinSingleForm checkinTracker={checkinTracker} onSuccess={submitCheckin} />
        </ModalBox>
      </Modal>
    </>
  )
}
