import classNames from 'classnames'
import { navigate } from 'gatsby'
import _ from 'lodash'
import React from 'react'
import Box from 'components/Box'
import Button from 'components/Button'
import DateTime from 'components/DateTime'
import Image from 'components/Image'
import Loader from 'components/Loader'
import Row from 'components/Row'
import Text from 'components/Text'
import * as events from 'constants/events'
import paths from 'constants/paths'
import type { Component } from 'constants/types'
import { useGlobalContext } from 'contexts/GlobalContext'
import LiveLessonSignUpButton from 'features/live-lesson/components/LiveLessonSignUpButton'
import * as challengeLib from 'libs/challenge'
import type { UserChallenge } from 'libs/challenge'
import * as lessonLib from 'libs/lesson'
import type { Lesson, LessonStream } from 'libs/lesson'
import * as lessonApiRequest from 'libs/lesson-api-request'
import styles from './DashboardTodayLesson.module.scss'

type Props = {
  challenge?: UserChallenge
  challengeTag: string
  lesson: Lesson
  liveLesson?: LessonStream
  loading?: boolean
} & Component

export default function DashboardLesson({
  challenge,
  challengeTag,
  className,
  lesson,
  liveLesson,
  loading,
}: Props) {
  const globalContext = useGlobalContext()
  const [isChangeMoveTitle, setIsChangeMoveTitle] = React.useState<boolean>(false)
  const isChallengeCompleted = challenge?.complete

  React.useEffect(() => {
    async function getLesson() {
      if (
        isChallengeCompleted &&
        challengeTag === challengeLib.CHALLENGE_TAG.MOVE &&
        challenge?.lessonId
      ) {
        try {
          const response = await lessonApiRequest.getLessonById(challenge?.lessonId)
          if (response && response.data && lessonLib.isEducationClass(response.data.classType)) {
            setIsChangeMoveTitle(true)
          }
        } catch (error) {
          console.error('Error fetching lesson:', error)
        }
      } else if (
        !isChallengeCompleted &&
        challengeTag === challengeLib.CHALLENGE_TAG.MOVE &&
        !liveLesson &&
        lessonLib.isEducationClass(lesson.classType)
      ) {
        setIsChangeMoveTitle(true)
      } else if (liveLesson) {
        setIsChangeMoveTitle(false)
      }
    }
    getLesson()
  }, [isChallengeCompleted, challenge, challengeTag, liveLesson, lesson.classType])

  function handleStartLesson() {
    if (challengeTag === challengeLib.CHALLENGE_TAG.MOVE) {
      navigate(paths.PROGRAM)
      globalContext.analytics?.trackEvent(events.DASHBOARD_TODAYS_PLAN_MOVE_BUTTON_CLICK, {
        recommendedLessonId: lesson.id,
      })
    } else if (challengeTag === challengeLib.CHALLENGE_TAG.LEARN) {
      navigate(`${paths.LESSON}${lesson.id}`, {
        state: {
          from: paths.HOME_AUTHED,
        },
      })
      globalContext.analytics?.trackEvent(events.DASHBOARD_TODAYS_PLAN_LEARN_BUTTON_CLICK, {
        recommendedLessonId: lesson.id,
      })
    }
  }

  const label = (
    <Text color="purple" size="large" weight="bold">
      {challengeTag === challengeLib.CHALLENGE_TAG.LEARN || isChangeMoveTitle ? 'Learn' : 'Move'}
    </Text>
  )

  if (loading) {
    return <Loader overlay={false} />
  }

  if (_.isEmpty(lesson)) {
    return null
  }

  return (
    <Box
      color="white"
      className={classNames(
        'DashboardTodayLesson',
        styles.this,
        isChallengeCompleted && styles['this---completed'],
        className
      )}>
      {isChallengeCompleted ? (
        label
      ) : (
        <>
          <Image src={lesson?.videoThumbnail} alt="Class thumbnail" className={styles.image} />
          <Text color="black" element="div" className={styles.body}>
            <Row size="smallx">
              <Row size="smallx">{label}</Row>
              <Row size="xxsmall">
                <Text element="h3" heading size="xlarge" weight="bold" wrap="balance">
                  {lesson?.videoTitle}
                </Text>
              </Row>
              <Text size="large">{lesson?.instructorName}</Text>
            </Row>
            <footer className={styles.footer}>
              {_.isEmpty(liveLesson) ? (
                <Button onClick={handleStartLesson}>Start class</Button>
              ) : (
                <LiveLessonSignUpButton
                  color="purple"
                  data={liveLesson}
                  full={false}
                  statusScheduledChildren={
                    <Text weight="bold">
                      Starts at <DateTime data={liveLesson?.scheduledStartTs} format="time" />
                    </Text>
                  }>
                  RSVP
                </LiveLessonSignUpButton>
              )}
            </footer>
          </Text>
        </>
      )}
    </Box>
  )
}
