import { useLocation } from '@reach/router'
import classNames from 'classnames'
import { navigate } from 'gatsby'
import React from 'react'
import Link from 'components/Link'
import Row from 'components/Row'
import Text from 'components/Text'
import TextWithIcon from 'components/TextWithIcon'
import paths from 'constants/paths'
import type { Lesson } from 'libs/lesson'
import styles from './ExerciseCard.module.scss'
import { ReactComponent as CheckmarkSvg } from './images/checkmark.svg'
import { ReactComponent as LockSvg } from './images/lock.svg'

type Props = {
  data: Lesson
  locked?: boolean
}

export default function ExerciseCard({ data, locked }: Props) {
  const { id, instructorName, status, videoThumbnail, videoTitle } = data
  const location = useLocation()

  function handleClick() {
    if (locked) return

    navigate(`${paths.LESSON}${id}`, {
      state: {
        from: location.pathname,
      },
    })
  }

  const Component = locked ? 'div' : Link

  return (
    <Component
      className={classNames('ExerciseCard', styles.this, locked && styles['this---locked'])}
      onClick={handleClick}>
      <div
        style={{
          backgroundImage: `url('${videoThumbnail.replaceAll(' ', '%20')}')`,
        }}
        className={styles.image}
      />
      <div className={styles.content}>
        {status === 'completed' && (
          <Row size="xxsmall">
            <TextWithIcon
              icon={<CheckmarkSvg />}
              color="white"
              inline
              size="xsmall"
              weight="bold"
              className={styles.tag}>
              Watched
            </TextWithIcon>
          </Row>
        )}
        <Text casing="upper" color="white" element="div">
          <Row size="xxsmall">
            <Text element="h3" size="large" weight="bold">
              {videoTitle}
            </Text>
          </Row>
          <Text element="div" size="small" weight="bold">
            {instructorName}
          </Text>
        </Text>
      </div>
      {locked && <LockSvg aria-label="Locked" className={styles.lock} />}
    </Component>
  )
}
