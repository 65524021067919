import _ from 'lodash'
import React from 'react'
import * as challengeLib from 'libs/challenge'
import type { UserChallenge } from 'libs/challenge'
import type { Lesson, LessonStream } from 'libs/lesson'
import { useLiveLessons } from 'libs/live-lessons-query'
import * as programLib from 'libs/program'
import {
  useLessonsForProgramDynamic,
  useLessonsForProgramStatic,
  useProgram,
} from 'libs/program-query'
import DashboardTodayLesson from '../DashboardTodayLesson'

type Props = {
  challenge: UserChallenge
  // eslint-disable-next-line no-unused-vars
  onMoveLessonChange: (lesson: Lesson) => void
}

export default function DashboardTodayLessonMove({ challenge, onMoveLessonChange }: Props) {
  const { data: programData } = useProgram()
  const program = programData?.data
  const { data: lessonsForProgramStaticData, isLoading: isLoadingStatic } =
    useLessonsForProgramStatic(program?.exerciseProgram?.id, {
      dependencies: [programData],
    })
  const { data: lessonsForProgramDynamicData, isLoading: isLoadingDynamic } =
    useLessonsForProgramDynamic(program?.id, {
      dependencies: [programData],
    })
  const lessonsForPrograms =
    program?.programType === programLib.TYPE_STATIC
      ? lessonsForProgramStaticData?.data
      : lessonsForProgramDynamicData?.data

  const { data: liveLessons, isLoading: isLoadingLiveLessons } = useLiveLessons({
    revalidateOnFocus: false,
  })
  const upcomingLiveLessons = liveLessons?.data

  const [lesson, setLesson] = React.useState<Lesson | {}>({})
  const [liveLessonStream, setLiveLessonStream] = React.useState<LessonStream>()
  const [isLoading, setIsLoading] = React.useState<boolean>(false)

  React.useEffect(() => {
    setIsLoading(isLoadingStatic || isLoadingDynamic || isLoadingLiveLessons)

    if (_.isEmpty(upcomingLiveLessons)) {
      setLesson(programLib.getLessonCurrent(lessonsForPrograms))
      setLiveLessonStream(undefined)
    } else {
      const lessonsScheduled = upcomingLiveLessons?.filter((item: LessonStream) => {
        if (!item.scheduledLessons?.id || item.scheduledLessons?.status === 'canceled') {
          return false
        }
        const startTime = new Date(item.scheduledStartTs)
        const now = new Date()
        const isSameDate = startTime.toDateString() === now.toDateString()
        const isClassNotOver =
          startTime.getTime() + item.lesson.duration * 60 * 1000 >= now.getTime()
        return isSameDate && isClassNotOver
      })

      if (_.isEmpty(lessonsScheduled)) {
        setLesson(programLib.getLessonCurrent(lessonsForPrograms))
        setLiveLessonStream(undefined)
      } else {
        const lessonScheduled = lessonsScheduled[0]
        setLiveLessonStream(lessonScheduled)
        setLesson(lessonScheduled?.lesson)
      }
    }
  }, [
    isLoadingDynamic,
    isLoadingLiveLessons,
    isLoadingStatic,
    lessonsForPrograms,
    upcomingLiveLessons,
  ])

  React.useEffect(() => {
    if (lesson) onMoveLessonChange(lesson)
  }, [lesson, onMoveLessonChange])

  if (_.isEmpty(lesson)) {
    return null
  }

  return (
    <DashboardTodayLesson
      challenge={challenge}
      challengeTag={challengeLib.CHALLENGE_TAG.MOVE}
      lesson={lesson}
      liveLesson={challenge?.complete ? undefined : liveLessonStream}
      loading={isLoading}
      className="DashboardTodayLessonMove"
    />
  )
}
