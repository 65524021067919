import React from 'react'
import Text from 'components/Text'
import styles from './AdminLessonRegimensDay.module.scss'
import AdminLessonRegimensItem from './components/AdminLessonRegimensItem'

type Props = {
  dayOfWeek: string
  onUpdate: () => void
  scheduleForDay: []
}

export default function AdminLessonRegimensDay({
  dayOfWeek,
  onUpdate: handleUpdate,
  scheduleForDay,
}: Props) {
  return (
    <div className={styles.box}>
      <Text element="h2" weight="bold" className={styles.name}>
        {dayOfWeek}
      </Text>
      {scheduleForDay.map((lessonRegimen, index) => (
        <AdminLessonRegimensItem
          key={`${lessonRegimen.id}-${index}`}
          lessonRegimen={lessonRegimen}
          onUpdate={handleUpdate}
          dayOfWeek={dayOfWeek}
        />
      ))}
    </div>
  )
}
