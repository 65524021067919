import classNames from 'classnames'
import { navigate } from 'gatsby'
import _ from 'lodash'
import React from 'react'
import { CircleProgress } from 'react-gradient-progress'
import TextLoop from 'react-text-loop'
import Button from 'components/Button'
import Row from 'components/Row'
import Text from 'components/Text'
import Title3 from 'components/Title3'
import View from 'components/View'
import paths from 'constants/paths'
import statusCodes from 'constants/status-codes'
import type { Component } from 'constants/types'
import { useGlobalContext } from 'contexts/GlobalContext'
import * as logging from 'libs/logging'
import * as notifications from 'libs/notifications'
import { useProgram } from 'libs/program-query'
import * as url from 'libs/url'
import * as user from 'libs/user'
import * as userApiRequest from 'libs/user-api-request'
import constants from 'styles/constants.module.scss'
import styles from './ProgramBuild.module.scss'

type Props = {
  onComplete?: () => void
} & Component

export default function ProgramBuild({ className, onComplete }: Props) {
  const globalContext = useGlobalContext()
  const { data: programData, mutate: mutateProgram } = useProgram()
  const [copyArray, setCopyArray] = React.useState([
    'Loading preferences',
    'Loading background',
    'Loading movements',
  ])
  const [hasInitialized, setHasInitialized] = React.useState(false)
  const [hasProgramError, setHasProgramError] = React.useState(false)
  const [isProgramBuildDone, setIsProgramBuildDone] = React.useState(false)
  const [percentage, setPercentage] = React.useState(0)

  React.useEffect(() => {
    async function getPersonalizationInformation() {
      const generatedResponse = await userApiRequest.getLatestUserProfile()
      if (generatedResponse.statusCode !== 200 || !generatedResponse?.data) return

      const generatedConditions = generatedResponse?.data?.latestProfile?.conditions

      if (!_.isEmpty(generatedConditions)) {
        setCopyArray([
          'Loading preferences',
          'Loading background',
          `Adjusting for ${generatedConditions[0]}`,
        ])
      }
    }
    getPersonalizationInformation()
  }, [])

  const complete = React.useCallback(() => {
    if (onComplete) {
      onComplete()
    } else {
      navigate(paths.PROGRAM, { replace: true })
    }
  }, [onComplete])

  React.useEffect(() => {
    async function updateProgram() {
      const { changeRequested } = url.getQueryString()

      logging.toDatadog('Program build', {
        changeRequested,
        hasInitialized,
        programData,
      })

      if (!hasInitialized && (changeRequested === 'true' || !programData?.data)) {
        setHasInitialized(true) // prevent re-calling
        const response = await userApiRequest.setUserExerciseProgram() // checks if currently has program
        if (response.statusCode === statusCodes.NO_OP) {
          notifications.notifyError(
            'Oops, your answers matched you to your current program. Please select different answers if you’d like to change your program.'
          )
          complete()
        }

        globalContext.updateUser()
        await mutateProgram()
        setIsProgramBuildDone(true)
      }
    }
    updateProgram()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasInitialized, programData])

  React.useEffect(() => {
    setTimeout(() => {
      setPercentage(10)

      setTimeout(() => {
        setPercentage(30)

        setTimeout(() => {
          setPercentage(60)

          setTimeout(() => {
            setPercentage(90)

            setTimeout(() => {
              setPercentage(100)
            }, 600)
          }, 800)
        }, 1200)
      }, 800)
    }, 400)
  }, [])

  React.useEffect(() => {
    if (percentage !== 100 || !isProgramBuildDone) return

    if (programData.statusCode === statusCodes.SUCCESS) {
      complete()
    } else {
      setHasProgramError(true)
      globalContext.analytics?.trackEvent('Program build: Failed', {
        statusCode: programData.statusCode,
        error: programData.message,
      })
    }
  }, [complete, globalContext.analytics, isProgramBuildDone, percentage, programData])

  function handleClick() {
    globalContext.analytics?.trackEvent('Program build: Go to Dashboard: Click')
    navigate(paths.HOME_AUTHED, { replace: true })
  }

  return (
    <View className={classNames('ProgramBuild', styles.this, className)}>
      {hasProgramError ? (
        <Title3
          actions={
            user.hasDashboard(globalContext.user) ? (
              <Button onClick={handleClick}>Go to Dashboard</Button>
            ) : null
          }
          flush
          subtext="Sorry, something went wrong attempting to build your program. Please try again later."
        />
      ) : (
        <div>
          <Row size="smallx">
            <div className={styles.progress}>
              <div className={styles['progress--circle']}>
                <CircleProgress
                  percentage={percentage}
                  primaryColor={[constants.PURPLE, constants.PURPLE]}
                  secondaryColor="#f5f5f5"
                  strokeWidth={14}
                  width={138}
                />
              </div>
            </div>
          </Row>
          <TextLoop interval={1600} className={styles.words}>
            {copyArray.map((item) => (
              <Text align="center" key={item} className={styles['words--item']}>
                {item}
              </Text>
            ))}
          </TextLoop>
        </div>
      )}
    </View>
  )
}
