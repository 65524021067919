import classNames from 'classnames'
import React from 'react'
import Row from 'components/Row'
import Text from 'components/Text'
import type { Component } from 'constants/types'
import type { Lesson } from 'libs/lesson'
import ProgramPostLessonFeedbackForm from '../ProgramPostLessonFeedbackForm'

type Props = {
  data: Lesson
} & Component

export default function ProgramPostLessonFeedback({ className, data }: Props) {
  const [isFeedbackSubmitted, setIsFeedbackSubmitted] = React.useState(false)

  return (
    <div className={classNames('ProgramPostLessonFeedback', className)}>
      {isFeedbackSubmitted ? (
        <>
          <Row size="xxxsmall">
            <Text element="h3" weight="bold">
              Feedback submitted
            </Text>
          </Row>
          <Text element="p" flush>
            Thank you for your feedback!
          </Text>
        </>
      ) : (
        <ProgramPostLessonFeedbackForm
          lesson={data}
          size="small"
          onSuccess={() => setIsFeedbackSubmitted(true)}
        />
      )}
    </div>
  )
}
