import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import Container from 'components/Container'
import ModalBox from 'components/ModalBox'
import * as events from 'constants/events'
import type { Component } from 'constants/types'
import { useGlobalContext } from 'contexts/GlobalContext'
import CheckinForm from 'features/checkin/components/CheckinForm'
import * as checkinApiRequest from 'libs/checkin-api-request'
import * as notifications from 'libs/notifications'
import CheckinTracker from 'libs/checkins/checkin-tracker'

type Props = {
  checkinTracker: {}
  onSuccess: () => void
} & Component

export default function ProgramPostLessonFeedback({
  checkinTracker: propsCheckinTracker,
  className,
  onSuccess,
}: Props) {
  const globalContext = useGlobalContext()
  const [checkinTracker, setCheckinTracker] = React.useState(propsCheckinTracker)

  const lessonId = globalContext.lesson?.id
  const lessonTitle = globalContext.lesson?.videoTitle

  React.useEffect(() => {
    globalContext.analytics?.trackEvent(events.START_FEEDBACK, {
      classId: lessonId,
      isExplore: window.location?.hash === '#explore',
      lessonName: lessonTitle,
    })
  }, [globalContext.analytics, lessonId, lessonTitle])

  React.useEffect(() => {
    if (!_.isEmpty(propsCheckinTracker)) return

    async function getCheckin() {
      const afterClassCheckinResponse = await checkinApiRequest
        .getUserCheckin({
          type: 'after_class',
          activeOnly: true,
          createIfNotExists: true,
          objectId: lessonId,
        })
        .catch((error: any) => {
          globalContext.analytics?.trackEvent(events.FAILED_CHECKIN_CALL, {
            error,
            from: 'post lesson feedback',
            type: 'after_class',
          })
          notifications.notifyError()
        })

      const afterClassCheckin = afterClassCheckinResponse.data

      if (!afterClassCheckin) return

      setCheckinTracker(
        new CheckinTracker(globalContext.user?.id, afterClassCheckin, globalContext.analytics)
      )
    }
    getCheckin()
  }, [globalContext.user?.id, lessonId, propsCheckinTracker, globalContext.analytics])

  function handleSuccess(answers) {
    notifications.notifySuccess('Thanks for your feedback!')

    const isExplore = window.location?.hash === '#explore'

    globalContext.analytics?.trackEvent(events.COMPLETE_FEEDBACK, {
      answers: JSON.stringify(answers),
      classId: lessonId,
      isExplore,
      lessonName: globalContext.lesson?.videoTitle,
    })

    onSuccess() // to close modal
  }

  return (
    <ModalBox
      title="Reflect on your class"
      className={classNames('ProgramPostLessonFeedback', className)}>
      <Container flush size="small">
        <CheckinForm
          buttonProps={{
            children: 'Send feedback',
            full: true,
          }}
          checkinTracker={checkinTracker}
          size="small"
          onSuccess={handleSuccess}
        />
      </Container>
    </ModalBox>
  )
}
