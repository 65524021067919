import React from 'react'
import FacebookAuthButton from 'components/FacebookAuthButton'
import Home from 'components/landing/Home'
import * as events from 'constants/events'

type Props = {}

export default function HomeSpecial(props: Props) {
  const showFacebook = false // rdd.browserName !== 'Chrome WebView'

  return (
    <Home
      heroProps={props}
      howProps={{
        authButton: showFacebook ? <FacebookAuthButton type={events.SIGN_UP} /> : undefined,
      }}
      // TODO
      landingHeroVariant
      className="HomeSpecial"
    />
  )
}
