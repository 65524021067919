import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import Button from 'components/Button'
import Image from 'components/Image'
import Text from 'components/Text'
import TextWithIcon from 'components/TextWithIcon'
import type { Component } from 'constants/types'
import styles from './ButtonFilter.module.scss'
import { ReactComponent as ChevronDownSvg } from './images/chevron---down.svg'
import { ReactComponent as ChevronUpSvg } from './images/chevron---up.svg'
import { ReactComponent as XSvg } from './images/x.svg'

type Option = {
  hide?: boolean
  icon?: React.ReactNode
  label: string
  value: string
}

type Props = {
  children: React.ReactNode
  onSelect: () => void
  options: Option[]
  shouldClose?: boolean
  value?: [] | string
} & Component

export default function ButtonFilter({
  children,
  className,
  onClear,
  onClick,
  onSelect,
  options,
  shouldClose,
  value: propsValue,
  // destructuring because react-media can pass as a prop that causes a noisey console warning
  matches,
  ...props
}: Props) {
  const [isOpen, setIsOpen] = React.useState(false)

  const getOption = React.useCallback(
    (newValue: [] | string) => {
      if (_.isEmpty(newValue)) return {}

      return options.find((item: Option) => _.isEqual(item.value, propsValue))
    },
    [options, propsValue]
  )

  const [value, setValue] = React.useState<Option | {}>(getOption(propsValue))

  React.useEffect(() => {
    if (!shouldClose) return
    setIsOpen(false)
  }, [shouldClose])

  React.useEffect(() => {
    if (_.isEmpty(propsValue)) return

    setValue(getOption(propsValue))
  }, [getOption, propsValue])

  const hasValue = !_.isEmpty(value)

  React.useEffect(() => {
    //if (hasValue) return
    setIsOpen(false)
  }, [value])

  function handleButtonClick() {
    if (hasValue) return
    setIsOpen(!isOpen)
    if (onClick) onClick()
  }

  function handleClearButtonClick(event) {
    event.stopPropagation()
    setValue({})
    onClear()
  }

  function handleOptionButtonClick(option: Option) {
    setValue(option)
    onSelect(option.value)
  }

  const optionsFiltered = options //.filter((item: Option) => !item.hide)

  return (
    <div {...props} className={classNames('ButtonFilter', styles.this, className)}>
      <Button
        color="white"
        disabled={!hasValue && _.size(optionsFiltered) <= 1}
        faux={hasValue}
        pill
        size="xsmall"
        className={classNames(styles.button, hasValue && styles['button---faux'])}
        onClick={handleButtonClick}>
        <TextWithIcon
          icon={
            !_.isEmpty(value) && (
              <button
                type="button"
                aria-label="Clear"
                className={styles['clear-button']}
                onClick={handleClearButtonClick}>
                <XSvg aria-hidden />
              </button>
            )
          }
          center
          gutterSize="xsmall">
          {value?.label || (
            <TextWithIcon
              icon={
                isOpen ? (
                  <ChevronUpSvg className={styles['chevron-icon']} />
                ) : (
                  <ChevronDownSvg className={styles['chevron-icon']} />
                )
              }
              iconPosition="right">
              {children}
            </TextWithIcon>
          )}
        </TextWithIcon>
      </Button>
      {isOpen && (
        <Text
          element="ul"
          className={classNames(
            styles.dropdown,
            _.size(optionsFiltered) > 5 && styles['dropdown---grid']
          )}>
          {optionsFiltered.map((item: Option) => (
            <li key={item.label} className={styles['dropdown--item']}>
              <TextWithIcon
                icon={<Image src={item.icon} className={styles['dropdown--item--icon']} />}
                align={item.icon ? 'left' : undefined}
                element="button"
                gutterSize="xsmall"
                type="button"
                weight="bold"
                //wrap={!item.icon}
                wrap={false}
                className={styles['dropdown--item--button']}
                onClick={() => handleOptionButtonClick(item)}>
                {item.label}
              </TextWithIcon>
            </li>
          ))}
        </Text>
      )}
    </div>
  )
}
