import type { PageProps } from 'gatsby'
import React from 'react'
import HomeSpecial from 'components/landing/HomeSpecial'
import HomeSpecialLayout from 'components/landing/HomeSpecialLayout'

type Props = PageProps

export default function HomeSpecialNewYear(props: Props) {
  return (
    <HomeSpecialLayout {...props} className="HomeSpecialNewYear">
      <HomeSpecial
        title="This year, move on from pain"
        subtext="It's YOUR year — improve your balance and strength in 2025 with Bold!"
      />
    </HomeSpecialLayout>
  )
}
