import type { PageProps } from 'gatsby'
import React from 'react'
import HomeSpecial from 'components/landing/HomeSpecial'
import HomeSpecialLayout from 'components/landing/HomeSpecialLayout'

type Props = PageProps

export default function HomeSpecialChairYoga(props: Props) {
  return (
    <HomeSpecialLayout {...props} className="HomeSpecialChairYoga">
      <HomeSpecial
        title="Free* chair yoga classes"
        subtext="Get access to hundreds of yoga, balance, flexibility and Tai Chi classes for free.*"
        disclaimer="*Free if covered by your insurance"
      />
    </HomeSpecialLayout>
  )
}
