import _ from 'lodash'
import React from 'react'
import Container from 'components/Container'
import Layout from 'components/Layout'
import Row from 'components/Row'
import Text from 'components/Text'
import Video from 'components/Video'
import View from 'components/View'
import useEventPageVisited from 'hooks/use-event-page-visited'

function FreeLessonsGroup({ lessons, title }) {
  return (
    <>
      <Row size="small">
        <Text element="h2" weight="bold" size="xxlarge">
          {title}
        </Text>
      </Row>
      {lessons.map((item) => (
        <Row key={item.title} size="large">
          <Row size="small">
            <Container align="left" flush>
              <Row size="xsmall">
                <Text element="h3" size="xlarge" weight="bold">
                  {item.title}
                </Text>
              </Row>
              <Row size="small">{item.trainer}</Row>
              <Text element="p">{item.description}</Text>
              {!_.isEmpty(item.accessories) && (
                <Text element="p">
                  To do the workout, you will need: {item.accessories.join(', ')}
                </Text>
              )}
            </Container>
          </Row>
          <Video url={item.url} />
        </Row>
      ))}
    </>
  )
}

export default function FreeLessons() {
  useEventPageVisited('Free lessons')

  const lessonsStanding = [
    {
      title: 'Power up flow',
      trainer: 'Alison Burmeister',
      duration: 15,
      description:
        'Get a Bold boost from this energizing class that easily fits into any schedule.',
      accessories: ['Chair (optional)'],
      url: 'https://youtu.be/l2cdc7lC2bE',
    },
    {
      title: 'Cardio with Jojo',
      trainer: 'Jojo Gorchoff',
      duration: 24,
      description:
        'In this class, Jojo leads you through a moderate cardio exercises so get ready to get moving!',
      accessories: [],
      url: 'https://youtu.be/A84YxeBdzLQ',
    },
    {
      title: 'Strength with Alicia',
      trainer: 'Alicia Estrada',
      duration: 45,
      description:
        'Join Alicia for a moderate intensity strength training session. Utilize dumbbells or simply body weight. Modifications are offered, so all fitness levels are welcome.',
      accessories: ['Chair (optional)', 'Hand weights (optional)'],
      url: 'https://youtu.be/B3nWrbWLG3o',
    },
  ]

  const lessonsSeated = [
    {
      title: 'Quick chair class with Jill',
      trainer: 'Jill Penfold',
      duration: 19,
      description:
        'All seated class with quick, repeating circuits to move and engage your whole body.',
      accessories: ['Chair'],
      url: 'https://youtu.be/M1S9XFGFDKs',
    },
    {
      title: 'Cardio challenge',
      trainer: 'Vanessa Wilkins',
      duration: 26,
      description:
        'Challenge yourself in this quick, full-body class that targets your core and stability.',
      accessories: ['Chair'],
      url: 'https://youtu.be/pRRX5uowf0c',
    },
    {
      title: 'Seated tai chi',
      trainer: 'Amanda Rees',
      duration: 34,
      description:
        'Tai Chi in this seated class can build balance, increase body awareness and promote relaxation.',
      accessories: ['Chair'],
      url: 'https://youtu.be/b-ZrjhM2cRw',
    },
  ]

  return (
    <Layout className="Vimeo">
      <View>
        <Container flush size="xlarge">
          <Row>
            <Text align="center" element="h1" size="xxxlarge" weight="bold">
              Try these classes
            </Text>
          </Row>
          <Row size="xlarge">
            <FreeLessonsGroup title="Standing classes" lessons={lessonsStanding} />
          </Row>
          <FreeLessonsGroup title="Seated classes" lessons={lessonsSeated} />
        </Container>
      </View>
    </Layout>
  )
}
