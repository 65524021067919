import classNames from 'classnames'
import { graphql } from 'gatsby'
import React from 'react'
import Container from 'components/Container'
import Head from 'components/Head'
import Layout from 'components/Layout'
import Link from 'components/Link'
import Text from 'components/Text'
import Title2 from 'components/Title2'
import NavHeader from 'features/blog/components/NavHeader'
import Posts2 from 'features/blog/components/Posts2'
import useEventPageVisited from 'hooks/use-event-page-visited'
import styles from './BlogTaxonomy.module.scss'

type Props = {
  data: unknown
  pageContext: {
    id: string
  }
}

export default function BlogTaxonomy({ data, pageContext }: Props) {
  const { postFeatured, postsSecondary, postsTertiary } = data

  const posts = [...postFeatured.edges, ...postsSecondary.edges, ...postsTertiary.edges]

  useEventPageVisited('Blog taxonomy', { title: pageContext.id })

  let title = 'Exercise, health and wellness tips'
  let description =
    'The latest posts and updates from Bold, your home for personalized, science-backed workouts you can do at home. Read on for exercise, health and wellness tips.'

  if (pageContext.id === 'exercise') {
    title = 'Exercise tips, workout plans and more for older adults'
    description =
      'The latest posts and updates from Bold, your home for personalized, science-backed workouts you can do at home. Read on for exercise, health and wellness tips.'
  }

  if (pageContext.id === 'lifestyle') {
    title = 'Blog posts by Bold – Personalized, science-backed workouts'
    description =
      'Learn exercise tips, ideas and more for older adults on the Bold Report. Bold is your home for personalized, science-backed workouts shown to reduce fall risk.'
  }

  return (
    <Layout className={classNames('BlogTaxonomy', styles[`this---${pageContext.id}`])}>
      <Head title={title} titleAppend="The Bold Report" description={description} />
      <NavHeader active={pageContext.id} />
      <div className={styles.content}>
        <Container size="xxlarge" className={styles['top--container']}>
          <div className={styles['top--title']}>
            <div className={styles['top--title--inner']}>
              <Title2 flush size="large" small="The latest in">
                {pageContext.id}
              </Title2>
            </div>
            <Text element="figcaption" className={styles['post-featured--caption']}>
              <Text element="h3" weight="bold" className={styles['post-featured--title']}>
                {postFeatured.edges[0].node.frontmatter.title}
              </Text>
              <Text element="p">{postFeatured.edges[0].node.excerpt}</Text>
              <Link to={postFeatured.edges[0].node.fields.slug} alt>
                Read on
              </Link>
            </Text>
          </div>
        </Container>
        <Posts2
          data={posts}
          //postFeatured={postFeatured.edges}
          //postsSecondary={postsSecondary.edges}
          //postsTertiary={postsTertiary.edges}
        />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogTaxonomy($id: String) {
    postFeatured: allMdx(
      limit: 1
      filter: { frontmatter: { category: { eq: $id }, hideFromLanding: { ne: true } } }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 72)
          fields {
            slug
          }
          frontmatter {
            image {
              childImageSharp {
                fluid(maxWidth: 924, maxHeight: 536) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
              publicURL
            }
            title
          }
        }
      }
    }
    postsSecondary: allMdx(
      limit: 3
      skip: 1
      filter: { frontmatter: { category: { eq: $id }, hideFromLanding: { ne: true } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            image {
              childImageSharp {
                fluid(maxWidth: 924, maxHeight: 536) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            title
          }
        }
      }
    }
    postsTertiary: allMdx(
      skip: 4
      filter: { frontmatter: { category: { eq: $id }, hideFromLanding: { ne: true } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            image {
              childImageSharp {
                fluid(maxWidth: 924, maxHeight: 536) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            title
          }
        }
      }
    }
  }
`
