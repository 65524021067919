import type { PageProps } from 'gatsby'
import React from 'react'
import HomeSpecial from 'components/landing/HomeSpecial'
import HomeSpecialLayout from 'components/landing/HomeSpecialLayout'

type Props = PageProps

export default function HomeSpecialPelvicHealth(props: Props) {
  return (
    <HomeSpecialLayout {...props} className="HomeSpecialPelvicHealth">
      <HomeSpecial
        title="Manage incontinence and pelvic pain with free* exercises"
        subtext="Join Bold’s pelvic health program, built by clinical experts to help with common symptoms."
        disclaimer="*Free if covered by your insurance"
      />
    </HomeSpecialLayout>
  )
}
