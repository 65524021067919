import type { PageProps } from 'gatsby'
import React from 'react'
import HomeSpecial from 'components/landing/HomeSpecial'
import HomeSpecialLayout from 'components/landing/HomeSpecialLayout'

type Props = PageProps

export default function HomeSpecialStrength(props: Props) {
  return (
    <HomeSpecialLayout {...props} className="HomeSpecialStrength">
      <HomeSpecial
        title="Get strong for free*"
        subtext="Strength training is essential for healthy aging, and Bold has something for every ability level. Get started today."
        disclaimer="*Free if covered by your insurance"
      />
    </HomeSpecialLayout>
  )
}
