import classNames from 'classnames'
import React from 'react'
import Container from 'components/Container'
import Head from 'components/Head'
import Layout from 'components/Layout'
import Text from 'components/Text'
import type { Component } from 'constants/types'
import styles from './Page2.module.scss'

// TODO
// merge with Page

type Props = {
  children: React.ReactNode
  headline?: string
  image?: string
  title?: string
} & Component

export default function Page2({ children, className, headline, image, title }: Props) {
  return (
    <Layout className={classNames('Page2', className)}>
      <Head title={headline || title} />
      {image && (
        <div className={styles.inner}>
          <header style={{ backgroundImage: `url(${image})` }} className={styles['content-header']}>
            <Container size="xlarge">
              <Text color="white" element="h1" weight="bold" className={styles.title}>
                {headline || title}
              </Text>
            </Container>
          </header>
        </div>
      )}
      <div className={styles.content}>
        <Container size="xlarge">
          {!image && <h1>{headline || title}</h1>}
          {children}
        </Container>
      </div>
    </Layout>
  )
}
