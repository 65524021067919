import classNames from 'classnames'
import { navigate } from 'gatsby'
import React from 'react'
import Button from 'components/Button'
import Container from 'components/Container'
import Row from 'components/Row'
import Text from 'components/Text'
import View from 'components/View'
import * as events from 'constants/events'
import paths from 'constants/paths'
import { useGlobalContext } from 'contexts/GlobalContext'
import OnboardingCheckinSingleForm from 'features/onboarding/components/OnboardingCheckinSingleForm'
import * as checkinApiRequest from 'libs/checkin-api-request'
import * as notifications from 'libs/notifications'
import CheckinTracker from 'libs/checkins/checkin-tracker'
import styles from './StandaloneCheckin.module.scss'

export default function StandaloneCheckin() {
  const [checkinTracker, setCheckinTracker] = React.useState<CheckinTracker>(null)
  const [hasCheckin, setHasCheckin] = React.useState(false)
  const globalContext = useGlobalContext()

  React.useEffect(() => {
    async function getCheckin() {
      const checkinResponse = await checkinApiRequest
        .getUserCheckin({
          type: 'standalone_checkin',
          activeOnly: true,
          createIfNotExists: false,
        })
        .catch((error: any) => {
          globalContext.analytics?.trackEvent(events.FAILED_CHECKIN_CALL, {
            error,
            from: 'standalone_checkin',
            type: 'standalone_checkin',
          })
        })
      if (checkinResponse.data) {
        setCheckinTracker(
          new CheckinTracker(globalContext.user.id, checkinResponse.data, globalContext.analytics)
        )
        setHasCheckin(true)
      }
    }
    getCheckin()
  }, [globalContext.analytics, globalContext.user.id])

  function handleSuccess() {
    try {
      notifications.notifySuccess('Successfully completed')
      navigate(paths.HOME_AUTHED, { replace: true })
    } catch {
      notifications.notifyError(
        'Something went wrong submitting your responses. Please try again later.'
      )
    }
  }

  function handleError() {
    notifications.notifyError(
      'Something went wrong submitting your responses. Please try again later.'
    )
  }

  let content = null
  content = !hasCheckin ? (
    <View>
      <Container flush size="small">
        <Row>
          <Text>You're all set! Nothing to check in on 😊</Text>
        </Row>
        <Button size="small" onClick={() => navigate(paths.HOME_AUTHED)}>
          Go back to your homepage
        </Button>
      </Container>
    </View>
  ) : (
    <View className={styles.main}>
      <Container flush size="small">
        <OnboardingCheckinSingleForm
          checkinTracker={checkinTracker}
          onSuccess={handleSuccess}
          onError={handleError}
        />
      </Container>
    </View>
  )

  return (
    <>
      <div className={classNames('StandaloneCheckin', styles.this)}>{content}</div>
    </>
  )
}
