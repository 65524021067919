import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import Button from 'components/Button'
import Field from 'components/Field'
import Input from 'components/Input'
import Label from 'components/Label'
import Text from 'components/Text'
//import storage from 'constants/storage'
import type { Component } from 'constants/types'
import { useGlobalContext } from 'contexts/GlobalContext'
//import * as cookie from 'libs/cookie'
import * as notifications from 'libs/notifications'
import * as subscriptions from 'libs/subscriptions'
import * as url from 'libs/url'
import * as user from 'libs/user'
import styles from './PromoCode.module.scss'
import { ReactComponent as XSvg } from './images/x.svg'

type Props = Component

export default function PromoCode({ className, ...props }: Props) {
  const globalContext = useGlobalContext()
  const [promoCode, setPromoCode] = React.useState('')
  const [promoCodeCoupon, setPromoCodeCoupon] = React.useState('')

  React.useEffect(
    () => () => {
      globalContext.update({ coupon: {} })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  React.useEffect(() => {
    const { coupon } = url.getQueryString()

    if (coupon) {
      setPromoCode(coupon)
      return
    }

    if (user.isTrialUser(globalContext.user)) {
      setPromoCode(subscriptions.PROMO_CODES._2020)
    }
  }, [globalContext.user])

  const planCouponsEligible = globalContext.selectedPlan?.eligible_coupons

  React.useEffect(() => {
    if (!promoCode) {
      return
    }

    const coupon = planCouponsEligible?.find((item) => item.id === promoCode)

    if (!coupon) return

    if (coupon.max_redemptions && coupon.max_redemptions < coupon.times_redeemed + 1) {
      notifications.notifyError('Invalid promo code')
      return
    }

    setPromoCodeCoupon(coupon.name)
    globalContext.update({ coupon })
    //cookie.createCookie(storage.PROMO_CODE_KEY, coupon.name)
    //cookie.createCookie(storage.PROMO_CODE_COUPON_KEY, JSON.stringify(coupon))

    //notifications.notifySuccess('Successfully applied offer')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planCouponsEligible, promoCode])

  function handleClearButtonClick() {
    setPromoCode('')
    setPromoCodeCoupon('')
    globalContext.update({ coupon: {} })
  }

  function handleChange(event) {
    setPromoCode(event.target.value)
  }

  if (_.isEmpty(planCouponsEligible)) return null

  return (
    <Field
      {...props}
      note={
        !promoCodeCoupon ? undefined : (
          <>
            <Text weight="bold">{promoCodeCoupon}</Text> offer applied
          </>
        )
      }
      className={classNames('PromoCode', styles.this, className)}>
      <Label>Promo Code</Label>
      <div className={styles['input-and-button']}>
        <Input disabled={promoCodeCoupon} value={promoCode} onChange={handleChange} />
        {promoCodeCoupon && (
          <Button
            level="text"
            aria-label="Remove"
            className={styles['clear-button']}
            onClick={handleClearButtonClick}>
            <XSvg aria-hidden />
          </Button>
        )}
      </div>
    </Field>
  )
}
