import classNames from 'classnames'
import React from 'react'
import Text, { type TextProps } from 'components/Text'
import styles from './Icon2.module.scss'

type Props = {
  background?: 'blue' | 'cyan' | 'purple' | 'white'
} & TextProps

export default function Icon2({
  background = 'white',
  className,
  color: propsColor,
  ...props
}: Props) {
  let color = propsColor ?? 'black'

  if (background === 'blue' || background === 'purple' || background === 'cyan') {
    color = 'white'
  }

  return (
    <Text
      {...props}
      color={color}
      element="div"
      aria-hidden
      className={classNames('Icon2', styles.this, styles[`this---${background}`], className)}
    />
  )
}
