import Tippy from '@tippyjs/react'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import Text from 'components/Text'
import type { Component } from 'constants/types'
import { useActiveChallenges, useUserChallenges } from 'libs/challenge-query'
import styles from './ChallengeProgress.module.scss'
import { ReactComponent as MedalSvg } from './images/medal.svg'

type Props = Component

// For the november challenge
export default function ChallengeProgress({ className }: Props) {
  const [novemberChallengeId, setNovemberChallengeId] = React.useState(null)
  const { data: activeChallenges } = useActiveChallenges({}, { revalidateOnFocus: false })
  const { data: userProgramChallengesData } = useUserChallenges(
    { challenge_id: novemberChallengeId },
    {
      dependencies: [activeChallenges],
      revalidateOnFocus: false,
    }
  )

  React.useEffect(() => {
    if (Array.isArray(activeChallenges?.data)) {
      const novemberChallenge = activeChallenges?.data?.find(
        (challenge) => challenge.name === 'November Challenge'
      )
      setNovemberChallengeId(novemberChallenge?.id || null)
    }
  }, [activeChallenges])

  const progress = userProgramChallengesData?.data[0]?.progress || 0 // defaulting to 0 if no userProgramChallengesData found
  const target = userProgramChallengesData?.data[0]?.target || 4

  const programChallengePercentComplete = (progress / target) * 100

  const progressIndexMarkers: number[] = []
  for (let index = 0; index < target; index++) {
    progressIndexMarkers.push(index)
  }

  return (
    <div className={classNames('ChallengeProgress', styles.this, className)}>
      <div className={styles.bar}>
        <div
          style={{
            transform: `translateX(-${100 - (programChallengePercentComplete ?? 0)}%)`,
          }}
          className={styles['bar--fill']}
        />
      </div>
      <Text weight="bold" className={styles.markers}>
        {progressIndexMarkers.map((index) => {
          const isLast = index === _.size(progressIndexMarkers) - 1
          return (
            <Tippy key={index} content={`Class ${index + 1}`}>
              <div
                className={classNames(
                  styles.marker,
                  index <= progress - 1 && styles['marker---complete'],
                  index === progress - 1 && styles['marker---current'],
                  isLast && styles['marker---medal']
                )}>
                {isLast && <MedalSvg className={styles.medal} />}
              </div>
            </Tippy>
          )
        })}
      </Text>
    </div>
  )
}
