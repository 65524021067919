import { Form, Formik, FormikProps } from 'formik'
import { navigate } from 'gatsby'
import React from 'react'
import * as Yup from 'yup'
import Box from 'components/Box'
import Button from 'components/Button'
import Buttons from 'components/Buttons'
import FieldPhoneNumber from 'components/FieldPhoneNumber'
import FieldSmsConsentCheckbox from 'components/FieldSmsConsentCheckbox'
import FieldValidation2 from 'components/FieldValidation2'
import MediaObject from 'components/MediaObject'
import Row from 'components/Row'
import Text from 'components/Text'
import paths from 'constants/paths'
import { useGlobalContext } from 'contexts/GlobalContext'
import * as notifications from 'libs/notifications'
import * as userApiRequest from 'libs/user-api-request'
import styles from './OnboardingPhoneNumberForm.module.scss'
import { ReactComponent as StarSvg } from './images/star.svg'

export default function OnboardingPhoneNumberForm() {
  const globalContext = useGlobalContext()
  const [hasConsented, setHasConsented] = React.useState(false)
  const [isUpdating, setIsUpdating] = React.useState(false)

  const handleCheck = (checked: boolean) => {
    setHasConsented(checked)
  }

  const handleSubmit = async (values) => {
    if (values.phoneNumber && !hasConsented) {
      return
    }

    try {
      setIsUpdating(true)

      if (values.phoneNumber) {
        await userApiRequest.updatePhoneNumber(values.phoneNumber)
      }

      await globalContext?.update({ phoneNumber: values.phoneNumber })
      await globalContext?.updateUser()

      navigate(paths.ONBOARDING_PROFILE)
    } catch (error) {
      console.error('Error updating phone number:', error)
      notifications.notifyError('Unable to update phone number. Please try again.')
    } finally {
      setIsUpdating(false)
    }
  }

  const handlePhoneNumberSubmit = (formikProps: FormikProps<FormValues>) => {
    if (formikProps.values.phoneNumber && !hasConsented) {
      formikProps.setFieldError('consent', 'Please check the box to continue, or skip')
    } else {
      formikProps.submitForm()
    }
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        consent: false,
        phoneNumber: globalContext?.user?.phoneNumber || '',
      }}
      validationSchema={Yup.object({
        consent: Yup.boolean(),
        phoneNumber: Yup.string().optional(),
      })}
      onSubmit={handleSubmit}>
      {(formikProps) => (
        <Form>
          <Row size="small">
            <Text element="h1" size="large" weight="bold">
              Let's help you stay on track
            </Text>
          </Row>
          <Row size="smallx">
            <Box color="pink" size="small">
              <MediaObject figure={<StarSvg aria-hidden />} size="xsmall">
                <Text element="p" flush>
                  Members who opt into texts are{' '}
                  <Text color="purple" weight="bold">
                    4 times
                  </Text>{' '}
                  more likely to stick to their new Bold exercise routine.
                </Text>
              </MediaObject>
            </Box>
          </Row>
          <FieldPhoneNumber />
          {formikProps.values.phoneNumber && (
            <Row size="small">
              <Box
                color="white"
                flush
                className={formikProps.errors.consent ? styles['consent--box---error'] : ''}>
                <FieldSmsConsentCheckbox
                  flush
                  shortenedText
                  className={styles['consent--field']}
                  onChange={handleCheck}
                />
              </Box>
              <FieldValidation2 />
            </Row>
          )}
          <Buttons axis="y">
            <Button
              disabled={isUpdating}
              full
              size="large"
              onClick={() => handlePhoneNumberSubmit(formikProps)}>
              Continue
            </Button>
            <Button
              to={paths.ONBOARDING_PROFILE}
              color="grey"
              disabled={isUpdating}
              full
              level="text">
              Skip
            </Button>
          </Buttons>
        </Form>
      )}
    </Formik>
  )
}
