import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import Container from 'components/Container'
import Copy from 'components/Copy'
import Heading2 from 'components/Heading2'
import Row from 'components/Row'
import Text from 'components/Text'
import type { Component } from 'constants/types'
import styles from './Hero2.module.scss'
import { ReactComponent as StripesSvg } from './images/stripes.svg'

type Props = {
  bottom: React.ReactNode
  button?: React.ReactNode
  image: React.ReactElement
  subtext: React.ReactNode
  title: string
} & Component

export default function Hero2({ bottom, button, className, image, subtext, title }: Props) {
  return (
    <>
      <header className={classNames('Hero2', styles.this, className)}>
        <Text color="white" element="div" className={styles.box}>
          <Container flush size="medium">
            <Heading2 level={1} weight="bold" className={styles.title}>
              {title}
            </Heading2>
            <Row flush={!button} size="small">
              <div className={styles.copy}>
                {_.isString(subtext) ? (
                  <Text element="p" flush>
                    {subtext}
                  </Text>
                ) : (
                  <Copy>{subtext}</Copy>
                )}
              </div>
            </Row>
            {button}
          </Container>
        </Text>
        {React.cloneElement(image, {
          className: styles.image,
        })}
      </header>
      <div className={styles['stripes-wrap']}>
        <StripesSvg aria-hidden className={styles.stripes} />
        <div>{bottom}</div>
      </div>
    </>
  )
}
