import classNames from 'classnames'
import React from 'react'
import Text, { type TextProps } from 'components/Text'
import styles from './Tag.module.scss'

type Props = {
  children: React.ReactNode
  color?:
    | 'yellow'
    | 'green'
    | 'blue'
    | 'purple'
    | 'purple500'
    | 'purple100'
    | 'pink'
    | 'grey'
    | 'white'
  level?: 'primary' | 'secondary'
} & Omit<TextProps, 'color'>

export default function Tag({
  casing = 'upper',
  children,
  className,
  color = 'purple100',
  level = 'primary',
  size = 'small',
  weight = 'bold',
  ...props
}: Props) {
  if (!children) return null

  let textColor: TextProps['color'] = level === 'secondary' ? color : 'white'

  if (color === 'grey') textColor = 'default'
  if (color === 'yellow') textColor = 'black'

  return (
    <Text
      {...props}
      align="center"
      casing={casing}
      color={textColor}
      size={size}
      weight={weight}
      className={classNames(
        'Tag',
        styles.this,
        color && styles[`this---${color}`],
        styles[`this---${level}`],
        styles[`this---${size}`],
        className
      )}>
      {children}
    </Text>
  )
}
