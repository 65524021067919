import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import Copy from 'components/Copy'
import Heading from 'components/Heading'
import MediaObject from 'components/MediaObject'
import Row from 'components/Row'
import Text from 'components/Text'
import type { Component } from 'constants/types'
import styles from './Title.module.scss'

type Props = {
  children: React.ReactNode
  cta?: React.ReactNode
  flush?: boolean
  image: React.ReactNode
  nav?: React.ReactNode
  size?: 'large' | 'medium' | 'small'
  small?: React.ReactNode
  subtext?: React.ReactNode
} & Component

export default function Title({
  children,
  className,
  cta,
  flush,
  image,
  nav,
  size = 'medium',
  small,
  subtext,
  ...props
}: Props) {
  // TODO
  if (size === 'large') {
    return (
      <Text
        {...props}
        element="header"
        className={classNames(
          'Title',
          styles.this,
          flush && styles['this---flush'],
          size && styles[`this---${size}`],
          className
        )}>
        {small && (
          <Row size="xsmall">
            <Text align="center">
              <Text color="purple" element="h2" weight="bold" className={styles.small}>
                {small}
              </Text>
            </Text>
          </Row>
        )}
        <Heading flush level={1} className={styles.title}>
          {children}
        </Heading>
        {subtext && (
          <>
            {_.isString(subtext) ? (
              <Text element="p" flush size="large" className={styles.subtext}>
                {subtext}
              </Text>
            ) : (
              <Copy element="div" size="large" className={styles.subtext}>
                {subtext}
              </Copy>
            )}
          </>
        )}
        {cta && <div className={styles.cta}>{cta}</div>}
      </Text>
    )
  }

  // TODO
  if (size === 'small') {
    return (
      <Text
        {...props}
        element="div"
        className={classNames('Title', styles.this, size && styles[`this---${size}`], className)}>
        {image && <Row size="small">{image}</Row>}
        <Text
          color="black"
          //element="h2"
          element="p"
          flush
          size="xxlarge"
          weight="bold"
          className={styles.title}>
          {children}
        </Text>
        {subtext && (
          <>
            {_.isString(subtext) ? (
              <Text element="p" flush size="large" weight="bold" className={styles.subtext}>
                {subtext}
              </Text>
            ) : (
              <Copy element="div" size="large" weight="bold" className={styles.subtext}>
                {subtext}
              </Copy>
            )}
          </>
        )}
        {cta && <div className={styles.cta}>{cta}</div>}
      </Text>
    )
  }

  return (
    <Text
      {...props}
      element="header"
      className={classNames(
        'Title',
        styles.this,
        props.color && styles[`this---${props.color}`],
        size && styles[`this---${size}`],
        className
      )}>
      <MediaObject center figure={nav} figurePosition="right" className={styles.inner}>
        <Text casing="upper" weight="bold" className={nav ? styles.title : undefined}>
          {children}
        </Text>
        {subtext && (
          <Text element="p" flush size="xlarge" weight="bold" className={styles.subtext}>
            {subtext}
          </Text>
        )}
      </MediaObject>
    </Text>
  )
}
