import _ from 'lodash'
import qs from 'qs'
import * as api from 'libs/api'

type ChallengeSearchParameters = {
  complete?: boolean
  isChallengeActive?: boolean
  status?: string
  tag?: string
  type?: string
}

export async function getActiveChallenges(parameters: ChallengeSearchParameters) {
  const path = 'challenges'

  try {
    return await api.get(!_.isEmpty(parameters) ? `${path}?${qs.stringify(parameters)}` : path)
  } catch (error) {
    return { error }
  }
}

export async function getOrCreateActiveTodaysPlanChallenges() {
  try {
    return await api.get(`todaysPlan`)
  } catch (error) {
    return { error }
  }
}

export async function getUserChallenges(parameters: ChallengeSearchParameters) {
  const path = 'challenges/user'

  try {
    return await api.get(
      !_.isEmpty(parameters)
        ? `${path}?${qs.stringify({
            ...parameters,
          })}`
        : path
    )
  } catch (error) {
    return { error }
  }
}

export async function joinChallenge(challengeId: string) {
  try {
    return await api.post(`challenges/${challengeId}`)
  } catch (error) {
    return { error }
  }
}

export async function markUserChallengeAsSeen(id: string) {
  try {
    return await api.post(`userChallenge/${id}/markAsSeen`)
  } catch (error) {
    return { error }
  }
}
