import React from 'react'
import Box from 'components/Box'
import Button from 'components/Button'
import ButtonsFloating from 'components/ButtonsFloating'
import Copy from 'components/Copy'
import ListNumbers from 'components/ListNumbers'
import Row from 'components/Row'
import Text, { type TextProps } from 'components/Text'
import * as events from 'constants/events'
import { useGlobalContext } from 'contexts/GlobalContext'
import OnboardingView from 'features/onboarding/components/OnboardingView'
import * as enterprise from 'libs/enterprise'
import * as user from 'libs/user'

type Props = {
  onNext: () => void
}

export default function OnboardingEnterpriseEligible({ onNext }: Props) {
  const globalContext = useGlobalContext()
  const [isClinical, setIsClinical] = React.useState(null)

  React.useEffect(() => {
    setIsClinical(user.isEnterpriseClinicalUser(globalContext.user))
  }, [globalContext.user])

  async function handleContinueButtonClick() {
    if (user.isEnterpriseReefOrOrcaUser(globalContext.user)) {
      await globalContext.analytics?.trackEvent(
        events.ENTERPRISE_CONFIRM_TOS,
        {},
        {
          enterpriseName: globalContext.user.enterpriseMember?.enterprise.internalId,
        }
      )
    }

    onNext()
  }

  const textPurpleProps: Partial<TextProps> = {
    color: 'purple',
    weight: 'bold',
  }

  const steps = isClinical
    ? [
        'Personalized exercise program tailored to your goals and preferences',
        'On-demand classes available anytime, from anywhere',
        'Tools and support to help you stay motivated and active',
      ]
    : ['Your preferences', 'Your background', 'Your movement ability']

  return (
    <OnboardingView
      small={isClinical ? 'Congrats' : ''}
      title={
        <>
          You’re eligible
          {globalContext.user.firstName ? (
            <>
              {', '}
              <Text {...textPurpleProps}>{globalContext.user.firstName}</Text>
            </>
          ) : (
            ''
          )}
          !
        </>
      }>
      <Row size="smallx">
        <Text element="p">
          You get access to Bold <Text {...textPurpleProps}>at no cost</Text>{' '}
          {user.isEnterpriseTrialUser(globalContext.user) ? (
            'for one year'
          ) : (
            <>
              via{' '}
              <Text {...textPurpleProps}>
                {enterprise.getNameTechnical(
                  globalContext.user.enterpriseMember?.enterprise?.internalId,
                  globalContext.user
                )}
              </Text>
            </>
          )}
          .
        </Text>
        {isClinical ? (
          <>
            <Text {...textPurpleProps} element="p">
              Included in your Bold membership:
            </Text>
            <ListNumbers data={steps} />
          </>
        ) : (
          <Box color="white" size="medium">
            <Copy>
              <Text element="h4">Let’s build you a personalized program:</Text>
              <Text element="p">
                We just need to learn a bit more about you. This should take less than{' '}
                <Text {...textPurpleProps}>5 minutes.</Text>
              </Text>
              <ListNumbers data={steps} color="black" connected weight="bold" />
            </Copy>
          </Box>
        )}
      </Row>
      <ButtonsFloating>
        <Button full size="large" onClick={handleContinueButtonClick}>
          {isClinical ? 'Continue' : 'Let’s get started!'}
        </Button>
      </ButtonsFloating>
    </OnboardingView>
  )
}
