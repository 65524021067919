import classNames from 'classnames'
import { Form, Formik } from 'formik'
import _ from 'lodash'
import React from 'react'
import * as Yup from 'yup'
import Button from 'components/Button'
import Field2 from 'components/Field2'
import FieldEmail from 'components/FieldEmail'
import FieldFirstName from 'components/FieldFirstName'
import FieldLastName from 'components/FieldLastName'
import Link from 'components/Link'
import Text from 'components/Text'
import paths from 'constants/paths'
import type { Component } from 'constants/types'
import * as notifications from 'libs/notifications'
import * as validations from 'libs/validations'

const EMAILS_COMPETITOR = ['hingehealth', 'kaiahealth', 'nymblscience', 'swordhealth']
const EMAILS_PERSONAL = ['aol', 'gmail', 'hotmail', 'icloud', 'outlook', 'yahoo']

type Props = {
  onSuccess?: () => void
} & Component

export default function RequestDemoForm({ className, onSuccess }: Props) {
  async function submit(values) {
    const { company, email, firstName, interest, jobTitle, lastName } = values

    const response = await fetch('https://api.airtable.com/v0/apprxg5JQ2HZGAwWQ/Demo', {
      method: 'POST',
      body: JSON.stringify({
        fields: {
          'Date': new Date().toString(),
          'First name': firstName,
          'Last name': lastName,
          'Email': email,
          'Company': company,
          'Job title': jobTitle,
          'Interest': interest,
        },
      }),
      headers: {
        'Authorization':
          'Bearer patRIvkm3O6dg9wqC.6c94f8d4c51f04dd6df693a74a05c2e92db13c41f74575927ab6a6bf3494a261',
        'Content-Type': 'application/json',
      },
    })

    if (response.status !== 200) {
      notifications.notifyError()
      return
    }

    notifications.notifySuccess('Request submitted')

    if (onSuccess) onSuccess()
  }

  function handleSubmit(values) {
    return submit(values)
  }

  return (
    <Formik
      initialValues={{
        company: '',
        consent: true,
        email: '',
        firstName: '',
        interest: '',
        jobTitle: '',
        lastName: '',
      }}
      validationSchema={Yup.object({
        company: validations.REQUIRED,
        consent: validations.CHECKBOX,
        email: Yup.string()
          .email('Invalid email')
          .lowercase()
          .test(
            'competitor',
            'Invalid email',
            (value) => !_.some(EMAILS_COMPETITOR, (item) => value?.includes(`${item}.com`))
          )
          .test(
            'personal',
            'Please use your work email',
            (value) => !_.some(EMAILS_PERSONAL, (item) => value?.includes(`${item}.com`))
          )
          .required(validations.REQUIRED_MESSAGE),
        firstName: validations.NAME,
        interest: validations.REQUIRED,
        jobTitle: validations.REQUIRED,
        lastName: validations.NAME,
      })}
      className={classNames('RequestDemoForm', className)}
      onSubmit={handleSubmit}>
      {(formikProps) => (
        <Form>
          <FieldFirstName />
          <FieldLastName />
          <FieldEmail label="Business email" />
          <Field2 label="Company" name="company" />
          <Field2 label="Job title" name="jobTitle" />
          <Field2
            label="I’m most interested in"
            name="interest"
            options={[
              {
                label: 'Improving my plan’s Star Ratings, including hard-to-move HOS measures',
                value: 'Improving my plan’s Star Ratings, including hard-to-move HOS measures',
              },

              {
                label: 'Increasing engagement in my supplemental fitness benefit',
                value: 'Increasing engagement in my supplemental fitness benefit',
              },
              {
                label:
                  'Addressing chronic conditions and improving clinical outcomes for high-risk members',
                value:
                  'Addressing chronic conditions and improving clinical outcomes for high-risk members',
              },
              {
                label: 'All of these',
                value: 'All',
              },
            ]}
            type="radio"
          />
          <Field2
            label={
              <>
                I confirm that I read and understand Bold’s{' '}
                <Link to={paths.PRIVACY_POLICY}>
                  <Text element="u">privacy policy</Text>
                </Link>
                .
              </>
            }
            name="consent"
            type="checkbox"
          />
          <Button
            color="yellow"
            disabled={formikProps.isSubmitting}
            full
            onClick={formikProps.submitForm}>
            Request meeting
          </Button>
        </Form>
      )}
    </Formik>
  )
}
