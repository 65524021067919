import { useLocation } from '@reach/router'
import classNames from 'classnames'
import React from 'react'
import Buttons from 'components/Buttons'
import Container from 'components/Container'
import Link from 'components/Link'
import Row from 'components/Row'
import Text from 'components/Text'
import Title from 'components/Title'
import View, { type ViewProps } from 'components/View'
import paths from 'constants/paths'
import styles from './ActivityView.module.scss'

type Props = {
  children: React.ReactNode
  theme?: 'dark'
  title: string
} & ViewProps

export default function ActivityView({ children, theme, title, ...props }: Props) {
  const location = useLocation()

  const navs = [
    {
      copy: 'Activity',
      to: paths.ACTIVITY,
    },
    {
      copy: 'Assessments',
      to: paths.ASSESSMENTS_AUTHED,
    },
    {
      copy: 'Badges',
      to: paths.BADGES,
    },
  ]

  return (
    <View {...props}>
      <Row size="small">
        <Container flush size="xlarge">
          <Title
            color={theme === 'dark' ? 'white' : undefined}
            nav={
              <Buttons align="right" element="nav">
                {navs.map((item) => {
                  if (location.pathname === item.to) return null

                  if (item.component) return item.component

                  return (
                    <Link
                      to={item.to}
                      nav
                      key={item.to}
                      className={classNames(
                        styles['nav--link'],
                        item.notification && styles['nav--link---notification']
                      )}>
                      <Text casing="upper" weight="bold">
                        {item.copy}
                      </Text>
                    </Link>
                  )
                })}
              </Buttons>
            }>
            {title}
          </Title>
        </Container>
      </Row>
      {children}
    </View>
  )
}
