import classNames from 'classnames'
//import numberToWords from 'number-to-words'
import React from 'react'
import { FacebookShareButton } from 'react-share'
import Button from 'components/Button'
import Buttons from 'components/Buttons'
import Container from 'components/Container'
import Image from 'components/Image'
import ModalBox from 'components/ModalBox'
import Row from 'components/Row'
import * as events from 'constants/events'
import type { Component } from 'constants/types'
import urls from 'constants/urls'
import { useGlobalContext } from 'contexts/GlobalContext'
import lessons5BadgeImage from 'images/badges/lessons---5.svg'
import lessons10BadgeImage from 'images/badges/lessons---10.svg'
import lessons20BadgeImage from 'images/badges/lessons---20.svg'
import { ReactComponent as ShareSvg } from './images/share.svg'

type Props = {
  classMilestoneCount: number
  onRequestLessonFeedback: () => void
} & Component

export default function Congrats({
  className,
  classMilestoneCount,
  onRequestLessonFeedback,
}: Props) {
  const globalContext = useGlobalContext()

  const handleShareButtonClick = () => {
    globalContext.analytics?.trackEvent(
      events.POST_LESSON_MODAL_BADGE_SHARE_TO_FACEBOOK_BUTTON_CLICK,
      {
        classMilestoneCount,
      }
    )
  }

  let badgeImgUrl = null
  switch (classMilestoneCount) {
    case 5: {
      badgeImgUrl = lessons5BadgeImage
      break
    }
    case 10: {
      badgeImgUrl = lessons10BadgeImage
      break
    }
    case 20: {
      badgeImgUrl = lessons20BadgeImage
      break
    }
    default: {
      break
    }
  }

  return (
    <ModalBox title="🎉 You just earned a badge!" className={classNames('Congrats', className)}>
      <Row>
        <Container flush size="xxsmall">
          <Image src={badgeImgUrl} />
        </Container>
      </Row>
      <Buttons align="center">
        {classMilestoneCount && (
          <FacebookShareButton
            url={`${urls.PRODUCTION}?source=facebook_${classMilestoneCount}_class_badge`}>
            <Button color="purple" faux icon={<ShareSvg />} onClick={handleShareButtonClick}>
              Share
            </Button>
          </FacebookShareButton>
        )}
        <Button
          icon="arrow-right"
          iconPosition="right"
          level="tertiary"
          onClick={onRequestLessonFeedback}>
          Continue
        </Button>
      </Buttons>
    </ModalBox>
  )
}
