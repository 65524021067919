import React from 'react'
import Button from 'components/Button'
import paths from 'constants/paths'
import type { Component } from 'constants/types'
import DashboardLessons from '../DashboardLessons'

type Props = {
  data: []
  isLoading: boolean
} & Component

export default function DashboardLessonsFavorite({ data, isLoading = true }: Props) {
  return (
    <DashboardLessons
      title="My saved classes"
      action={<Button to={paths.LESSONS_FAVORITE}>See all saved classes</Button>}
      data={data}
      loading={isLoading}
      className="DashboardLessonsFavorite"
    />
  )
}
