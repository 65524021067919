import {
  format as dateFnsFormat,
  formatRelative as dateFnsFormatRelative,
  fromUnixTime,
  getDay,
  isDate,
  parse,
  parseISO,
} from 'date-fns'
import _ from 'lodash'

export const DAYS_OF_WEEK = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]
export const DAYS_OF_WEEK_SMALL = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat']
export const DAYS_OF_WEEK_XSMALL = ['Su', 'M', 'T', 'W', 'Th', 'F', 'S']
export const DAYS_OF_WEEK_XXSMALL = ['S', 'M', 'T', 'W', 'T', 'F', 'S']
export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
export const TIMES = [
  {
    label: '6:00 AM',
    value: '06:00',
  },
  {
    label: '7:00 AM',
    value: '07:00',
  },
  {
    label: '8:00 AM',
    value: '08:00',
  },
  {
    label: '9:00 AM',
    value: '09:00',
  },
  {
    label: '10:00 AM',
    value: '10:00',
  },
  {
    label: '11:00 AM',
    value: '11:00',
  },
  {
    label: '12:00 PM',
    value: '12:00',
  },
  {
    label: '1:00 PM',
    value: '13:00',
  },
  {
    label: '2:00 PM',
    value: '14:00',
  },
  {
    label: '3:00 PM',
    value: '15:00',
  },
  {
    label: '4:00 PM',
    value: '16:00',
  },
  {
    label: '5:00 PM',
    value: '17:00',
  },
  {
    label: '6:00 PM',
    value: '18:00',
  },
  {
    label: '7:00 PM',
    value: '19:00',
  },
  {
    label: '8:00 PM',
    value: '20:00',
  },
  {
    label: '9:00 PM',
    value: '21:00',
  },
  {
    label: '10:00 PM',
    value: '22:00',
  },
  {
    label: '11:00 PM',
    value: '23:00',
  },
  {
    label: '12:00 AM',
    value: '00:00',
  },
  {
    label: '1:00 AM',
    value: '01:00',
  },
  {
    label: '2:00 AM',
    value: '02:00',
  },
  {
    label: '3:00 AM',
    value: '03:00',
  },
  {
    label: '4:00 AM',
    value: '04:00',
  },
  {
    label: '5:00 AM',
    value: '05:00',
  },
]
export const TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone
const TIMESTAMP_FORMAT = `yyyy-MM-dd'T'HH:mm:ss.SSSxxx`

export function format(date: unknown, formatString = 'MMMM do, y') {
  if (!date) return ''

  let value = date

  if (_.isNumber(value)) value = fromUnixTime(date as number)
  if (!isDate(value)) value = parseISO(date as string)
  if (!isDate(value)) value = new Date(date as Date | number | string)

  if (!isDate(value)) return date

  try {
    return dateFnsFormat(value, formatString)
  } catch {
    return date
  }
}

//export const formatApi = (date: string) => format(date, 'yyyy-MM-dd')

export const formatDisplay = (date: string) => format(date)

export const formatDisplayDayWithTime = (date: string) =>
  `${format(date, 'eeee')} at ${format(date, 'h:mm a')}`

export const formatDisplayShortWithTime = (date: string) =>
  `${format(date, 'M/d')} ${format(date, 'h:mm a')}`

export const formatDisplayWithDayOfWeek = (date: string) => `${format(date, 'eeee, MMMM do')}`

export const formatDisplayWithTime = (date: string) =>
  `${format(date, 'eeee, MMMM do')} at ${format(date, 'h:mm a')}`

export function formatInput(date: string) {
  const [dateWithoutTimestamp] = date.split('T')
  const parsedDate = parse(dateWithoutTimestamp, 'yyyy-MM-dd', new Date())
  return format(parsedDate, 'MM/dd/yyyy')
}

export const formatRelative = (date: string) => dateFnsFormatRelative(parseISO(date), new Date())

export const formatTime = (date: string) => format(date, 'h:mm a')

export const formatTimestamp = (date: string) => format(date, TIMESTAMP_FORMAT)

export const getDateFromTimestamp = (date: string) => parse(date, TIMESTAMP_FORMAT, new Date())

export const getDateFromYYYY_MM_DD = (date: string) => parse(date, 'yyyy-MM-dd', new Date())

export const getDayOfWeek = (date: Date | number) => DAYS_OF_WEEK[getDay(date)]

export function getDayOfWeekNext(dayOfWeek: string, date = new Date()) {
  const dateCopy = new Date(date.getTime())
  const indexOfDayOfWeek = DAYS_OF_WEEK.indexOf(dayOfWeek)
  const nextDay = new Date(
    dateCopy.setDate(dateCopy.getDate() + ((7 - dateCopy.getDay() + indexOfDayOfWeek) % 7 || 7))
  )
  return nextDay
}

export const getYYYY_MM_DDFromTimestamp = (date: string) => date.slice(0, 10)
