import classNames from 'classnames'
import { navigate } from 'gatsby'
import _ from 'lodash'
import pluralize from 'pluralize'
import React from 'react'
import useWindowSize from 'react-use/lib/useWindowSize'
import Box from 'components/Box'
import Button from 'components/Button'
import MediaObject from 'components/MediaObject'
import Progress from 'components/Progress'
import Row from 'components/Row'
import Text from 'components/Text'
import * as events from 'constants/events'
import paths from 'constants/paths'
import type { Component } from 'constants/types'
import { useGlobalContext } from 'contexts/GlobalContext'
import * as challengeLib from 'libs/challenge'
import type { Challenge as ChallengeType, UserChallenge as UserChallengeType } from 'libs/challenge'
import * as challengeApiRequest from 'libs/challenge-api-request'
import * as notifications from 'libs/notifications'
import constants from 'styles/constants.module.scss'
import { ReactComponent as BadgeSvg } from './images/badge.svg'

type Props = {
  badge?: boolean
  color?: 'pink' | 'white'
  from: string
} & Component

export default function Challenge({
  badge,
  color = 'white',
  className,
  from: fromProperty,
}: Props) {
  const globalContext = useGlobalContext()
  const { width } = useWindowSize()
  const [challenge, setChallenge] = React.useState<ChallengeType | {}>({})
  const [userChallengeProgress, setUserChallengeProgress] = React.useState<UserChallengeType | {}>(
    {}
  )
  const [isJoiningChallenge, setIsJoiningChallenge] = React.useState(false)

  const getData = React.useCallback(async () => {
    const challengeType = challengeLib.CHALLENGE_TYPE.ONEOFF

    const challengesResponse = await challengeApiRequest.getActiveChallenges({
      type: challengeType,
    })

    const challengesActiveResponse = await challengeApiRequest.getUserChallenges({
      isChallengeActive: true,
      status: 'active',
      type: challengeType,
    })

    const challengesData = challengesResponse?.data
    const challengeData = _.orderBy(challengesData, 'activatedAt', 'asc')[0]

    const challengeActiveData = challengesActiveResponse?.data?.find(
      (activeChallenge) => activeChallenge.challengeId === challengeData?.id
    )

    setChallenge(challengeData)
    setUserChallengeProgress(challengeActiveData)
  }, [])

  React.useEffect(() => {
    getData()
  }, [getData])

  // TODO: dedupe with features/user/pages/Activity/components/Challenges
  async function handleJoinChallenge(challengeId: string, challengeName: string) {
    try {
      // Prevent double click
      // https://stackoverflow.com/questions/35315872/reactjs-prevent-multiple-times-button-press
      if (isJoiningChallenge) {
        return null
      }

      setIsJoiningChallenge(true)
      await challengeApiRequest.joinChallenge(challengeId)

      getData()
      setIsJoiningChallenge(false)
      notifications.notifySuccess('Thanks for joining the challenge!')
      globalContext.analytics?.trackEvent(events.DASHBOARD_CHALLENGE_JOIN_BUTTON_CLICK, {
        challengeId,
        challengeName,
        success: true,
      })
      globalContext.analytics?.trackEvent(events.JOIN_CHALLENGE, {
        challengeId,
        challengeName,
        success: true,
      })
    } catch {
      setIsJoiningChallenge(false)
      notifications.notifyError(
        'Something went wrong joining that challenge. Please try again later.'
      )
      globalContext.analytics?.trackEvent(events.JOIN_CHALLENGE, {
        challengeId,
        challengeName,
        success: false,
      })
    }
  }

  function handleButtonClick(challengeClicked: any) {
    navigate(paths.getChallengePath(challengeClicked), {
      state: {
        from: fromProperty,
      },
    })
  }

  if (_.isEmpty(challenge)) {
    return null
  }

  return (
    <Box color={color} className={classNames('Challenge', className)}>
      <MediaObject figure={!!badge && <BadgeSvg aria-hidden />} center reset="tablet" size="small">
        <MediaObject
          figure={
            !_.isEmpty(userChallengeProgress) ? (
              <Button
                color={userChallengeProgress.complete ? 'white' : 'purple'}
                onClick={() => handleButtonClick(challenge)}>
                View classes
              </Button>
            ) : (
              <Button
                color={color === 'pink' ? undefined : 'yellow'}
                loading={isJoiningChallenge}
                onClick={() => handleJoinChallenge(challenge.id, challenge.name)}>
                Join challenge
              </Button>
            )
          }
          center
          figurePosition="right"
          reset="tablet">
          <Text
            align={width <= Number(constants.TABLET_PX) ? 'center' : undefined}
            color="black"
            element="div">
            {_.isEmpty(userChallengeProgress) && (
              <Row size="xxsmall">
                <Text casing="upper" color="black" element="h2" size="xsmall" weight="bold">
                  Join the challenge
                </Text>
              </Row>
            )}
            <Row size="xxsmall">
              <Text element="h3" size="xlarge" weight="bold">
                {challenge.name}
              </Text>
            </Row>
            <Text element="p" flush={_.isEmpty(userChallengeProgress)} lines={2}>
              {challenge.description}
            </Text>
            {userChallengeProgress?.complete && (
              <Row size="xxsmall">
                <Text weight="bold">🎉 Congrats on completing this challenge!</Text>
              </Row>
            )}
            {!_.isEmpty(userChallengeProgress) && !userChallengeProgress.complete && (
              <>
                <Row size="xxsmall">
                  <Text weight="bold">
                    {Math.round(userChallengeProgress.progress)} of{' '}
                    {pluralize(
                      userChallengeProgress.challenge.unit === 'unit' ||
                        userChallengeProgress.challenge.unit === 'lesson'
                        ? 'class'
                        : userChallengeProgress.challenge.unit,
                      userChallengeProgress.challenge.threshold,
                      true
                    )}
                  </Text>
                </Row>
                <Row size="xxsmall">
                  <Progress
                    value={userChallengeProgress.progress}
                    total={userChallengeProgress.challenge.threshold}
                    color="green"
                    size="small"
                  />
                </Row>
              </>
            )}
          </Text>
        </MediaObject>
      </MediaObject>
    </Box>
  )
}
