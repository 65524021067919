import type { PageProps } from 'gatsby'
import React from 'react'
import HomeSpecial from 'components/landing/HomeSpecial'
import HomeSpecialLayout from 'components/landing/HomeSpecialLayout'

type Props = PageProps

export default function HomeSpecialPain(props: Props) {
  return (
    <HomeSpecialLayout {...props} className="HomeSpecialPain">
      <HomeSpecial
        title="Manage pain for free*"
        subtext="Bold’s free* pain programs are shown to reduce joint pain by 50%. Take your first class today."
        disclaimer="*Free if covered by your insurance"
      />
    </HomeSpecialLayout>
  )
}
