import { navigate } from 'gatsby'
import React from 'react'
import FormFieldsRequired from 'components/FormFieldsRequired'
import * as events from 'constants/events'
import paths from 'constants/paths'
import storage from 'constants/storage'
import { useGlobalContext } from 'contexts/GlobalContext'
import OnboardingLayout from 'features/onboarding/components/OnboardingLayout'
import OnboardingView from 'features/onboarding/components/OnboardingView'
import useEventPageVisited from 'hooks/use-event-page-visited'
import * as cookie from 'libs/cookie'
import * as enterprise from 'libs/enterprise'
import * as subscriptions from 'libs/subscriptions'
import * as user from 'libs/user'
import OnboardingProfileForm from './components/OnboardingProfileForm'

export default function OnboardingProfile() {
  useEventPageVisited(events.ONBOARDING_PROFILE_PAGE)
  const globalContext = useGlobalContext()
  const [disabled, setDisabled] = React.useState(false)
  const isSpecial = user.hasHomeSpecial()
  const isElig =
    user.isEnterpriseUser(globalContext.user) && globalContext.user?.enterpriseMember?.validated
  const partner = cookie.getCookie(storage.HOME_KEY)

  async function submit(updatedUser: any) {
    if (!globalContext.user) return

    await globalContext.analytics?.trackEvent(
      events.PROFILE_CREATION_COMPLETE,
      {
        coupon: cookie.getCookie(storage.PROMO_CODE_KEY),
        isNavigationVariant: !isSpecial ? 'true' : 'false',
      },
      {
        trialLength: !user.isEnterpriseUser(updatedUser) ? subscriptions.TRIAL_DAYS : null,
        profileCompletedDate: new Date(),
      }
    )

    navigate(
      isSpecial && !isElig
        ? paths.ONBOARDING_ENTERPRISE_ELIGIBILITY // nay page
        : user.isOnboardingPaymentRequired(globalContext.user)
          ? paths.ONBOARDING_PAYMENT
          : paths.ONBOARDING_PROGRAM_BUILD,
      { replace: true }
    )
  }

  async function handleSuccess(updatedUser: any) {
    setDisabled(true)
    await submit(updatedUser)
  }

  return (
    <OnboardingLayout title="Profile">
      <OnboardingView
        small="Finish profile"
        title={
          partner === enterprise.REEF_KEY || partner === enterprise.ORCA_KEY || isSpecial
            ? 'Create a password to get moving'
            : 'Last step to get your personalized program at no cost'
        }>
        <FormFieldsRequired />
        <OnboardingProfileForm disabled={disabled} onSuccess={handleSuccess} />
      </OnboardingView>
    </OnboardingLayout>
  )
}
