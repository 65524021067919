import classNames from 'classnames'
import React from 'react'
import Text from 'components/Text'
import TextWithIcon, { type TextWithIconProps } from 'components/TextWithIcon'
import * as date from 'libs/date'

const FORMATS = {
  display: 'formatDisplay',
  displayShortWithTime: 'formatDisplayShortWithTime',
  displayWithTime: 'formatDisplayWithTime',
  relative: 'formatRelative',
  time: 'formatTime',
}

type Props = {
  children?: React.ReactNode
  data?: number | string | {}
  format?: 'display' | 'displayShortWithTime' | 'displayWithTime' | 'relative' | 'time'
} & Partial<TextWithIconProps>

export default function DateTime({
  children,
  className,
  data = new Date(),
  format = 'display',
  ...props
}: Props) {
  const Component = props.icon ? TextWithIcon : Text

  return (
    <Component
      {...props}
      dateTime={date.formatTimestamp(data)}
      element="time"
      className={classNames('DateTime', className)}>
      {children ?? date[FORMATS[format]](data)}
    </Component>
  )
}
