import phoneNumbers from '@bold/common/constants/phone-numbers'
import classNames from 'classnames'
import { Form, Formik } from 'formik'
import { navigate } from 'gatsby'
import _ from 'lodash'
import pluralize from 'pluralize'
import React from 'react'
import * as Yup from 'yup'
import Box from 'components/Box'
import Button from 'components/Button'
import Buttons from 'components/Buttons'
import ButtonWithModal from 'components/ButtonWithModal'
import Copy from 'components/Copy'
import Field2 from 'components/Field2'
import FieldDateOfBirth from 'components/FieldDateOfBirth'
import FieldFirstName from 'components/FieldFirstName'
import FieldLastName from 'components/FieldLastName'
import FieldPhoneNumber from 'components/FieldPhoneNumber'
import FieldPostalCode from 'components/FieldPostalCode'
import Fields from 'components/Fields'
import Link from 'components/Link'
import ModalBox from 'components/ModalBox'
import Row from 'components/Row'
import Text from 'components/Text'
import TextWithIcon from 'components/TextWithIcon'
import * as events from 'constants/events'
import paths from 'constants/paths'
import statusCodes from 'constants/status-codes'
import storage from 'constants/storage'
import type { Component } from 'constants/types'
import { useGlobalContext } from 'contexts/GlobalContext'
import EnterpriseReefOrcaFieldCode from 'features/enterprise/reef-orca/components/EnterpriseReefOrcaFieldCode'
import * as cookie from 'libs/cookie'
import * as date from 'libs/date'
import * as enterprise from 'libs/enterprise'
import * as enterpriseMemberApiRequest from 'libs/enterprise-member-api-request'
import * as logrocket from 'libs/logrocket'
import * as notifications from 'libs/notifications'
import * as user from 'libs/user'
import * as userApiRequest from 'libs/user-api-request'
import * as validations from 'libs/validations'
import styles from './EnterpriseEligibilityForm.module.scss'
import { ReactComponent as ArrowUpRightSvg } from './images/arrow---up-right.svg'
import { ReactComponent as OrcaSvg } from './images/partners/orca.svg'
import { ReactComponent as ReefSvg } from './images/partners/reef.svg'

// these should be proper radio inputs
function EnterpriseEligibilityFormButtonInputRadio({
  externalIdType,
  labelNote,
  field,
  value,
  ...props
}: {
  externalIdType: string
  field: React.ReactElement
  labelNote?: React.ReactElement
  value: string
}) {
  return (
    <Row size="smallx">
      <TextWithIcon
        {...props}
        align="left"
        element="button"
        type="button"
        icon={<div className={styles['button-input-radio--indicator']} />}
        className={classNames(
          styles['button-input-radio'],
          value === externalIdType && styles['button-input-radio---active'],
          externalIdType &&
            !_.isNil(value) &&
            value !== externalIdType &&
            styles['button-input-radio---inactive']
        )}
      />
      <div className={styles['button-input-radio--body']}>
        {labelNote}
        {value === externalIdType && (
          <div className={styles['button-input-radio--field']}>{field}</div>
        )}
      </div>
    </Row>
  )
}

type Props = {
  from: string
  onSkip?: () => void
  // eslint-disable-next-line no-unused-vars
  onSubmit?: (values: any) => void
  // eslint-disable-next-line no-unused-vars
  onSuccess: (updatedUser: any) => void
} & Component

export default function EnterpriseEligibilityForm({ from, onSkip, onSubmit, onSuccess }: Props) {
  const globalContext = useGlobalContext()
  const fromSettings = from === 'settings'
  const isSpecial = user.hasHomeSpecial()

  const [externalIdType, setExternalIdType] = React.useState()
  const [isSubmitting, setIsSubmitting] = React.useState(false)

  const partner = cookie.getCookie(storage.HOME_KEY) || globalContext.partner
  const reefAndOrOrca = enterprise.determineReefOrOrca(partner)
  const askForReefOrcaCode = reefAndOrOrca.length > 0

  function handleError(error, formikActions) {
    console.error(error)
    notifications.notifyError()
    formikActions.setSubmitting(false)
  }

  async function tryReefOrcaCodeToConvertToEnterprise(
    enterpriseName: string,
    enterpriseId: string,
    externalId: string,
    formikActions: any
  ) {
    const handleReefCodeError = async () => {
      formikActions.setSubmitting(false)
      await globalContext.analytics?.trackEvent(events.ENTERPRISE_REEFORCA_CODE_FAILED, {
        enterprise: enterpriseName,
        codeSource: 'user',
        codeUsed: externalId,
      })
    }

    const updatedUser = await enterprise.convertReefOrcaUserToEnterprise({
      data: {
        enterpriseId,
        externalId,
      },
      onError: async () => handleReefCodeError(),
    })

    return updatedUser
  }

  async function convertUserToEnterprise(enterpriseMember, formikActions) {
    const response = await userApiRequest
      .linkUserWithEnterpriseMember({ enterpriseMemberId: enterpriseMember.id })
      .catch((error) => {
        handleError(error, formikActions)
      })

    if (response.message || response.error) {
      const message = response.message || response.error.message
      handleError(message, formikActions)
    }

    await globalContext.analytics?.trackEvent(
      events.ENTERPRISE_MEMBER_LINKED,
      {
        from,
      },
      {
        eligibilityStartDate: enterpriseMember.eligibilityStartDate,
      }
    )

    return response?.data
  }

  async function submit(values, formikActions) {
    let convertedToEnterprise: boolean = false
    let updatedUser = null
    // Eligibility file lookup
    if (enterprise.PARTNERS_WITH_ELIGIBILITY_FILE.includes(partner) || !partner) {
      const { data: enterpriseData } = await userApiRequest.getEnterpriseByInternalId(
        enterprise.getInternalId(partner)
      )

      const enterpriseMemberResponse = await enterpriseMemberApiRequest.verifyUserEligibility({
        ...values,
        enterpriseIds: [enterpriseData?.id],
        zipCode: values.postalCode,
      })

      if (
        enterpriseMemberResponse.statusCode === statusCodes.SUCCESS &&
        enterpriseMemberResponse.data?.id
      ) {
        updatedUser = await convertUserToEnterprise(enterpriseMemberResponse.data, formikActions)
        convertedToEnterprise = !!updatedUser?.enterpriseMemberId
      }
    }

    // Reef / Orca eligibility lookup
    if (askForReefOrcaCode && !convertedToEnterprise) {
      const ent = reefAndOrOrca[0] // assuming reef over orca if checking for both
      const { data: enterpriseData } = await userApiRequest.getEnterpriseByInternalId(ent)
      const enterpriseId = enterpriseData?.id

      // they provided a confirmation code
      if (values.externalId) {
        updatedUser = await tryReefOrcaCodeToConvertToEnterprise(
          ent,
          enterpriseId,
          values.externalId,
          formikActions
        )
        convertedToEnterprise = !!updatedUser?.enterpriseMemberId
      }

      // try to lookup confirmation code
      if (fromSettings && !convertedToEnterprise) {
        // run pverify first from settings page only
        const response = await userApiRequest.checkUserHealthPlanAndConvert({
          firstName: values.firstName,
          lastName: values.lastName,
          dob: values.dateOfBirth,
        })

        if (response.statusCode === statusCodes.SUCCESS && response.data) {
          updatedUser = response.data
          convertedToEnterprise = true
        }
      } else if (!convertedToEnterprise) {
        // run just partner API
        const healthPlanData = {
          firstName: values.firstName,
          lastName: values.lastName,
          dob: values.dateOfBirth,
          enterpriseId,
          healthPlanMemberId: values.memberID,
          zipCode: values.postalCode,
        }
        const response = await userApiRequest.getReefOrcaConfirmationCodeAndConvert(healthPlanData)

        if (response.statusCode === statusCodes.SUCCESS && response.data) {
          updatedUser = response.data
          convertedToEnterprise = true
        }
      }
    }

    if (!convertedToEnterprise) {
      await globalContext.analytics?.trackEvent(events.ENTERPRISE_MEMBER_NOT_MATCHED_AT_ALL, {
        enterprise: partner,
      })
    }

    await userApiRequest
      .updateUser({
        ...values,
      })
      .catch((error: any) => {
        handleError(error, formikActions)
      })

    if (updatedUser) await globalContext.update({ user: updatedUser })
    await globalContext.updateUser()

    formikActions.setSubmitting(false)

    onSuccess(updatedUser)
  }

  async function handleSubmit(values: any, formikActions: any) {
    setIsSubmitting(true)
    if (onSubmit) onSubmit(values)

    const trimmedValues: any = {}
    Object.keys(values).forEach((key) => {
      trimmedValues[key] = typeof values[key] === 'string' ? values[key].trim() : values[key]
    })
    await submit(trimmedValues, formikActions)
  }

  async function handleSkipClick() {
    if (onSkip) onSkip()

    // skip eligibility verdict
    navigate(enterprise.isClinical(partner) ? paths.ONBOARDING_QUESTIONS : paths.ONBOARDING_INTRO)
  }

  const askForMemberId = partner !== enterprise.OTTER_KEY
  const askForPhone = !enterprise.REEF_ORCA_SOURCES.includes(partner)
  const askForZip = partner !== enterprise.GROUPER_KEY && !isSpecial
  const memberIdRequired = partner === enterprise.GROUPER_KEY
  const reefOrcaEnterprises = [
    {
      copy: enterprise.getNameTechnical(enterprise.REEF_KEY),
      logo: ReefSvg,
      to: 'https://www.uhc.com/member-resources/health-care-programs/wellness-and-rewards-programs/renew-active',
    },
    {
      copy: enterprise.getNameTechnical(enterprise.ORCA_KEY),
      logo: OrcaSvg,
      to: 'https://www.youronepass.com/member-code-check',
    },
  ]
  const reefOrcaExternalIdText = enterprise.getReefAndOrOrcaName(partner)

  const enterpriseReefOrcaCodeButton = (
    <ButtonWithModal
      color="purple"
      level="text"
      modal={
        <ModalBox
          title={`Finding your ${reefOrcaExternalIdText}`}
          subtext={
            <>
              Call Bold at <Link to={phoneNumbers.DEFAULT} /> and our team can assist.
            </>
          }>
          <Copy>
            <Text element="p">
              The {pluralize('link', reefAndOrOrca.length)} provided here will take you to where you
              will find your code. Note, you might have to sign in to view the page.
            </Text>
            <Text element="p">
              Once you’ve copied your code, come back here and paste it in this field.
            </Text>
          </Copy>
          {reefOrcaEnterprises
            .filter((item) =>
              reefAndOrOrca.includes(enterprise.REEF_KEY) && item.logo === ReefSvg
                ? item
                : reefAndOrOrca.includes(enterprise.ORCA_KEY) && item.logo === OrcaSvg
                  ? item
                  : null
            )
            .map((item) => (
              <Row key={item.to} size="small">
                <Box color="grey">
                  <Row size="small">
                    <item.logo aria-label={item.copy} />
                  </Row>
                  <Button to={item.to} icon={<ArrowUpRightSvg />} iconPosition="right">
                    Learn more
                  </Button>
                </Box>
              </Row>
            ))}
        </ModalBox>
      }>
      <Text weight="normal">What is this?</Text>
    </ButtonWithModal>
  )

  const fieldProps = {
    ...logrocket.INPUT_PHI_PROPS,
    disabled: isSubmitting,
  }

  const fieldDateOfBirthProps = {
    ...fieldProps,
    required: true,
  }

  // React.cloneElement() didn't work
  const askForIds = askForMemberId && askForReefOrcaCode

  const memberIdField = (
    <Field2
      {...fieldProps}
      label="Health plan member ID"
      autoFocus={askForIds}
      flush
      labelHidden={askForIds}
      name="memberID"
      required={memberIdRequired}
    />
  )

  const zipField = <FieldPostalCode {...fieldProps} labelHidden={isSpecial} optional={!isSpecial} />

  const reefOrcaCodeField = (
    <EnterpriseReefOrcaFieldCode
      label={reefOrcaExternalIdText}
      autoFocus={askForIds}
      flush
      labelHidden={askForIds}
      name="externalId"
    />
  )

  // can skip only from regular or special
  const canSkip = from === 'recheck' && (partner === 'special' || !partner)

  return (
    <div className="EnterpriseEligibilityForm">
      <Formik
        enableReinitialize
        initialValues={{
          dateOfBirth:
            globalContext.user.dateOfBirth && !fromSettings
              ? date.formatInput(globalContext.user.dateOfBirth)
              : '',
          externalId: '',
          firstName: !fromSettings ? globalContext.user.firstName : '',
          lastName: !fromSettings ? globalContext.user.lastName : '',
          memberID: '',
          phoneNumber: !fromSettings ? globalContext.user.phoneNumber : '',
          postalCode: !fromSettings ? globalContext.user.postalCode : '',
        }}
        validationSchema={Yup.object({
          dateOfBirth: validations.DATE_OF_BIRTH,
          externalId: askForReefOrcaCode ? validations.REEF_ORCA_EXTERNAL_ID_OPTIONAL : undefined,
          firstName: validations.NAME,
          lastName: validations.NAME,
          memberID: memberIdRequired ? validations.MEMBER_ID : validations.MEMBER_ID_OPTIONAL,
          phoneNumber: askForPhone ? validations.PHONE_NUMBER : undefined,
          postalCode: validations.POSTAL_CODE_OPTIONAL,
        })}
        onSubmit={handleSubmit}>
        {(formikProps) => (
          <Form>
            <Row size="xmedium">
              {!fromSettings && isSpecial && globalContext.user.firstName ? null : ( // don't re-ask name for special if already have it from sign up, to simplify recheck step
                <Fields>
                  <FieldFirstName {...fieldProps} required />
                  <FieldLastName {...fieldProps} required />
                </Fields>
              )}
              {askForZip ? (
                <Fields>
                  <FieldDateOfBirth {...fieldDateOfBirthProps} />
                  {zipField}
                </Fields>
              ) : (
                <FieldDateOfBirth {...fieldDateOfBirthProps} />
              )}
              {askForPhone && <FieldPhoneNumber {...fieldProps} required />}
              {askForIds && (
                <Box color="white" size="small">
                  <Row size="smallx">
                    <Text color="black" weight="bold">
                      {isSpecial ? 'Select one' : 'Select one (Optional)'}
                    </Text>
                  </Row>
                  {isSpecial && (
                    <EnterpriseEligibilityFormButtonInputRadio
                      externalIdType={externalIdType}
                      field={zipField}
                      value="zip"
                      onClick={() => setExternalIdType('zip')}>
                      ZIP code
                    </EnterpriseEligibilityFormButtonInputRadio>
                  )}
                  {askForMemberId && (
                    <EnterpriseEligibilityFormButtonInputRadio
                      externalIdType={externalIdType}
                      field={memberIdField}
                      value="other"
                      onClick={() => setExternalIdType('other')}>
                      Health plan member ID
                    </EnterpriseEligibilityFormButtonInputRadio>
                  )}
                  {askForReefOrcaCode && (
                    <EnterpriseEligibilityFormButtonInputRadio
                      externalIdType={externalIdType}
                      labelNote={<>({enterpriseReefOrcaCodeButton})</>}
                      value="reef-orca"
                      field={reefOrcaCodeField}
                      onClick={() => setExternalIdType('reef-orca')}>
                      {reefOrcaExternalIdText}
                    </EnterpriseEligibilityFormButtonInputRadio>
                  )}
                </Box>
              )}
              {askForMemberId && !askForReefOrcaCode && memberIdField}
              {!askForMemberId && askForReefOrcaCode && reefOrcaCodeField}
            </Row>
            <Buttons axis="y" size="small">
              <Button
                disabled={formikProps.isSubmitting}
                full
                loading={isSubmitting}
                size="large"
                onClick={formikProps.submitForm}>
                {isSubmitting ? 'Checking…' : 'Submit'}
              </Button>
              {canSkip && (
                <Button
                  color="grey"
                  data-test-id="eligibility-recheck-form--skip-button"
                  disabled={formikProps.isSubmitting}
                  full
                  level="text"
                  onClick={handleSkipClick}>
                  Skip
                </Button>
              )}
            </Buttons>
          </Form>
        )}
      </Formik>
    </div>
  )
}
