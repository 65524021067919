import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import * as rdd from 'react-device-detect'
import Button from 'components/Button'
import ModalBox from 'components/ModalBox'
import Text from 'components/Text'
import * as events from 'constants/events'
import urls from 'constants/urls'
import { useGlobalContext } from 'contexts/GlobalContext'
import * as api from 'libs/api'

type Props = {
  onRequestClose: () => void
}

export default function AppDownloadModalBox({ onRequestClose }: Props) {
  const globalContext = useGlobalContext()
  const ipRef = React.useRef()
  const isAndroid = rdd.isAndroid

  React.useEffect(() => {
    async function getIp() {
      const response = await fetch('https://api.ipify.org?format=json')
      const data = await response.json()
      if (data.ip) ipRef.current = data.ip
    }
    getIp()
  }, [globalContext.user, ipRef])

  const registerIpForDownload = async () => {
    if (!ipRef.current) return

    await api.post('registerIpForAppDownloadAuthed', {
      ipAddress: ipRef.current,
    })
  }

  function handleDownloadClick() {
    globalContext.analytics?.trackEvent(events.DOWNLOAD_APP_CLICK, {
      page: 'Program',
      platform: isAndroid ? 'Android' : 'iOS',
    })
    registerIpForDownload()
    window.open(isAndroid ? urls.PLAY_STORE : urls.APP_STORE, '_blank')
    onRequestClose()
  }

  return (
    <>
      {isAndroid ? (
        <StaticImage
          src="./images/phones--android@2x.jpg"
          alt="Bold app for Android"
          layout="fullWidth"
          placeholder="blurred"
          width={360}
        />
      ) : (
        <StaticImage
          src="./images/phones--ios@2x.jpg"
          alt="Bold app for iOS"
          layout="fullWidth"
          placeholder="blurred"
          width={360}
        />
      )}
      <ModalBox align="left">
        <Text element="p">
          {`Use Bold on the ${isAndroid ? 'Android' : 'iOS'} mobile app. Take classes anytime, anywhere.`}
        </Text>

        <Button full onClick={handleDownloadClick}>
          Download now
        </Button>
      </ModalBox>
    </>
  )
}
