import type { PageProps } from 'gatsby'
import React from 'react'
import HomeSpecial from 'components/landing/HomeSpecial'
import HomeSpecialLayout from 'components/landing/HomeSpecialLayout'

type Props = PageProps

export default function HomeSpecialBalance(props: Props) {
  return (
    <HomeSpecialLayout {...props} className="HomeSpecialBalance">
      <HomeSpecial
        title="Improve your balance for free*"
        subtext="Bold’s free* balance programs are shown to reduce fall risk by more than 40%. Take your first class today."
        disclaimer="*Free if covered by your insurance"
      />
    </HomeSpecialLayout>
  )
}
