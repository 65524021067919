import React from 'react'
import Button from 'components/Button'
import paths from 'constants/paths'
import * as exploreApiRequest from 'libs/explore-api-request'
import ExploreLessons from '../ExploreLessons'

export default function ExploreLessonsNew() {
  const [data, setData] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(true)

  React.useEffect(() => {
    async function getData() {
      const response = await exploreApiRequest.getLessons({
        isOnDemandClass: 'true',
      })

      setIsLoading(false)
      setData(response.data)
    }
    getData()
  }, [])

  return (
    <ExploreLessons
      title="Newly released studio classes"
      action={<Button to={`${paths.EXPLORE_LESSONS}?preset=isOnDemandClass`} />}
      data={data}
      loading={isLoading}
      className="ExploreLessonsNew"
    />
  )
}
