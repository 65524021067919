import classNames from 'classnames'
import React from 'react'
import Container from 'components/Container'
import Copy from 'components/Copy'
import Image from 'components/Image'
import Row from 'components/Row'
import Text from 'components/Text'
import type { Component } from 'constants/types'
import styles from './Hero.module.scss'
import { ReactComponent as LinesSmallSvg } from './images/lines---small.svg'
import { ReactComponent as LinesXSmallSvg } from './images/lines---xsmall.svg'
import { ReactComponent as LinesSvg } from './images/lines.svg'

type Props = {
  copy?: React.ReactNode
  image: string
  image2x: string
  imageSmall: string
  imageSmall2x: string
  small?: string
  title?: string
} & Component

export default function Hero({
  className,
  copy,
  image,
  image2x,
  imageSmall,
  imageSmall2x,
  small,
  title,
}: Props) {
  return (
    <header className={classNames('Hero', styles.this, className)}>
      <div className={styles.inner}>
        <Image
          src={imageSmall}
          src2x={imageSmall2x}
          full
          className={classNames(styles.image, styles['image---small'])}
        />
        <Image
          src={image}
          src2x={image2x}
          full
          className={classNames(styles.image, styles['image---large'])}
        />
        <Text color="white" element="div" className={styles.content}>
          <Container size="xxlarge">
            <Container align="left" flush size="medium" className={styles.container}>
              {small && (
                <Row size="large">
                  <Text casing="upper" size="large" weight="bold">
                    {small}
                  </Text>
                </Row>
              )}
              <Row flush={!copy} size="small">
                <Text element="h1" size="xxxxlarge" weight="bold" className={styles.title}>
                  {title}
                </Text>
              </Row>
              {copy && (
                <Copy size="xxlarge" className={styles.copy}>
                  {copy}
                </Copy>
              )}
            </Container>
          </Container>
        </Text>
      </div>
      <LinesSvg className={classNames(styles.lines, styles['lines---large'])} />
      <LinesSmallSvg className={classNames(styles.lines, styles['lines---small'])} />
      <LinesXSmallSvg className={classNames(styles.lines, styles['lines---xsmall'])} />
    </header>
  )
}
