import { useLocation } from '@reach/router'
import { differenceInMinutes, differenceInSeconds, formatDistance } from 'date-fns'
import { navigate } from 'gatsby'
import _ from 'lodash'
import React from 'react'
import { useInterval } from 'react-use'
import Box from 'components/Box'
import Button from 'components/Button'
import Container from 'components/Container'
import HeaderSubheader from 'components/HeaderSubheader'
import LessonTrainer from 'components/LessonTrainer'
import Loader from 'components/Loader'
import MediaObject from 'components/MediaObject'
import Modal from 'components/Modal'
import Row from 'components/Row'
import Text from 'components/Text'
import * as events from 'constants/events'
import paths from 'constants/paths'
import { useGlobalContext } from 'contexts/GlobalContext'
import PostLesson from 'features/lesson/components/PostLesson'
import LiveLessonTag from 'features/live-lesson/components/LiveLessonTag'
import type { LessonStream } from 'libs/lesson'
import * as lessonApiRequest from 'libs/lesson-api-request'
import * as url from 'libs/url'
import styles from './LiveRestreamLesson.module.scss'
import LiveRestreamLessonVideo from './components/LiveRestreamLessonVideo'

type Props = {
  lessonStreamId: string
}

const SCHEDULED_LESSON_COUNT_INITIAL = 2

export default function LiveRestreamLesson({ lessonStreamId }: Props) {
  const globalContext = useGlobalContext()
  const location = useLocation()
  const [data, setData] = React.useState<LessonStream | null>(null)
  const [isPast, setIsPast] = React.useState(false)
  const [now, setNow] = React.useState(new Date())
  const [scheduledLessonCount, setScheduledLessonCount] = React.useState(
    SCHEDULED_LESSON_COUNT_INITIAL
  )
  const [showPostLessonModal, setShowPostLessonModal] = React.useState(false)

  const ONE_SECOND = 1000
  useInterval(() => setNow(new Date()), ONE_SECOND * 15)

  React.useEffect(() => {
    const { source } = url.getQueryString()

    async function getData() {
      const response = await lessonApiRequest.getLessonStreamDetails(lessonStreamId)
      const lessonStream = response.data
      if (!lessonStream) {
        navigate(paths.LIVE_LESSONS, { replace: true })
        return
      }

      setData(lessonStream)

      const lessonData = lessonStream.lesson

      // TODO: put scheduled lesson counts back but right now just randomly generate a number between 30 and 60
      // if (scheduledLessonCountData > SCHEDULED_LESSON_COUNT_INITIAL) {
      //   setScheduledLessonCount(scheduledLessonCountData)
      // }
      setScheduledLessonCount(Math.floor(Math.random() * 30 + 30))

      globalContext.analytics?.trackEvent(events.OPEN_LESSON, {
        classMedium: lessonData.classMedium,
        classType1: lessonData.classType?.[0],
        classType2: lessonData.classType?.[1],
        from: location?.state?.from,
        isCollectionLesson: location?.state?.from?.includes('playlist'),
        isLiveRestream: true,
        isProgramLesson: !!lessonData.exerciseProgramId,
        lessonId: lessonData.id,
        lessonIsPast:
          differenceInMinutes(now, new Date(lessonStream.scheduledStartTs)) > lessonData.duration,
        lessonIsStarted: differenceInSeconds(now, new Date(lessonStream.scheduledStartTs)) > 0,
        lessonName: lessonData.videoTitle,
        lessonStreamId: lessonStream.id,
        // TODO: PUT BACK
        // scheduledLessonCount,
        source,
        trainerName: lessonData.instructorName,
      })
    }
    getData()

    globalContext.update({ from: location.pathname })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalContext.analytics, lessonStreamId, location])

  function handleOnEnd() {
    setShowPostLessonModal(true)
  }

  function handleOnClose() {
    setShowPostLessonModal(false)
    setIsPast(true)
  }

  if (_.isEmpty(data)) {
    return <Loader overlay={false} />
  }

  const {
    lesson: { instructions, videoLink, videoThumbnail, videoTitle },
    scheduledStartTs,
  } = data

  const classTimeDifferenceInSeconds = differenceInSeconds(now, new Date(scheduledStartTs))
  const classTimeDifferenceInMinutes = differenceInMinutes(now, new Date(scheduledStartTs))
  const isStarted = classTimeDifferenceInSeconds > 0

  return (
    <div className="LiveRestreamLesson">
      <HeaderSubheader shadow={false}>
        <div className={styles['header--inner']}>
          <Text element="h1" size="large" weight="bold">
            {videoTitle} rerun
          </Text>
          <Text weight="bold" className={styles.schedulees}>
            <div className={styles['schedulees--avatars']}>
              {_.sampleSize(['SW', 'DA', 'AQ', 'JC', 'ET', 'HA', 'LF', 'CL', 'JD'], 3).map(
                (item) => (
                  <Text
                    color="white"
                    size="xsmall"
                    key={item}
                    weight="bold"
                    className={styles['schedulees--avatars--item']}>
                    {item}
                  </Text>
                )
              )}
            </div>
            & {scheduledLessonCount} others{' '}
            {isStarted ? (isPast ? 'watched' : 'are watching') : 'have scheduled'} this class
          </Text>
        </div>
      </HeaderSubheader>
      <div className={styles['video-wrap']}>
        <LiveRestreamLessonVideo
          classTimeDifferenceInSeconds={classTimeDifferenceInSeconds}
          data={data}
          thumbnail={videoThumbnail}
          url={videoLink}
          className={styles.video}
          onEnd={handleOnEnd}
        />
        {!isStarted && (
          <div className={styles['video-overlay']}>
            <Container flush size="xmedium">
              <Box color="white" className={styles.box}>
                <Row size="small">
                  <Row size="xxsmall">
                    <MediaObject figure={<LiveLessonTag data={data} />} figurePosition="right">
                      <Row size="xxsmall">
                        <Text element="h2" size="large" weight="bold">
                          About this session
                        </Text>
                      </Row>
                      <LessonTrainer data={data.lesson} />
                    </MediaObject>
                  </Row>
                  <Text element="p">{instructions}</Text>
                </Row>
                <Text element="p" flush size="large" weight="bold">
                  Class available in{' '}
                  {Math.abs(classTimeDifferenceInSeconds) < 60 * 60
                    ? `${Math.abs(classTimeDifferenceInMinutes)} minutes and ${Math.abs(
                        classTimeDifferenceInSeconds - classTimeDifferenceInMinutes * 60
                      )} seconds`
                    : ` ${formatDistance(new Date(scheduledStartTs), now)}`}
                  ...
                </Text>
              </Box>
            </Container>
          </div>
        )}
        {isPast && (
          <div className={styles['video-overlay']}>
            <Container flush size="xmedium">
              <Box color="white" className={styles.box}>
                <Row size="small">
                  <Text color="purple" size="large" weight="bold" element="p">
                    This class has finished
                  </Text>
                </Row>
                <Text weight="bold" element="p" size="large">
                  Want to schedule another live class rerun?
                </Text>
                <Button
                  color="yellow"
                  full
                  size="xlarge"
                  onClick={() => navigate(paths.LIVE_LESSONS)}>
                  View live class schedule
                </Button>
              </Box>
            </Container>
          </div>
        )}
      </div>
      <Modal isOpen={showPostLessonModal} onRequestClose={handleOnClose}>
        <PostLesson
          lesson={data}
          lessonStreamId={data.id}
          onSuccess={() => {
            setShowPostLessonModal(false)
          }}
        />
      </Modal>
    </div>
  )
}
