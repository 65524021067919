import _ from 'lodash'
import React from 'react'
import Media from 'react-media'
import Box from 'components/Box'
import Copy from 'components/Copy'
import Loader from 'components/Loader'
import MediaObject from 'components/MediaObject'
import Row from 'components/Row'
import Tag from 'components/Tag'
import Text from 'components/Text'
import * as events from 'constants/events'
import { useGlobalContext } from 'contexts/GlobalContext'
import SubscriptionPlanPrice from 'features/user/components/SubscriptionPlanPrice'
import * as subscriptionApiRequest from 'libs/subscription-api-request'
import * as subscriptions from 'libs/subscriptions'
import constants from 'styles/constants.module.scss'
import styles from './SubscriptionPlans.module.scss'
import { ReactComponent as CheckmarkCircleSvg } from './images/checkmark---circle.svg'

type Props = {
  defaultSelected?: string
  hidePremiumFeaturesBox?: boolean
  isPlanFreeTrialEligible?: boolean
  onSelect?: () => void
}

export default function SubscriptionPlans({
  defaultSelected = subscriptions.PLANS.year,
  hidePremiumFeaturesBox,
  isPlanFreeTrialEligible = true,
  onSelect,
}: Props) {
  const globalContext = useGlobalContext()
  const [plan, setPlan] = React.useState({})
  const [plans, setPlans] = React.useState([])

  React.useEffect(() => {
    if (_.isEmpty(plan)) return
    globalContext.update({ selectedPlan: plan })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plan])

  // TODO
  // eslint-disable-next-line react-hooks/exhaustive-deps
  function selectPlan(planLocal) {
    const planFromApi = plans.find((item) => item.id === planLocal.id)
    setPlan({
      ...planFromApi,
      ...planLocal,
    })
  }

  React.useEffect(() => {
    if (_.isEmpty(plans)) return
    selectPlan(defaultSelected)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plans])

  React.useEffect(() => {
    async function getPlans() {
      const response = await subscriptionApiRequest.getPlans()
      setPlans(response.data.plans)
    }
    getPlans()
  }, [])

  function handleSelect(planNew: { name: string; amount: number; id: string; interval: string }) {
    selectPlan(planNew)
    if (onSelect) onSelect(planNew)
    globalContext.analytics?.trackEvent(events.SELECT_SUBSCRIPTION_PLAN_INTERVAL, {
      selectedPlanName: planNew.name,
      amount_to_be_paid:
        subscriptions.calculateAmountInCentsWithCoupon(planNew.amount, globalContext.coupon) / 100,
      coupon: globalContext.coupon?.name,
      discount: globalContext.coupon?.percent_off
        ? `${globalContext.coupon?.percent_off}% off`
        : `$${globalContext.coupon?.amount_off} off`,
      srcId: globalContext.user?.srcId,
    })
  }

  const plansPremium = _.pickBy(subscriptions.PLANS, (item) => item.amount !== 0)

  const features = [
    'Functional fitness assessments',
    'Access private Bold community',
    'Live and on-demand classes',
    'Personalized exercise program',
  ]

  const percentageOffFigure = (planKey: string) => {
    if (planKey === 'year') {
      return (
        <Tag color="green" size="xsmall" wrap={false}>
          Save{' '}
          {Math.round(
            100 - (subscriptions.PLANS.year.amount / (subscriptions.PLANS.month.amount * 12)) * 100
          )}
          %
        </Tag>
      )
    }
    if (planKey === 'biAnnual') {
      return (
        <Tag color="green" size="xsmall" wrap={false}>
          Save{' '}
          {Math.round(
            100 -
              (subscriptions.PLANS.biAnnual.amount / (subscriptions.PLANS.month.amount * 6)) * 100
          )}
          %
        </Tag>
      )
    }
  }

  return (
    <div className="SubscriptionPlans">
      {!hidePremiumFeaturesBox && (
        <Row size="small">
          <Box
            adornment={<CheckmarkCircleSvg className={styles.checkmark} />}
            adornmentPosition="right"
            color="purple100"
            weight="bold">
            <Row size="small">
              <Text element="h3">Bold Premium</Text>
            </Row>
            <Copy columns={2} element="ul" className={styles.list}>
              {features.map((item) => (
                <Text element="li" key={item}>
                  {item}
                </Text>
              ))}
            </Copy>
          </Box>
        </Row>
      )}
      {_.isEmpty(plans) ? (
        <Loader overlay={false} />
      ) : (
        <div className={styles.plans}>
          {Object.keys(plansPremium).map((key, index) => {
            const planLocal = plansPremium[key]

            return (
              <Row flush={index === _.size(plansPremium) - 1} key={planLocal.key} size="small">
                <Box
                  size="small"
                  tabIndex="0"
                  className={styles['plans--item']}
                  onClick={() => handleSelect(planLocal)}>
                  <MediaObject // PRICE
                    figure={
                      <SubscriptionPlanPrice
                        data={planLocal.amount / 100}
                        plan={planLocal}
                        size="inherit"
                        weight="bold"
                        subText={
                          <div className={styles.priceBox}>
                            <Text align="right" element="p" flush>
                              Billed {planLocal.billingFrequency}
                            </Text>
                            <Media query={{ maxWidth: constants.MOBILE }}>
                              <div className={styles.percentOff}>
                                {percentageOffFigure(planLocal.key)}
                              </div>
                            </Media>
                          </div>
                        }
                      />
                    }
                    center
                    figurePosition="right">
                    <MediaObject // CHECKMARK
                      figure={
                        planLocal.id === plan.id ? (
                          <CheckmarkCircleSvg
                            aria-label="Selected plan"
                            className={styles['plans--item--checkmark']}
                          />
                        ) : (
                          <div className={styles['plans--item--indicator']} />
                        )
                      }
                      center
                      size="xsmall">
                      <MediaObject // PERCENT
                        figure={
                          <Media query={{ minWidth: constants.GT_MOBILE }}>
                            {percentageOffFigure(planLocal.key)}
                          </Media>
                        }
                        center
                        figurePosition="right"
                        inline
                        size="small">
                        <Text element="p" flush weight="bold">
                          {planLocal.name}
                        </Text>
                        {isPlanFreeTrialEligible && (
                          <Text element="p" flush>
                            {planLocal.freeTrialDays === 0
                              ? 'No free trial'
                              : `${planLocal.freeTrialDays} day free trial`}
                          </Text>
                        )}
                      </MediaObject>
                    </MediaObject>
                  </MediaObject>
                </Box>
              </Row>
            )
          })}
        </div>
      )}
    </div>
  )
}
