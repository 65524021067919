import classNames from 'classnames'
import React from 'react'
import ButtonWithModal, { type ButtonWithModalProps } from 'components/ButtonWithModal'
import * as events from 'constants/events'
import { useGlobalContext } from 'contexts/GlobalContext'
import PostLessonFeedback from 'features/lesson/components/PostLessonFeedback'
import * as checkinApiRequest from 'libs/checkin-api-request'
import type { Lesson } from 'libs/lesson'
import * as notifications from 'libs/notifications'
import CheckinTracker from 'libs/checkins/checkin-tracker'
import { ReactComponent as SpeechBubbleSvg } from './images/speech-bubble.svg'

type Props = {
  children?: React.ReactNode
  icon?: React.ReactElement
  lesson: Lesson
  onFeedbackSuccess?: () => void
} & ButtonWithModalProps

export default function LessonFeedbackButton({
  children = 'After class feedback',
  className,
  icon = <SpeechBubbleSvg />,
  lesson,
  onFeedbackSuccess,
  ...props
}: Props) {
  const globalContext = useGlobalContext()
  const [checkinTracker, setCheckinTracker] = React.useState()
  const [showFeedbackModal, setShowFeedbackModal] = React.useState()
  const lessonId = lesson ? lesson.id : globalContext.lesson?.id

  React.useEffect(() => {
    async function getCheckin() {
      const afterClassCheckinResponse = await checkinApiRequest
        .getUserCheckin({
          type: 'after_class',
          activeOnly: true,
          createIfNotExists: true,
          objectId: lessonId,
        })
        .catch((error) => {
          globalContext.analytics?.trackEvent(events.FAILED_CHECKIN_CALL, {
            error,
            from: 'lesson feedback button',
            type: 'after_class',
          })
          notifications.notifyError()
        })

      const afterClassCheckin = afterClassCheckinResponse.data

      if (!afterClassCheckin) return

      setCheckinTracker(
        new CheckinTracker(globalContext.user?.id, afterClassCheckin, globalContext.analytics)
      )
    }
    getCheckin()
  }, [globalContext.user?.id, lessonId, globalContext.analytics])

  if (!checkinTracker) return null

  return (
    <ButtonWithModal
      {...props}
      icon={icon}
      showModal={showFeedbackModal}
      modal={
        <PostLessonFeedback
          checkinTracker={checkinTracker}
          onSuccess={() => {
            setShowFeedbackModal(false)
            if (onFeedbackSuccess) onFeedbackSuccess()
          }}
        />
      }
      className={classNames('LessonFeedbackButton', className)}>
      {children}
    </ButtonWithModal>
  )
}
