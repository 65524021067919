import { StaticImage } from 'gatsby-plugin-image'
import _ from 'lodash'
import React from 'react'
import Box from 'components/Box'
import Button from 'components/Button'
import Container from 'components/Container'
import Copy from 'components/Copy'
import Heading2 from 'components/Heading2'
import Link from 'components/Link'
import Row from 'components/Row'
import Section from 'components/Section'
import Text from 'components/Text'
import Title4 from 'components/Title4'
import paths from 'constants/paths'
import useEventPageVisited from 'hooks/use-event-page-visited'
import styles from './GiftBold.module.scss'

export default function GiftBold() {
  useEventPageVisited('Gift-Bold')
  const valueProps = [
    {
      title: 'Personalized programs',
      copy: 'Every Bold member receives an exercise program that accounts for their health history led by expert trainers passionate about supporting older adults',
    },
    {
      title: 'A bottomless content library',
      copy: 'Members have access to hundreds of live and on-demand exercise classes and educational resources to support their healthy aging journeys.',
    },
    {
      title: 'Engagement leads to outcomes',
      copy: 'The average member takes 3 classes per week. Bold drives a more than 40% reduction in fall-related hospitalizations and more than 80% of members see an improvement in their mental health.',
    },
    {
      title: 'Built-in motivation and accountability',
      copy: 'Bold offers easy-to-use tools, reminders, and nudges to start and stick with an exercise routine, and see progress month after month and year after year.',
    },
  ]

  const faqs = [
    {
      title: 'What will my friend or loved one receive from Bold?',
      copy: 'Bold will send a unique signup link to the email address you provide that allows the recipient to create a Bold Premium account at no personal cost, with access up to a year — the same experience available to more than 10 million Medicare members through one of our partnerships.',
    },
    {
      title: 'What types of organizations work with Bold?',
      copy: 'Bold partners with Medicare Advantage plans, accountable-care organizations and other risk-bearing organizations to reduce falls and hospitalizations, increase quality measures, and support member retention. If you’re interested in getting more out of your fitness benefit, or driving tangible outcomes with high-cost populations through an efficient, engaging intervention that members love, talk to one of our team members here at the booth.',
    },
  ]

  return (
    <div className="GiftBold">
      <header className={styles.hero}>
        <Container size="xxlarge">
          <div className={styles['hero--inner']}>
            <Container align="left" flush size="xmedium">
              <div className={styles['hero--copy']}>
                <Row size="xmedium">
                  <Text element="h1" weight="bold" className={styles.title}>
                    Give the Gift of Movement
                  </Text>
                </Row>
                <Row size="xmedium">
                  <Text element="p" flush size="large">
                    Bold helps overcome barriers to movement for older adults, offering personalized
                    exercise programs, education, and more to support Medicare members as they age.
                    Our platform helps members manage their chronic conditions, improve their
                    emotional well-being, and build strength, balance, and mobility.
                  </Text>
                </Row>
                <Row size="xmedium">
                  <Text element="p" flush size="large">
                    As a conference attendee, you can give the gift of a 1-year subscription to Bold
                    Premium to a friend, colleague, or loved one. Simply fill out the form below.
                  </Text>
                </Row>
                <Row size="smallx">
                  <Button to="https://forms.gle/5FhBDqa4wxwZiLCD9" full>
                    Share Bold with someone you know
                  </Button>
                </Row>
              </div>
            </Container>
            <StaticImage
              src="./images/phones@2x.png"
              alt="Bold app"
              layout="fullWidth"
              placeholder="blurred"
              width={464}
              className={styles['hero--phones']}
            />
          </div>
        </Container>
      </header>
      <Section color="purple">
        <Container size="xxlarge">
          <Title4 size="small">The Bold difference</Title4>
          <div className={styles.boxes}>
            {valueProps.map((item) => (
              <Text element="figure" key={item.title} className={styles.box}>
                <Heading2 level={3} levelStyle={4}>
                  <Text weight="bold">{item.title}</Text>
                </Heading2>
                <Text element="p" flush>
                  {item.copy}
                </Text>
              </Text>
            ))}
          </div>
        </Container>
      </Section>
      <Section>
        <Container size="large">
          <Title4 size="small">Frequently asked questions</Title4>
          {faqs.map((item) => (
            <Row key={item.title} size="xmedium">
              <Box color="white">
                <Heading2 level={3} levelStyle={4}>
                  <Text weight="bold">{item.title}</Text>
                </Heading2>
                <Copy element={_.isString(item.copy) ? 'p' : undefined} flush>
                  {item.copy}
                </Copy>
              </Box>
            </Row>
          ))}
        </Container>
      </Section>
      <Section>
        <Container size="large">
          <Text size="large" textStyle="italic">
            {'Want to learn more about Bold’s clinical exercise platform? '}
            <Link to={paths.REQUEST_DEMO} target="_blank">
              <Text element="u">Book time with us.</Text>
            </Link>
          </Text>
        </Container>
      </Section>
    </div>
  )
}
