import React from 'react'
import * as exploreApiRequest from 'libs/explore-api-request'
import DashboardLessons from '../DashboardLessons'

export default function DashboardLessonsRecommended() {
  const [data, setData] = React.useState([{}])
  const [isLoading, setIsLoading] = React.useState(true)

  React.useEffect(() => {
    async function getData() {
      const response = await exploreApiRequest.getLessons({
        source: 'dashboard',
      })

      if (response.statusCode !== 200) return

      setIsLoading(false)
      setData(response.data)
    }
    getData()
  }, [])

  return (
    <DashboardLessons
      title="Recommended for you"
      data={data}
      loading={isLoading}
      className="DashboardLessonsRecommended"
    />
  )
}
