import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import Copy from 'components/Copy'
import Row from 'components/Row'
import Text from 'components/Text'
import type { Component } from 'constants/types'
import styles from './Title3.module.scss'

type Props = {
  actions: React.ReactNode
  align?: 'left' | 'center' | 'right'
  children: string
  flush?: boolean
  size?: 'large' | 'medium'
  small?: string
  subtext: string
} & Component

export default function Title3({
  actions,
  align = 'center',
  children,
  className,
  flush,
  size = 'large',
  small,
  subtext,
  ...props
}: Props) {
  return (
    <Text
      {...props}
      align={align}
      element="div"
      className={classNames('Title3', styles.this, flush && styles['this---flush'], className)}>
      {small && (
        <Row size="small">
          <Text casing="upper" color="purple" element="h2" weight="bold">
            {small}
          </Text>
        </Row>
      )}
      <Row size="small">
        <Text
          element="h2"
          size={size === 'medium' ? 'xxlarge' : 'xxxlarge'}
          weight="bold"
          className={styles.title}>
          {children}
        </Text>
      </Row>
      <Row flush={!actions}>
        <Copy
          element={_.isString(subtext) ? 'p' : undefined}
          size={size === 'medium' ? undefined : 'large'}>
          {subtext}
        </Copy>
      </Row>
      {actions && <div>{actions}</div>}
    </Text>
  )
}
