import classNames from 'classnames'
import React from 'react'
import Text, { type TextProps } from 'components/Text'
import TextWithIcon from 'components/TextWithIcon'
import styles from './ListNumbers.module.scss'

type Props = {
  connected?: boolean
  data: React.ReactNode[]
} & TextProps

export default function ListNumbers({ className, connected, data, ...props }: Props) {
  return (
    <Text
      {...props}
      copy
      element="ol"
      className={classNames(
        'ListNumbers',
        styles.this,
        connected && styles['this---connected'],
        className
      )}>
      {data.filter(Boolean).map((item, index) => (
        <li key={index} className={styles.item}>
          <TextWithIcon
            icon={
              <Text color="white" size="medium" weight="bold" className={styles['item--number']}>
                {++index}
              </Text>
            }
            gutterSize="xsmall">
            {item}
          </TextWithIcon>
        </li>
      ))}
    </Text>
  )
}
