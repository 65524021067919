import type { PageProps } from 'gatsby'
import React from 'react'
import EnterpriseFitnessLanding from 'features/enterprise/fitness/components/EnterpriseFitnessLanding'
import * as enterprise from 'libs/enterprise'
import { ReactComponent as LogosSvg } from './images/logos.svg'

type Props = PageProps

export default function EnterpriseReef(props: Props) {
  return (
    <EnterpriseFitnessLanding
      {...props}
      hideNavBottomMobile
      logos={LogosSvg}
      partner={enterprise.REEF_KEY}
      className="EnterpriseReef"
    />
  )
}
