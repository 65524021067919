//import _ from 'lodash'
import classNames from 'classnames'
import React from 'react'
import Text from 'components/Text'
import type { Component } from 'constants/types'
import styles from './Title2.module.scss'

type Props = {
  children: React.ReactNode
  flush?: boolean
  size?: 'large'
  small: string
} & Component

export default function Title2({ children, className, flush, size, small }: Props) {
  return (
    <Text
      casing="upper"
      color="purple"
      element="header"
      weight="bold"
      className={classNames(
        'Title2',
        styles.this,
        flush && styles['this---flush'],
        size && styles[`this---${size}`],
        className
      )}>
      <Text element="h2">
        <Text
          casing="upper"
          color="default"
          element="div"
          size="large"
          weight="bold"
          className={styles.small}>
          {small}
        </Text>
        {children}
      </Text>
    </Text>
  )
}
