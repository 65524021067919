import { navigate } from 'gatsby'
import React from 'react'
import paths from 'constants/paths'
import { useGlobalContext } from 'contexts/GlobalContext'
import useEventPageVisited from 'hooks/use-event-page-visited'
import type { Lesson } from 'libs/lesson'
import * as navigation from 'libs/navigation'
import {
  useActiveProgramProgress,
  useLessonsForProgramStatic,
  useProgram,
  useProgramCurrentWeek,
} from 'libs/program-query'
import ProgramBlocks from './components/ProgramBlocks'
import ProgramHeader from './components/ProgramHeader'
import ProgramOverview from './components/ProgramOverview'
import ProgramProgressionModals from './components/ProgramProgressionModals'
import ProgramLayout from '../ProgramLayout'

export default function ProgramStatic() {
  useEventPageVisited('StaticProgram')
  const globalContext = useGlobalContext()
  const { data: programProgressData, mutate: mutateProgramProgress } = useActiveProgramProgress()
  const programProgress = programProgressData?.data
  const { data: programData } = useProgram({
    revalidateOnFocus: false,
  })
  const program = programData?.data
  const exerciseProgramId = program?.exerciseProgram?.id
  const { mutate: mutateProgramLessons } = useLessonsForProgramStatic(exerciseProgramId, {
    dependencies: [programData],
    revalidateOnFocus: false,
  })
  const { mutate: mutateProgramCurrentWeek } = useProgramCurrentWeek()
  const [lessonOverride, setLessonOverride] = React.useState<Lesson | undefined>()
  const [onSkip, setOnSkip] = React.useState({ skipped: false })
  const [playSkipped, setPlaySkipped] = React.useState({ skipped: false })
  const [postLessonState, setPostLessonState] = React.useState<boolean | undefined>()
  const [showProgramProgressionModal, setShowProgramProgressionModal] = React.useState(false)

  React.useEffect(() => {
    if (programData?.statusCode === 422) {
      navigate(paths.PROGRAM_BUILD, { replace: true })
    }
    // mutateProgramProgress()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programData])

  React.useEffect(() => {
    if (programProgress?.percentComplete === 100 && !globalContext.isVideoPlaying) {
      setShowProgramProgressionModal(true)
    } else {
      setShowProgramProgressionModal(false)
    }
  }, [globalContext.isVideoPlaying, globalContext.user, programProgress])

  // this is so sus
  React.useEffect(() => {
    mutateProgramLessons()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalContext.user?.id, program])

  function handleLessonOverride() {
    setLessonOverride(null)
  }

  function handleLessonClick(lesson: any) {
    setLessonOverride(lesson)
    setPostLessonState(false)
    navigation.scrollToTop()
  }

  const handleLessonSkipped = () => {
    setOnSkip({ skipped: true })
  }

  const handlePlaySkippedLesson = () => {
    setPlaySkipped({ skipped: true })
  }

  async function handleVideoEnd() {
    setLessonOverride(null)
    await mutateProgramProgress()
    await mutateProgramCurrentWeek()
  }

  function handlePostLessonStateChange(lessonState: boolean) {
    setPostLessonState(lessonState)
  }

  function handleChangeProgramClose() {
    setShowProgramProgressionModal(false)
  }

  return (
    <ProgramLayout
      header={
        <ProgramHeader
          lessonOverride={lessonOverride}
          onLessonClick={handleLessonClick}
          onLessonOverride={handleLessonOverride}
          onLessonSkipped={handleLessonSkipped}
          onPlaySkippedLesson={handlePlaySkippedLesson}
          onPostLessonStateChange={handlePostLessonStateChange}
          onVideoEnd={handleVideoEnd}
        />
      }
      overview={<ProgramOverview />}
      blocks={
        <ProgramBlocks
          lessonOverride={lessonOverride}
          playSkippedLesson={playSkipped}
          postLessonState={postLessonState}
          program={program}
          onLessonClick={handleLessonClick}
          onLessonSkipped={onSkip}
        />
      }
      loading={!program}
      className="ProgramStatic">
      {showProgramProgressionModal && (
        <ProgramProgressionModals
          numClassesCompleted={programProgress.numClassesCompleted}
          program={program}
          onClose={handleChangeProgramClose}
        />
      )}
    </ProgramLayout>
  )
}
