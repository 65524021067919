import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react'
import classNames from 'classnames'
import { navigate, type PageProps } from 'gatsby'
import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import Head from 'components/Head'
import Layout from 'components/Layout'
import experiments from 'constants/experiments'
import paths from 'constants/paths'
import storage from 'constants/storage'
import { Component } from 'constants/types'
import * as cookie from 'libs/cookie'
import * as environment from 'libs/environment'
import * as segment from 'libs/segment'
import * as subscriptions from 'libs/subscriptions'

let visitorId = cookie.getCookie(storage.VISITOR_ID_KEY)

if (!visitorId) {
  visitorId = uuidv4()
  cookie.createCookie(storage.VISITOR_ID_KEY, visitorId)
}

const growthbook = new GrowthBook({
  ...experiments.GROWTHBOOK_SETTINGS,
  trackingCallback: (experiment, result) => {
    segment.track('Experiment Viewed', {
      'Experiment Id': experiment.key,
      'page': 'Home Special',
      'Variant Id': result.variationId,
      'Variation Value': result.value,
      'visitorId': visitorId,
      '$source': 'growthbook',
    })
  },
})
growthbook.init({ timeout: 2000 })

type Props = {
  children: React.ReactNode
} & Component &
  PageProps

export default function HomeSpecialLayout({ children, className, location }: Props) {
  React.useEffect(() => {
    cookie.createCookie(storage.HOME_KEY, storage.HOME_SPECIAL)
    cookie.createCookie(storage.PROMO_CODE_KEY, subscriptions.PROMO_CODES._2020)

    growthbook.setAttributes({
      ...growthbook.getAttributes(), // preserve existing attributes
      id: visitorId, // todo(ideally we could reuse segment's anonymousId),
      isProductionEnv: environment.isProduction,
      url: window.location.href,
    })

    // example fetching gb value
    // const featureFlagVal = growthbook.getFeatureValue('test-sophia', 'defaultVal')
  }, [])

  React.useEffect(() => {
    // globalContext.isAuthed isn't ready on mount
    // and adding to dep causes redirect away soon as they auth
    if (cookie.getCookie(storage.REFRESH_TOKEN_KEY)) {
      navigate(paths.HOME_AUTHED, { replace: true })
    } else if (location.pathname === paths.HOME_SPECIAL) {
      navigate(paths.getHomePath(), { replace: true })
    }
  }, [location.pathname])

  return (
    <GrowthBookProvider growthbook={growthbook}>
      <Layout className={classNames('HomeSpecial', className)}>
        <Head
          bodyAttributes={{ class: 'body---hide-header' }}
          title="Age Bold – Variety of online fitness classes for older adults"
          titleOnly>
          <script id="facebook-pixel-script">
            {`
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '${environment.isProduction ? '734846830278663' : '670882820054870'}');
            fbq('track', 'PageView');
            `}
          </script>
        </Head>
        {children}
      </Layout>
    </GrowthBookProvider>
  )
}
