import React from 'react'
import Box from 'components/Box'
import Text from 'components/Text'
import AdminLessonRegimenUpdateModal from 'features/admin/components/AdminLessonRegimenUpdateModal'

type Props = {
  lessonRegimen: () => void
}

export default function AdminLessonRegimensItem({
  lessonRegimen,
  onUpdate: handleUpdate,
  dayOfWeek,
}: Props) {
  const [showEditModal, setShowEditModal] = React.useState(false)
  const { title, instructorName, liveSchedule, rerunSchedule, isActive, type } = lessonRegimen

  const displayTimes = []
  if (type === 'Live') {
    liveSchedule.forEach((live) => {
      if (live[0].includes(dayOfWeek)) {
        displayTimes.push(`${live[0]} @ ${live[1]}`)
      }
    })
  } else {
    rerunSchedule.forEach((rerun) => {
      if (rerun[0].includes(dayOfWeek)) {
        displayTimes.push(`${rerun[0]} @ ${rerun[1]}`)
      }
    })
  }
  const handleOpenModal = () => {
    setShowEditModal(true)
  }

  const handleCloseModal = () => {
    setShowEditModal(false)
    if (handleUpdate) handleUpdate()
  }

  const color = isActive ? (type === 'Live' ? 'purple-dark' : 'purple') : 'grey'

  return (
    <>
      <Box color={color} onClick={handleOpenModal}>
        <Text element="h3" weight="bold">
          {isActive ? `ACTIVE ${type}` : `inactive ${type}`}
        </Text>
        <br />
        <Text element="h3" size="xlarge" weight="bold">
          {title}
        </Text>
        <br />
        <Text element="h3" weight="bold">
          {instructorName}
        </Text>
        <br />
        <Text element="h3">{displayTimes.join(', ')}</Text>
      </Box>
      <AdminLessonRegimenUpdateModal
        isOpen={showEditModal}
        propsLessonRegimen={lessonRegimen}
        onClose={handleCloseModal}
        onUpdate={handleUpdate}
      />
    </>
  )
}
