import type { PageProps } from 'gatsby'
import React from 'react'
import HomeSpecial from 'components/landing/HomeSpecial'
import HomeSpecialLayout from 'components/landing/HomeSpecialLayout'

type Props = PageProps

export default function HomeSpecialLiveLessons(props: Props) {
  return (
    <HomeSpecialLayout {...props} className="HomeSpecialLiveLessons">
      <HomeSpecial
        title="Free* live exercise classes"
        subtext="Get instant access to hundreds of live and on-demand classes that fit your needs and schedule."
        disclaimer="*Free if covered by your insurance"
      />
    </HomeSpecialLayout>
  )
}
