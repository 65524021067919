import classNames from 'classnames'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import Box from 'components/Box'
import ModalConfirm, { type ModalConfirmProps } from 'components/ModalConfirm'
import Row from 'components/Row'
import Text from 'components/Text'
import { useGlobalContext } from 'contexts/GlobalContext'
import * as challengeApiRequest from 'libs/challenge-api-request'
import { useActiveChallenges, useUserChallenges } from 'libs/challenge-query'
import styles from './EnterpriseClinicalChallengeNovemberModal.module.scss'
import ChallengeProgress from './components/ChallengeProgress'
import { ReactComponent as BadgeSvg } from './images/badge.svg'

type Props = ModalConfirmProps

export default function EnterpriseClinicalChallengeNovemberModal({ className, ...props }: Props) {
  const [isConfirmed, setIsConfirmed] = useState(false)
  const [novemberChallengeId, setNovemberChallengeId] = useState<string | null>(null)
  const [showModal, setShowModal] = useState(false)
  const [challengeProgress, setChallengeProgress] = useState(0)

  const globalContext = useGlobalContext()
  const { data: activeChallenges, mutate: mutateActiveChallenges } = useActiveChallenges(
    {},
    { revalidateOnFocus: false }
  )
  const { data: userProgramChallengesData, mutate: mutateUserProgramChallenges } =
    useUserChallenges(
      { challenge_id: novemberChallengeId },
      {
        dependencies: [activeChallenges],
        revalidateOnFocus: false,
      }
    )

  useEffect(() => {
    if (Array.isArray(activeChallenges?.data)) {
      const novemberChallenge = activeChallenges?.data?.find(
        (challenge) => challenge.name === 'November Challenge'
      )
      setNovemberChallengeId(novemberChallenge?.id || null)
    }
  }, [activeChallenges])

  useEffect(() => {
    if (Array.isArray(userProgramChallengesData?.data) && novemberChallengeId != null) {
      const userChallenge = userProgramChallengesData.data[0]
      setChallengeProgress(userChallenge?.progress || 0)
      setShowModal(!userChallenge?.userHasSeenLatestProgress)
    }
  }, [userProgramChallengesData, novemberChallengeId])

  async function close(userChallengeId: string) {
    await challengeApiRequest.markUserChallengeAsSeen(userChallengeId)
    mutateActiveChallenges()
    mutateUserProgramChallenges()
    setShowModal(false)
    setIsConfirmed(false)
  }

  async function handleConfirmClick() {
    setIsConfirmed(true)
    try {
      if (challengeProgress === 0) {
        const newUserChallenge = await challengeApiRequest.joinChallenge(novemberChallengeId)
        await close(newUserChallenge?.data?.id)
      } else {
        await close(userProgramChallengesData?.data[0]?.id)
      }
    } catch {
      setShowModal(false)
    }
  }

  const isComplete = challengeProgress === 4

  const progressTexts = [
    {
      progress: 0,
      buttonText: 'Get started',
      title: 'Welcome to the November Challenge!',
      copy: (
        <Text element="p">
          <Text color="purple" weight="bold">
            Bold’s November Challenge
          </Text>{' '}
          will help you build your strength and balance so you can finish the year feeling like your
          strongest self.
        </Text>
      ),
    },
    {
      progress: 1,
      buttonText: 'Continue',
      copy: (
        <>
          Congratulations on completing your first November Challenge class!
          <br />
          Keep up the great work.
        </>
      ),
    },
    {
      progress: 2,
      copy: 'You’re half way through the Challenge! Keep moving through your program.',
    },
    {
      progress: 3,
      copy: 'You’re so close! Just one more class to go.',
    },
  ]

  const currentProgress = progressTexts.find((p) => p.progress === challengeProgress) || {}

  const confirmButtonText = isComplete
    ? 'Go to my program'
    : currentProgress.buttonText || 'Continue'
  const title = currentProgress.title || 'My November Challenge'
  const copy = isComplete ? (
    <>
      Congratulations,{' '}
      <Text color="purple" weight="bold">
        {globalContext.user.firstName}
      </Text>
      ! You completed the November Challenge!
    </>
  ) : (
    currentProgress.copy
  )

  const challengeProgressLabel = isComplete ? (
    'Check your email for a special invitation to Thanksgiving Break, an exclusive relaxation class with Alicia. Be sure to keep moving!'
  ) : challengeProgress === 0 ? (
    <>
      Take{' '}
      <Text color="purple" weight="bold">
        4 classes
      </Text>{' '}
      by the end of the month to unlock access to a special Thanksgiving class.
    </>
  ) : (
    <>
      Take{' '}
      <Text color="purple" weight="bold">
        4 classes
      </Text>{' '}
      by the end of the month to earn your November Challenge badge.
    </>
  )

  return (
    <ModalConfirm
      {...props}
      title={title}
      confirmButtonProps={{
        children: confirmButtonText,
        loading: isConfirmed,
      }}
      shouldCloseOnOverlayClick={challengeProgress !== 0}
      hideCancel
      hideClose={challengeProgress === 0}
      isOpen={showModal}
      className={classNames(styles.this, className)}
      onConfirm={handleConfirmClick}
      onRequestClose={() => close(userProgramChallengesData?.data[0]?.id)}
      skipRequestCloseOnConfirm>
      <Row size="small">
        {_.isString(copy) ? (
          <Text element="p" flush>
            {copy}
          </Text>
        ) : (
          copy
        )}
      </Row>
      <Box color="grey" className={styles.box}>
        {isComplete && (
          <Row size="xsmall">
            <BadgeSvg aria-label="November Challenge badge" />
          </Row>
        )}
        <Text element="p">{challengeProgressLabel}</Text>
        <ChallengeProgress />
      </Box>
    </ModalConfirm>
  )
}
