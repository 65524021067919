// sort-imports-ignore
import { Script } from 'gatsby'
import LogRocket from 'logrocket'
import LogRocketFuzzySanitizer from 'logrocket-fuzzy-search-sanitizer'
import React from 'react'
import { Slide, ToastContainer } from 'react-toastify'
import Head from 'components/Head'
import urls from 'constants/urls'
import { GlobalContextProvider } from 'contexts/GlobalContext'
import useHasMounted from 'hooks/use-has-mounted'
import * as environment from 'libs/environment'
import * as logging from 'libs/logging'
import constants from 'styles/constants.module.scss'
import '@fontsource-variable/inter/wght.css'
import 'react-toastify/dist/ReactToastify.css'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'tippy.js/dist/tippy.css'
import 'styles/app.scss'
import './Base.scss'
import faviconImage from './images/favicon.png'
import { RELEASE_VERSION } from 'constants/generated-release-version'

const HUBSPOT_ANALYTICS_DOMAIN = 'https://js.hs-scripts.com'
const VIMEO_ANALYTICS_DOMAIN = 'https://extend.vimeocdn.com'

const { requestSanitizer, responseSanitizer } = LogRocketFuzzySanitizer.setup([
  'email',
  'firstName',
  'healthPlanMemberId',
  'lastName',
  'password',
  'phoneNumber',
  'dateOfBirth',
  'dob',
  'memberID',
])

type Props = {
  children: React.ReactNode
}

export default function Base({ children }: Props) {
  const hasMounted = useHasMounted()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => {
    logging.toConsole(
      `🌱 ENVIRONMENT - ${environment.APP_ENV} Release: ${RELEASE_VERSION}`,
      constants.GREEN
    )

    if (environment.isProduction) {
      LogRocket.init('vsas4n/age-bold', {
        network: {
          requestSanitizer: (request) => {
            if (request.url.toLowerCase().includes('admin')) {
              request.body = null
              return request
            }

            request.headers.Authorization = null

            return requestSanitizer(request)
          },
          responseSanitizer,
        },
      })
    }
  }, [])

  React.useEffect(() => {
    if (!environment.isProduction || window.gtag === undefined) return

    window.gtag('config', 'AW-711086065')
  }, [])

  return (
    <GlobalContextProvider>
      <Head
        htmlAttributes={{
          lang: 'en-us',
        }}>
        <link rel="icon" href={faviconImage} />
        <meta name="p:domain_verify" content="198c0f5c167a109769be2d87bc9427b4" />
        <meta name="theme-color" content="#3366ff" />
        <meta property="og:type" content="website" />
        <link rel="preconnect" href={urls.ASSETS} crossOrigin />
        <link rel="preconnect" href={HUBSPOT_ANALYTICS_DOMAIN} crossOrigin />
        <link rel="preconnect" href={VIMEO_ANALYTICS_DOMAIN} crossOrigin />
      </Head>
      {children}
      {hasMounted && (
        <ToastContainer
          closeButton={false}
          closeOnClick
          draggable={false}
          hideProgressBar
          icon={false}
          position="top-center"
          theme="colored"
          transition={Slide}
        />
      )}
      <Script src={`${HUBSPOT_ANALYTICS_DOMAIN}/ga/90506932.js`} />
      <Script src={`${VIMEO_ANALYTICS_DOMAIN}/47074318.js`} />
    </GlobalContextProvider>
  )
}
