import _ from 'lodash'
import React from 'react'
import Button from 'components/Button'
import Container from 'components/Container'
import Head from 'components/Head'
import HeaderSubheader from 'components/HeaderSubheader'
import Loader from 'components/Loader'
import Row from 'components/Row'
import Text from 'components/Text'
import View from 'components/View'
import paths from 'constants/paths'
import { useGlobalContext } from 'contexts/GlobalContext'
import LessonBox from 'features/lesson/components/LessonBox'
import useEventPageVisited from 'hooks/use-event-page-visited'
import type { Lesson } from 'libs/lesson'
import { useUserLessonsFavorite } from 'libs/lessons-query'
import * as user from 'libs/user'
import styles from './LessonsFavorite.module.scss'

export default function LessonsFavorite() {
  useEventPageVisited('Favorite classes')
  const globalContext = useGlobalContext()
  const { data: lessonsFavoriteData, isLoading: isLessonsFavoriteLoading } =
    useUserLessonsFavorite()

  return (
    <div className="LessonsFavorite">
      <Head bodyAttributes={{ class: 'body---grey body---has-subheader' }} />
      <HeaderSubheader>
        <Text element="h1" size="xlarge" weight="bold">
          My saved classes
        </Text>
      </HeaderSubheader>
      <View color="grey">
        <Container flush size="xxlarge">
          {isLessonsFavoriteLoading ? (
            <Loader overlay={false} />
          ) : _.isEmpty(lessonsFavoriteData?.data) ? (
            <>
              <Text align="center" color="grey" className={styles.null}>
                <Row size="small">
                  <Row size="xsmall">
                    <Text color="grey" element="h2" size="xxlarge" weight="bold">
                      Fill this page with your favorite classes
                    </Text>
                  </Row>
                  <Text element="p" flush>
                    {`Add any Explore ${!user.isFreeUser(globalContext.user) ? 'or Program' : ''} class to your favorites to watch later`}
                  </Text>
                </Row>
                {!user.isFreeUser(globalContext.user) && (
                  <Button to={paths.PROGRAM} color="white">
                    Go to program
                  </Button>
                )}
              </Text>
            </>
          ) : (
            <div className={styles.lessons}>
              {lessonsFavoriteData.data?.map((item: Lesson) => (
                <LessonBox data={item} key={item.id} />
              ))}
            </div>
          )}
        </Container>
      </View>
    </div>
  )
}
