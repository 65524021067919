import React from 'react'
import Copy from 'components/Copy'
import Divider from 'components/Divider'
import Head from 'components/Head'
import Link from 'components/Link'
import Row from 'components/Row'
import Text from 'components/Text'
import AboutUsCard from 'components/pages/about/AboutUsCard'
import Carousel from 'components/pages/about/Carousel'
import emails from 'constants/emails'
import paths from 'constants/paths'
import useEventPageVisited from 'hooks/use-event-page-visited'
import Page2 from 'templates/Page2'
import headerImage from './images/header.jpg'

export default function About() {
  useEventPageVisited('About')

  const testimonials = [
    'Bold has been life changing for me physically, cognitively, and emotionally. The trainers are outstanding in their ability to connect with an aging population from varied backgrounds.<br />— Sarah M., 67',
    'When I started Bold, two and a half years ago, I did two 15-minute workouts a week. Took me a year to progress to three 25-minute workouts each week. Now I can do five 45-minute live classes! The process works!<br />— Greg L., 73',
    'My family has commented on how my outlook on my long-term goals for my health and fitness has grown exponentially since I joined Bold.<br />— Linda W., 81',
    'I have seen such an improvement in my strength, flexibility and cardio endurance since doing Bold’s live classes.<br />— Richard A., 68',
  ]

  const textProps = {
    size: 'large',
  }

  return (
    <Page2 title="About Bold" image={headerImage}>
      <Head
        title="About Bold"
        description="We’re Team Bold – welcome to the Bold community. Our team is passionate about aging strong and investing in our 100-year-old selves."
      />
      <Copy {...textProps}>
        <Text element="p">
          Bold is an at-home, digital fitness platform designed to help older adults live their
          strongest, happiest, and healthiest lives.
        </Text>
        <Text element="p">
          We create science-backed, custom exercise programs for adults over 65. Our balance
          programs are proven to lower fall risk by 46% and our pain programs reduce joint pain by
          over 50%, so older adults can beat the odds with Bold.
        </Text>
        <Text element="p">
          Bold members get instant access to hundreds of live and on-demand classes, clinical
          assessments, and personalized exercise programs. Our expert trainers provide exercise
          modifications that accommodate age, health, and mobility conditions, so every member can
          reach their goals.
        </Text>
        <Text element="p">
          At Bold, we’re here to help you improve your strength, flexibility, and balance — so you
          can chase the life you want, at any age.
        </Text>
      </Copy>
      <Divider />
      <Row size="small">
        <Text size="xlarge" weight="bold">
          From our members
        </Text>
      </Row>
      <Carousel>
        {testimonials.map((item, index) => (
          <AboutUsCard index={index} key={item}>
            {item}
          </AboutUsCard>
        ))}
      </Carousel>
      <Divider />
      <Row size="small">
        <Text size="xlarge" weight="bold">
          Join Team Bold
        </Text>
      </Row>
      <Copy {...textProps}>
        <Text element="p">
          We’re hiring! If you’re ready to change the conversation on aging, we’d love to chat.
        </Text>
        <Text element="p">
          Email us at <Link to={emails.DEFAULT} />, and{' '}
          <Link to={paths.CAREERS}>see our open positions</Link>.
        </Text>
      </Copy>
    </Page2>
  )
}
