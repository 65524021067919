import type { PageProps } from 'gatsby'
import React from 'react'
import HomeSpecial from 'components/landing/HomeSpecial'
import HomeSpecialLayout from 'components/landing/HomeSpecialLayout'

type Props = PageProps

export default function HomeSpecialAccessibility(props: Props) {
  return (
    <HomeSpecialLayout {...props} className="HomeSpecialAccessibility">
      <HomeSpecial
        title="Free* at-home exercises"
        subtext="Hundreds of free* exercise classes you can do at home. Get moving today!"
        disclaimer="*Free if covered by your insurance"
      />
    </HomeSpecialLayout>
  )
}
