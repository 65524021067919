import { navigate } from 'gatsby'
import _ from 'lodash'
import React from 'react'
import Button from 'components/Button'
import Buttons from 'components/Buttons'
import Modal from 'components/Modal'
import ModalBox from 'components/ModalBox'
import Row from 'components/Row'
import Rows from 'components/Rows'
import Tag from 'components/Tag'
import paths from 'constants/paths'
import { useGlobalContext } from 'contexts/GlobalContext'
import OnboardingBox from 'features/onboarding/components/OnboardingBox'
import OnboardingLayout from 'features/onboarding/components/OnboardingLayout'
import OnboardingView from 'features/onboarding/components/OnboardingView'
import useEventPageVisited from 'hooks/use-event-page-visited'
import * as assessments from 'libs/assessments'
import * as assessmentsApiRequest from 'libs/assessments-api-request'
import { useLatestUserCheckinResponses } from 'libs/checkin-query'
import * as url from 'libs/url'
import * as userApiRequest from 'libs/user-api-request'
import { useUserProfile } from 'libs/user-query'
import { CHECKIN_RESPONSE_VALUES } from 'libs/checkins/checkin-constants'

const PROGRAM_MIXED_MOVEMENTS = 'mixedMovements'
const PROGRAM_SEATED_ONLY = 'seatedOnly'
type ProgramSelected = 'mixedMovements' | 'seatedOnly'

export default function OnboardingAssessmentResults() {
  const globalContext = useGlobalContext()
  const { data: latestUserCheckinResponsesData } = useLatestUserCheckinResponses()
  const { data: latestUserProfileData } = useUserProfile()
  const latestUserProfile = latestUserProfileData?.data
  const latestUserCheckinResponses = latestUserCheckinResponsesData?.data
  const preferredPosition = latestUserCheckinResponses?.preferred_exercise_position?.responseValue
  const { pelvic } = url.getQueryString()

  const programFocus = latestUserProfile?.latestProfile?.programFocus

  const [movementResults, setMovementResults] = React.useState<any | undefined>()
  const [prefersSeatedMismatch, setPrefersSeatedMismatch] = React.useState(false)
  const [showModal, setShowModal] = React.useState(false)
  const [selectedProgram, setSelectedProgram] =
    React.useState<ProgramSelected>(PROGRAM_MIXED_MOVEMENTS) // State to track selected box

  useEventPageVisited('Onboarding assessments page')

  const handleClick = async () => {
    if (pelvic) {
      setShowModal(true)
    } else {
      if (
        (prefersSeatedMismatch && selectedProgram === 'seatedOnly') ||
        movementResults.lastResult === 'non_weight_bearing'
      ) {
        const response = await userApiRequest.updateUserProfile({
          source: 'Assessment Results',
          positionOverride: true, // to ensure strict filtering to seated lessons
        })
        if (prefersSeatedMismatch) {
          await globalContext.analytics?.trackEvent('User wants seated only program', {
            movementResults: movementResults.lastResult,
            successfulUpdate: response.statusCode === 200 ? 'true' : 'false',
          })
        }
      }
      navigate(paths.ONBOARDING_PROFILE, { replace: true })
    }
  }

  const handleProgramSelect = (program: string) => {
    if (prefersSeatedMismatch) {
      // do nothing on click if it's only box
      setSelectedProgram(program)
    }
  }

  const goBackToProgram = async () => {
    await userApiRequest.updateUserProfile({
      programFocus: null,
      source: 'Pelvic Program Opt-out',
    })
    navigate(paths.PROGRAM)
  }

  const goToPelvicProgram = () => {
    navigate(`${paths.PROGRAM_BUILD}?changeRequested=true`, { replace: true })
  }

  React.useEffect(() => {
    async function getAssessments() {
      const response = await assessmentsApiRequest.getAssessments(globalContext.user?.id)

      let movementAssessment = null
      if (response && preferredPosition) {
        movementAssessment = response.filter((item) => item.title === 'Bold Lower Body Movement')
        setMovementResults(movementAssessment[0])
        const assessmentResult = movementAssessment[0].lastResult
        const mismatch =
          assessmentResult !== 'non_weight_bearing' &&
          preferredPosition === CHECKIN_RESPONSE_VALUES.SEATED
        console.log(
          'Movement assessment result',
          assessmentResult,
          'and preferredPosition',
          preferredPosition,
          'so prefers seated mismatch:',
          mismatch
        )
        setPrefersSeatedMismatch(mismatch)
        await globalContext.analytics?.trackEvent('OB Assessment Results: fetched assessment', {
          assessmentResult,
          preferredPosition,
          prefersSeatedMismatch: mismatch ? 'true' : 'false',
        })
      }
    }
    getAssessments()
  }, [globalContext.analytics, globalContext.user?.id, preferredPosition])

  if (_.isEmpty(movementResults)) return null

  let sectionCopy
  let icon
  let info
  let name
  let focusCopy
  let title
  switch (programFocus) {
    case 'pelvic_health': {
      sectionCopy = !_.isNil(movementResults?.lastResult)
        ? assessments.MOVEMENT_ASSESSMENT_RESULTS_WITH_PELVIC_MAP[movementResults.lastResult]
        : assessments.MOVEMENT_ASSESSMENT_RESULTS_WITH_PELVIC_MAP.default
      focusCopy =
        'Techniques to increase body awareness, relax muscles, and build strength for pelvic health.'
      info = _.get(sectionCopy, 'info', null)
      name = _.get(sectionCopy, 'name', null)
      title = 'Based on your answers, your program will focus on:'
      break
    }
    default: {
      sectionCopy = assessments.MOVEMENT_ASSESSMENT_RESULTS_MAP[movementResults.lastResult]
      icon = _.get(sectionCopy, 'icon', 'person')
      info = _.get(sectionCopy, 'info', null)
      name = _.get(sectionCopy, 'name', null)
      title = `${
        prefersSeatedMismatch
          ? 'Based on your responses, you can choose from 2 programs:'
          : 'Your answers indicate you can do:'
      }`
      break
    }
  }

  if (!name || !info) return null

  if (prefersSeatedMismatch && programFocus !== 'pelvic_health') {
    icon = 'shuffle'
    info = `Your ratings indicate you can do ${name.toLowerCase()}, but you said your preferred exercise position is seated. This curated program will contain a mix of movements in various positions.`
    name = 'Mixed Movements'
  }

  const programs = [
    {
      title: name,
      copy: info,
      ...(programFocus === 'pelvic_health'
        ? {}
        : {
            icon,
            isSelected: selectedProgram === PROGRAM_MIXED_MOVEMENTS && prefersSeatedMismatch,
            prepend: !!prefersSeatedMismatch && (
              <Tag color="yellow" size="xsmall">
                Recommended
              </Tag>
            ),
            onClick: () => handleProgramSelect(PROGRAM_MIXED_MOVEMENTS),
          }),
    },
    // Conditionally include the second program only if programFocus is not true
    ...(programFocus === 'pelvic_health'
      ? []
      : [
          {
            title: 'Seated only',
            copy: 'You said your preferred exercise position is seated, so this program will only contain seated classes.',
            hide: !prefersSeatedMismatch,
            icon: 'chair',
            isSelected: selectedProgram === PROGRAM_SEATED_ONLY,
            onClick: () => handleProgramSelect(PROGRAM_SEATED_ONLY),
          },
        ]),
  ]

  return (
    <OnboardingLayout
      title="Movement"
      redirectIfOnboardingComplete={false}
      className="OnboardingAssessmentResults">
      <OnboardingView small="Your results" title={title}>
        <Rows size="small">
          {programs
            .filter((item) => !item.hide)
            .map(({ copy, ...item }) => (
              <OnboardingBox {...item} key={item.title}>
                {focusCopy && <Row size="xsmall">{focusCopy}</Row>}
                <Row size="xsmall">{copy}</Row>
              </OnboardingBox>
            ))}
          <Button full onClick={handleClick}>
            Create my program
          </Button>
        </Rows>
      </OnboardingView>
      <Modal size="medium" isOpen={showModal} onRequestClose={() => setShowModal(false)}>
        <ModalBox
          title="Please confirm you'd like to start a pelvic health program."
          subtext="This will replace your current program."
          textAlign="left">
          <Buttons align="center" equal>
            <Button color="white" onClick={goBackToProgram}>
              No, return to my program
            </Button>
            <Button onClick={goToPelvicProgram}>Yes, start this program</Button>
          </Buttons>
        </ModalBox>
      </Modal>
    </OnboardingLayout>
  )
}
